// to add the select I have to style the whole select element
// propertly, adding a wrapper to it etc.
button,
input[type="text"],
input[type="button"],
input[type="submit"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
}

input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
  vertical-align: baseline;
}
