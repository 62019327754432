.o-level {
  align-items: center;
  justify-content: space-between;
  @include from($tablet) {
    display: flex;
  }
  &.is-inverted-tablet {
    @include from($tablet) {
      .o-level__left {
        order: 1;
      }
    }
  }
}

.o-level__left,
.o-level__right {
  align-items: center;
  @include from($tablet) {
    display: flex;
  }
}

@include until($tablet) {
  .o-level__left + .o-level__right {
    margin-top: 1.5rem;
  }
}

.o-level__left {
  justify-content: flex-start;
}

.o-level__right {
  justify-content: flex-end;
}

.o-level__item {

}