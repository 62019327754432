.c-search {
  color: $black;
}

.c-search__wrap {
  border: 1px solid currentColor;
  position: relative;
}

.c-search__input {
  background: transparent;
  border: none;
  color: currentColor;
  font-size: .75em;
  width: 100%;
  &:focus {
    outline: none;
  }
}

.c-search__button {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  color: inherit;
  height: 1em;
  padding: 0;
  position: absolute;
  right: .5em;
  top: 50%;
  transform: translateY(-50%);
  width: 1em;
  &:hover {
    box-shadow: none;
    transform: scale(1.1) translateY(-50%);
  }
  svg {
    height: 100%;
    width: 100%;

  }
}