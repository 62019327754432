.c-message {
  margin-bottom: 1.5rem;
  &.is-error {
    border-left: 3px solid $color-error;
  }
}

.c-message__header {
  font-weight: 700;
  padding: .75rem 1rem;
}

.c-message__body {
  padding: 1.25rem 1.5rem;
}

.c-message {
  &.is-success {
    .c-message__header {
      background: $color-success;
      color: $color-white;
    }
    .c-message__body {
      background: rgba($color-success, .05);
      color: $color-success;
    }
  }
  &.is-error {
    .c-message__body {
      background: rgba($color-error, .05);
      color: $color-error;
    }
  }

}
