#forgot_password {
  label {
    display: inline-block;
    width: 200px;
  }
  input {
    display: inline-block;
    margin-bottom: 12px;
  }
}

.signup-page-explanation-text {
  .page-section-header {
    margin: 1em 0;
  }
}

.send-again-activation-page {
  padding-top: 3em;
  text-align: center;
}

.form-wrapper {
  margin: auto;
  max-width: 25em;
  padding: 1em;
  text-align: left;
  width: 80%;
}

.send-again-form {
  .form-field-wrapper {
    margin-bottom: 1em;
  }
  label {
    display: block;
    margin-bottom: 1em;
  }
  input[type='submit'] {
    @include button;
    background: $orange;
    border: 0;
    color: $white;
    font-family: $montserrat;
  }
  .email-input {
    width: 100%;
  }
  .form-error {
    color: red;
    font-size: .75em;
    margin: 0 0 0 .5em;
  }
}

.g-recaptcha {
  margin-bottom: 1em;
}


