.c-gallery-img-container {
  align-items: center;
  display: flex;
  height: 8rem;
  justify-content: center;
  //height: 20em;
  @include from(290px) {
    height: 12rem;
  }
  @include from(380px) {
    height: 16rem;
  }
  @include from(480px) {
    height: 20rem;
  }
  @include from($desktop) {
    height: 16rem;
  }
}

.photo-gallery-footer {
  font-size: .75em;
  margin-top: 1em;
  @include media('<600px') {
    font-size: 1.5em;
  }
}

.gallery-header {
  padding: 6% 3%;
  .container {
    background-color: rgba(255, 255, 255, 0.9);
    margin: auto;
    padding: 3%;
    width: 80%;
  }
  @include media('<680px') {
    display: none;
  }
}

.gallery-header-text {
  text-align: center;
  p {
    margin-bottom: 0;
    @include media('<680px') {
      display: none;
    }
  }
}

.gallery {
  display: flex;
  justify-content: space-between;
  @include until($desktop) {
    padding-top: 1rem;
  }
}

/*
@supports (display: grid) {
  .gallery {
    display: grid;
    grid-gap: 1.5em;
    grid-template-columns: 1.5fr 7fr;
    grid-template-rows: 1fr 50px;
    // I moved the top filters inside the wrap, so I need one row less
    // grid-template-rows: 50px 1fr 50px;
    min-height: 0;
    min-width: 0;
    @include media('<780px') {
      grid-template-columns: 1fr;
    }
  }
}
*/

.mobile-sidebar-header {
  align-items: center;
  background: $black;
  color: $white;
  display: flex;
  flex: 0 0 50px;
  justify-content: center;
  position: relative;
  @include media('>780px') {
    display: none;
  }
}

.gallery-sidebar-mobile-button {
  background: $color-sunglow;
  color: $color-charleston-gray;
  cursor: pointer;
  font-family: $montserrat;
  font-weight: 400;
  text-align: center;
  position: fixed;
  padding: 1rem 0;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  @include media('>780px') {
    // display: none;
    position: relative;
    left: 0;
    right: 0;
  }
}

.c-gallery-sidebar__close {
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
}


.gallery-wrap {
  //flex: 0 0 70%;
  //min-width: 80%;
}
.breadcrumb {
  font-size: 0.8rem;
  margin-bottom: 1.5rem;
}

.gallery-wrap {
  //display: grid;
  //grid-column: 2 / 3;
  //grid-row: 1 / 2;
  // grid-row: 2 / 3;
  //grid-template-rows: 50px minmax(max-content, max-content) 50px;
  @include media('<780px') {
    grid-column: 1 / 3;
  }
}
.sub-links {
  a {
    font-size: 0.8rem;
    padding-right: 0.25rem;
  }
}
.c-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@supports (display: grid) {
  .c-gallery {
    display: grid;
    grid-gap: 1.5rem;
    grid-row: 2 / 3;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @include from($desktop) {
    grid-gap: 3rem 3.25rem;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}

.c-search-filter__size {
  border: 0;
  margin: 0;
  padding: 0;
}

.gallery-bottom-filters,
.gallery-top-filters {
  //display: flex;
  //height: 50px;
  //justify-content: center;
  //position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-top: 2rem;
  padding-bottom: 2rem;
  > div {
    width: 30%;
    //flex-grow: 1;
    @include until($desktop) {
      width: 48%;
      //flex-grow: 1;
    }
  }

  @include until($desktop) {
    position: sticky;
    z-index: 1;
    width: 100%;
    background: white;
    top: 6rem;
    left: 0;
    right: 0;
    padding: 0.5rem;
    padding-top: 2rem;


    &.is-detached {

      transition: transform .1s;
      box-shadow: 0px 7px 13px 0px rgba(0,0,0,.2);  
    }
  }
  @include media('<780px') {
    width: 107%;
    margin-left: -1.25rem;
    top: 5rem;
  }

  &.is-detached {
    position: fixed;
    transition: transform .1s;
    z-index: 50;
    width: 100%;
    background: white;
    top: 5rem;
    left: 0;
    right: 0;
    padding: 0.5rem;
    padding-top: 2rem;
    box-shadow: 0px 7px 13px 0px rgba(0,0,0,.2);  
  }
  &.is-not-visible {
    opacity: 0;
    transform: translateY(-100%);
  }
  &.is-visible {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.gallery-top-filters {
  //grid-row: 1 / 2;
  @include media('<780px') {
    //display: none;
  }
}
.in-calendars {
  .gallery-wrap {
    width: 100%;
  }
  .gallery-top-filters {
    > div {
      width: 100%;
      @include media('<780px') {
        width: 100%;
        text-align: left !important;
        padding: 0.5rem 0;
      }
    }
    label {
      margin-right: 1rem;
    }
    label[for="filter-orientation-vertical"] {
      margin-right: 0;
    }
  }
}
.gallery-bottom-filters {
  //grid-row: 3 / 4;
  margin-top: 3em;
  padding-bottom: 0;
}


.gallery-pagination {
  align-items: center;
  display: flex;
  font-size: 1em;
  position: relative;
  @include from($desktop) {
    font-size: 1.5em;
  }

}

.pagination-arrow {
  cursor: pointer;
  font-size: 1.5em;
  &:hover {
    color: #ffcc00;
  }
  &.is-inactive {
    color: $light_grey;
    cursor: default;
  }
}

.gallery-sort-by {
  border-bottom: 1px solid;
  position: absolute;
  right: 0;
}

.gallery-pagination-select,
.gallery-sort-by-select {
  appearance: none;
  border: 0;
  background-color: transparent;
  font-size: 1em;
  font-weight: 300;
  outline: none;
  padding: 8px 28px 8px 10px;
  position: relative;
  z-index: 1;
  color: $color-charleston-gray;
}

.gallery-pagination-select {
  padding: 8px 10px 8px 10px;
}

.gallery-pagination-select {
  border-bottom: 3px solid #ffcc33;
  border-radius: 0;
  height: 2.5em;
  margin: 0 1.5em;
  @include media('<600px') {
    height: 3em;
  }
  &:hover {
    background: #ffcc33;
    cursor: pointer;
  }
}

.gallery-pagination-select-arrow-down,
.gallery-sort-by-select-arrow-down {
  position: absolute;
  right: 6px;
  top: 9px;
  z-index: 0;
}
.gallery-sort-by-section {
  /*
  position: absolute;
  right: 0.25rem;
  bottom: 2.2rem;
  z-index: 0; 
  */
  @include until($desktop) {
    width: 100%;
    flex-grow: 2;
    margin-top: 1rem;
  }
}
@include media('<780px') {
  .gallery-mobile-sidebar-overlay {
    background: rgba($black, .7);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    &.is-visible {
      display: block;
    }
  }
}

// this is to position correctly the remove button
.selectize-input-item {
  //font-family: $montserrat;
  width: 80%;
}

.selectize-control.plugin-remove_button .remove-single {
  position: absolute;
  right: 0;
  font-size: 23px;
}

.sort-filter-mobile {
  @include media('>780px') {
    display: none;
  }

}

.gallery-sort-by {
  display: none;
  @include media('>780px') {
    display: block;
  }
}

.gallery-mobile-sort-by {
  height: 100%;
  position: relative;
  width: 100%;
  .selected-sort-method {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
  ul {
    background: $white;
    display: none;
    left: -2px;
    position: absolute;
    text-align: center;
    width: calc(100% + 4px);
    label {
      cursor: pointer;
      display: block;
      padding: .5em 0;
      &.is-selected {
        background: #000;
        color: $white;
      }
    }

    li {
      border: 2px solid #000;
      &:first-child {
        border-bottom: 0;
      }
      &:hover {
        background: #000;
        color: $white;
      }
    }
  }
}

.mobile-sort-label {
  .mobile-sort-checked-icon {
    color: $white;
    display: none;
  }
  input {
    position: absolute;
    visibility: hidden;
    &:checked {
      & ~ .mobile-sort-checked-icon {
        display: inline-block;
      }
    }
  }
}

.gallery-mobile-menu {
  background: $white;
  color: $black;
  display: flex;
  height: 50px;
  transition: transform .3s ease-in-out;
  width: 100%;
  @include media('>780px') {
    display: none;
  }
  &.is-disabled {
    .gallery-mobile-menu__filter {
      background: rgba(0, 0, 0, .75);
    }
  }
}

.gallery-mobile-menu__filter {
  border: 2px solid #000;
  cursor: pointer;
  display: flex;
  flex: 0 0 50%;
  font-size: .75em;
  align-items: center;
  justify-content: center;
  &:first-child {
    border-right: 0;
  }
}

.gallery-box {
  display: none;
}

@supports (display: grid) {
  .gallery-box {
    font-size: 1em;
    background: $color-section-background-alt;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-column: 1 / 3;
    grid-row: 10;
    padding: 1.5rem;
    @include from($desktop) {
      grid-column: 2 / 4;
    }
    &--project {
      background: $color-section-background-alt;
      grid-column: 1 / 3;
      grid-row: 3;
    }
    &--calendars {
      background: $color-section-background-main;
      grid-column: 1 / -1;
      grid-row: 5;
      .c-gallery-img-container {
        height: 10rem;
        /* this should be added to the main image-thumbnail.scss file */
        img {
          height: 100%;
          object-fit: contain;
        }
      }
      .c-card--4 .c-card__text {
        @include from($tablet) {
          max-width: 170px;
        }
      }
      @include until($desktop) {
        .c-image-grid {
          grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
        }
      }
      .c-card {
        color: $black;
      }
    }
    &--customer-shared-photos {
      background: $color-alabaster;
      grid-column: 1 / -1;
      grid-row: 8;
      h2, h3 {
        color: $color-charleston-gray;
      }
    }
  }

  .gallery-box__icon-wrap {
    font-size: .5em;
    margin-top: 1rem;
  }
  .gallery-box__img-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    @include from($desktop) {
      justify-content: flex-end;
    }
  }

  .gallery-box__img {
    &:not(:first-child) {
      margin-left: 1rem;
    }
    text-align: center;
    width: 7.5rem;
    img {
      border-radius: 50%;
      width: 100%;
    }
  }

  .gallery-box__title,
  .gallery-box__text {
    color: inherit;
  }

  .gallery-box__title {
    font-weight: 400;
  }

  .gallery-box__text {
    font-weight: 300;
    margin-bottom: 0;
    a {
      color: $white;
      &:hover {
        border-color: currentColor;
        color: $black;
      }
    }
  }

  .gallery-box__icon {
    align-items: center;
    display: flex;
    img {
      margin-right: 1em;
      width: 3em;
    }
  }

  .gallery-box__icon-content {
    align-items: center;
    display: flex;
  }
  .gallery-box__icon-number {
    flex: 0 0 auto;
    font-size: 3em;
    margin-right: .25em;
  }
  .gallery-box__icon-text {
    flex: 0 0 50%;
    font-size: 1em;
    font-weight: 400;
    text-transform: uppercase;
    width: 20%;
  }
  .gallery-box__icon-tree-text {
    width: 50%;
  }

  .gallery-box__contact-link {
    border-bottom: 2px solid $white;
    color: $white;
    &:hover {
      border-color: currentColor;
      color: $black;
    }
  }
}

.c-gallery-hero {
  @include until($desktop) {
    //display: none;
  }
}
.c-gallery-hero .categories {
  li {
    display: inline-block; 
    border: 1px solid #ddd; 
    border-radius: 2rem; 
    padding: 0.27rem; 
    margin: 0.2rem;
    cursor: pointer;
    &.selected {
      background-color: #eee;
    }
  }
  img {
    width: 30px; 
    height: 30px; 
    vertical-align: middle; 
    border-radius: 2rem;
  }
  a {
    border-bottom: 0;
  }
}
.c-gallery-extra-content, .c-photo-extra-content {
  font-size: .8em;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  @include until($desktop) {
    //display: none;
  }
  .row {
    display: flex;
    flex-wrap: nowrap;
    flex-flow: space-between;
    @include until($desktop) {
      padding-left: 0;
      padding-right: 0;
      display: block;
    }
  }
  .col {
    flex: 1;
    padding: 1rem;
  }
  .col > img {
    width: 100%;
  }
  .video-embed-wrap {
    width: 100%;
  }
  .c-product-features {
   margin: 1.5rem 0;   
  }
  .c-product-features--item {
    max-width: 350px;
  }
  .reviews-container {
    padding: 1rem;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 1rem;
    @include until($desktop) {
      width: 100%;
    }
  }
  .review-headline {
    text-align: center;
  }
  .reviews-list {
    display: flex;
    @include until($desktop) {
      width: 100%;
      display: block;
      .review-user-row {
        margin-top: 0.5rem;
      }
    }
    .review-row {
      background: $color-grey;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 0rem;
      display: block;
      flex: 1;
      border-bottom: 0px;
    }
    .left {
      width: 100%;
    }
    .right {
      display: none;
    }
  }
}
.c-photo-extra-content {
  font-size: 1rem;
}
.product-big-image {
  width: 100%;
  max-width: 700px;
  margin: auto;
}
.gallery-content-container {
  //grid-row: 2/3;
}

.select-color-label {
  width: auto !important;
  input {
    position: absolute;
    visibility: hidden;

  }
}

.color-circle-selector {
  border: 2px solid $grey;
  border-radius: 30px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  margin-right: 5px;
  width: 25px;
  //&.selected,
  &:hover {
    border-color: $black;
  }
  &.red {
    background: #e00000;
  }
  &.orange {
    background: #f7941e;
  }
  &.yellow {
    background: #f5ee31;
  }
  &.green {
    background: #0ba14b;
  }

  &.azure {
    background: #25aae1;
  }

  &.blue {
    background: #04559b;
  }

  &.purple {
    background: #6e5198;
  }

  &.pink {
    background: #ec008c;
  }

  &.brown {
    background: #945830;
  }

  &.beige {
    background: #fff0db;
  }

  &.black {
    background: #000;
  }

  &.white {
    background: #fff;
  }

  &.black-and-white {
    background: linear-gradient(135deg, #ffffff 0%, #ffffff 50%, #0a0e0a 51%, #0a0809 100%);
  }

  &.colorful {
    background: linear-gradient(45deg, yellow 0%, hsla(313, 94%, 47%, 0) 70%),
    linear-gradient(135deg, fuchsia 10%, hsla(354, 92%, 47%, 0) 80%),
    linear-gradient(225deg, aqua 10%, hsla(86, 94%, 41%, 0) 80%),
    linear-gradient(315deg, limegreen 100%, hsla(263, 92%, 44%, 0) 70%);
  }

  &.silver {
    background: linear-gradient(45deg, rgba(160, 160, 160, 1) 0%, rgba(232, 232, 232, 1) 56%);

  }
  &.gold {
    background: gold;
  }

}

.select-color-label input:checked ~ .color-circle-selector {
  border-color: $black;
}

.gallery-bodyclick {
  position: fixed; 
  top: 0px; 
  left: 0px; 
  right: 0px; 
  bottom: 0px; 
  width: 100%; 
  height: 100%; 
  background: white; 
  opacity: 0.5; 
  margin: auto; 
  padding-top: 12rem; 
  box-sizing: border-box; 
  text-align: center;
  display: none;
}

.c-gallery-extra-content .o-container {
  width: 100%;
  max-width: 640px;
}

ul.c-category-filters {
  li a {
    text-decoration: none;    
    border-bottom: 0;
  }
}

#toggle-filters-section, #toggle-categories-section {
  border: 3px solid $color-middle-blue-green;
  background-color: $color-middle-blue-green;
  color: #fff;
  padding: 1rem 0; 
  display: inline-block;
  @include from($desktop) {
    width: 20rem;
  }
  cursor: pointer;
  text-align: center;
  &:hover {
    background-color: #fff;
    color: $color-middle-blue-green;
  }
}
#toggle-categories-section {
  @include from($desktop) {
    display: none;
  }
}
#close-filters {
  cursor: pointer;
  font-size: 1.5rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}