.competition {
    text-align: center;

    .info {
        margin-top: 48px;
        p {
            text-align: left;
        }
        div {
            display: inline-block;
            //margin-bottom: 200px;
            //padding: 0 200px;
            vertical-align: top;
            width: 40%;
            &:first-child {
                margin-right: 50px;
            }
            &:last-child {
                margin-left: 50px;
            }
        }
    }

	.subtitle {
	    color: white;
	    opacity: 0.6;
	}

    .how-it-works {
        
    }
    .how-to-vote {
        font-family: Lora;
        ul {
            line-height: 1.5em;
            list-style-type: disc;
            margin-left: 24px;
            text-align: left;
            li {
                margin-bottom: 24px;
            }
        }
    }
}

.prize-box {
    display: inline-block;
    //height: 232px;
    text-align: center;
    vertical-align: top;
    width: 232px;
    p {
        font-size: 12px;
        height: 100px;
    }

    &.canon {
        display: inline-block;
    }
    &.vaude {
    }
    &.designstraps
    {
        img {
        width: 150px;
    }
    }
    &.giftcards
    {
        img {
        width: 150px;
    }
    }
}

.voters-prizes {
    h2 {
        margin-bottom: 48px;
    }
    .prize-box { 
        vertical-align: bottom;
    }
}
.photographers-prizes {
    h2 {
        margin-bottom: 48px;
    }
}

.full-width-picture {
	background: url($assetsPath + 'images/competition.jpeg') no-repeat center center scroll;
    background-size: cover;
    height: 550px;
    text-align: center;
    h1 {
        display: inline-block;
        margin-top: 64px;
        vertical-align: top;
    }
}
.competition-photos {
    .vote-message {
        font-size: 16px;
        a {
            color: $green;
            font-size: 16px;
            &:hover {
                color: $dark-green;
            }
        }
    }
    p {
        font-size: 12px;
        margin: 12px 0 48px 0;
    }
    a {
        color: $black;
        font-size: 12px;
        text-decoration: none;
        &:hover {
            color: $orange;
        }
    }
}

.prize-list {
    display: inline-block;
    text-align: justify;
    width: 960px;
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
    }
}
