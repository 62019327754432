.c-main-header {
  background: #000;
  font-size: 1em;
  left: 0;
  position: sticky;
  top: 0;
  transform: none;
  transition: transform .3s, opacity .3s;
  width: 100%;
  z-index: 99;
  &.is-detached {
    position: fixed;
    transition: transform .1s;
  }
  &.is-not-visible {
    opacity: 0;
    transform: translateY(-100%);
  }
  &.is-visible {
    opacity: 1;
    transform: translate(0, 0);
  }
}

