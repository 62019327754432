.o-stack {
  --space: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.o-stack > * {
  margin-top: 0;
  margin-bottom: 0;
}

.o-stack > * + * {
  margin-top: var(--space);
}

.o-stack--big > * + * {
  --space: 5rem;
}

.o-stack--small > * + * {
  --space: .75rem;
}
