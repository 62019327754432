$print_photo_width: 400px;
$sell_photo_width: 930px;

progress /* All HTML5 progress enabled browsers */
{
    /* Turns off styling - not usually needed, but good to know. */
    appearance: none;
    background: $white !important;

    /* gets rid of default border in Firefox and Opera. */
    border: 1px solid $grey;

    /* Needs to be in here for Safari polyfill so background images work as expected. */
    background-size: auto;

    /* Dimensions */
    width: $print_photo_width;
    height: 60px;

    left: 100px;
    position: relative;
    top: 50%;
}

#progress-bar-dialog {
    border-radius: 5px;
    padding: 10px;
    h4 {
        @include headline($orange, 24px);
        margin-left: 10px;
        margin-top: 5px;
    }
    p {
        margin: 10px 0 10px 11px;
    }
    .upload-progress-bar-container {
        width: 560px;
        margin-left: 10px;
    }
}

// #sell-photo progress {
// 	position: relative;
// 	width: $sell_photo_width;
// 	height: 40px;
// 	border: 0;
// 	top: 0;
// 	left: 0;
// }
/* Chrome */
progress::-webkit-progress-bar {
    background: $light_grey;
}

/* IE10 */
progress {
    color: black;
}

/* Firefox */
progress::-moz-progress-bar {
    background: $green;
}

/* Chrome */
progress::-webkit-progress-value {
    background: $green;
}

.upload-progress-bar-container {
    background: $light_grey;
    //border: 3px solid $green;
    display: none;
    height: 40px;
    //padding: 5px;
    position: relative;
    width: 100%;
}

.upload-progress-bar {
    border: 0;
    height: 100%;
    left: 0px;
    top: 0px;
    width: 100%;
}

.progress-number {
    color: $black;
    position: absolute;
    right: -10px;
}

.upload-progress-bar {
}

.progress-number {
    color: $black;
    left: 1em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
}


