.drop-element, .drop-element:after, .drop-element:before, .drop-element *, .drop-element *:after, .drop-element *:before {
  box-sizing: border-box; }

.drop-element {
  position: absolute;
  display: none; }
  .drop-element.drop-open {
    display: block; }

.drop-element.drop-theme-arrows-bounce-dark {
  max-width: 100%;
  max-height: 100%; }
  .drop-element.drop-theme-arrows-bounce-dark .drop-content {
    border-radius: 5px;
    position: relative;
    font-family: inherit;
    background: #000;
    color: #fff;
    padding: 1em;
    font-size: 1.1em;
    line-height: 1.5em; }
    .drop-element.drop-theme-arrows-bounce-dark .drop-content:before {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-width: 12px;
      border-style: solid; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-center .drop-content {
    margin-bottom: 12px; }
    .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-center .drop-content:before {
      top: 100%;
      left: 50%;
      margin-left: -12px;
      border-top-color: #000; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-center .drop-content {
    margin-top: 12px; }
    .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-center .drop-content:before {
      bottom: 100%;
      left: 50%;
      margin-left: -12px;
      border-bottom-color: #000; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-right.drop-element-attached-middle .drop-content {
    margin-right: 12px; }
    .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-right.drop-element-attached-middle .drop-content:before {
      left: 100%;
      top: 50%;
      margin-top: -12px;
      border-left-color: #000; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-left.drop-element-attached-middle .drop-content {
    margin-left: 12px; }
    .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-left.drop-element-attached-middle .drop-content:before {
      right: 100%;
      top: 50%;
      margin-top: -12px;
      border-right-color: #000; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-left.drop-target-attached-bottom .drop-content {
    margin-top: 12px; }
    .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-left.drop-target-attached-bottom .drop-content:before {
      bottom: 100%;
      left: 12px;
      border-bottom-color: #000; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-right.drop-target-attached-bottom .drop-content {
    margin-top: 12px; }
    .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-right.drop-target-attached-bottom .drop-content:before {
      bottom: 100%;
      right: 12px;
      border-bottom-color: #000; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-top .drop-content {
    margin-bottom: 12px; }
    .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-top .drop-content:before {
      top: 100%;
      left: 12px;
      border-top-color: #000; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-top .drop-content {
    margin-bottom: 12px; }
    .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-top .drop-content:before {
      top: 100%;
      right: 12px;
      border-top-color: #000; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-right.drop-target-attached-left .drop-content {
    margin-right: 12px; }
    .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-right.drop-target-attached-left .drop-content:before {
      top: 12px;
      left: 100%;
      border-left-color: #000; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-left.drop-target-attached-right .drop-content {
    margin-left: 12px; }
    .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-left.drop-target-attached-right .drop-content:before {
      top: 12px;
      right: 100%;
      border-right-color: #000; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-left .drop-content {
    margin-right: 12px; }
    .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-left .drop-content:before {
      bottom: 12px;
      left: 100%;
      border-left-color: #000; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-right .drop-content {
    margin-left: 12px; }
    .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-right .drop-content:before {
      bottom: 12px;
      right: 100%;
      border-right-color: #000; }

.drop-element.drop-theme-arrows-bounce-dark {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-transition: opacity 100ms;
          transition: opacity 100ms;
  opacity: 0; }
  .drop-element.drop-theme-arrows-bounce-dark .drop-content {
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.265, 1.55);
            transition: transform 0.3s cubic-bezier(0, 0, 0.265, 1.55);
    -webkit-transform: scale(0) translateZ(0);
            transform: scale(0) translateZ(0); }
  .drop-element.drop-theme-arrows-bounce-dark.drop-open {
    display: none; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-open-transitionend {
    display: block; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-after-open {
    -webkit-transition: none;
            transition: none;
    opacity: 1; }
    .drop-element.drop-theme-arrows-bounce-dark.drop-after-open .drop-content {
      -webkit-transform: scale(1) translateZ(0);
              transform: scale(1) translateZ(0); }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-center .drop-content {
    -webkit-transform-origin: 50%, calc(100% + 12px);
        -ms-transform-origin: 50%, calc(100% + 12px);
            transform-origin: 50%, calc(100% + 12px); }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-center .drop-content {
    -webkit-transform-origin: 50%, -12px;
        -ms-transform-origin: 50%, -12px;
            transform-origin: 50%, -12px; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-right.drop-element-attached-middle .drop-content {
    -webkit-transform-origin: calc(100% + 12px), 50%;
        -ms-transform-origin: calc(100% + 12px), 50%;
            transform-origin: calc(100% + 12px), 50%; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-left.drop-element-attached-middle .drop-content {
    -webkit-transform-origin: -12px, 50%;
        -ms-transform-origin: -12px, 50%;
            transform-origin: -12px, 50%; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-left.drop-target-attached-bottom .drop-content {
    -webkit-transform-origin: 0, -12px;
        -ms-transform-origin: 0, -12px;
            transform-origin: 0, -12px; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-right.drop-target-attached-bottom .drop-content {
    -webkit-transform-origin: 100%, -12px;
        -ms-transform-origin: 100%, -12px;
            transform-origin: 100%, -12px; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-top .drop-content {
    -webkit-transform-origin: 0, calc(100% + 12px);
        -ms-transform-origin: 0, calc(100% + 12px);
            transform-origin: 0, calc(100% + 12px); }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-top .drop-content {
    -webkit-transform-origin: 100%, calc(100% + 12px);
        -ms-transform-origin: 100%, calc(100% + 12px);
            transform-origin: 100%, calc(100% + 12px); }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-right.drop-target-attached-left .drop-content {
    -webkit-transform-origin: calc(100% + 12px), 0;
        -ms-transform-origin: calc(100% + 12px), 0;
            transform-origin: calc(100% + 12px), 0; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-left.drop-target-attached-right .drop-content {
    -webkit-transform-origin: -12px, 0;
        -ms-transform-origin: -12px, 0;
            transform-origin: -12px, 0; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-left .drop-content {
    -webkit-transform-origin: calc(100% + 12px), 100%;
        -ms-transform-origin: calc(100% + 12px), 100%;
            transform-origin: calc(100% + 12px), 100%; }
  .drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-right .drop-content {
    -webkit-transform-origin: -12px, 100%;
        -ms-transform-origin: -12px, 100%;
            transform-origin: -12px, 100%; }
