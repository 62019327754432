.c-cart {
  border-bottom: 1px solid;
  margin-bottom: 1.5rem;
  padding: 1.5rem 0;
  width: 100%;
  --space: 3rem;
}

.c-cart__total {
  font-weight: 400;
}

.c-cart__delivery-times {
  margin-bottom: 1.5rem;
}

.c-cart__header {
  display: none;
  @include from($tablet) {
    border-bottom: 1px solid;
    display: flex;
    margin-bottom: 1rem;
    padding: 1rem 0;
  }
  h2 {
    font-size: 1.25em;
  }
}

.c-cart__row {
  padding-top: 1.5rem;
  & + & {
    margin-top: 3rem;
    border-top: 1px solid $color-silver;
  }
  @include from($tablet) {
    border: 0 !important;
    display: flex;
  }
}

.c-cart__cell {
  @include from($tablet) {
    flex: 1;
    padding: 0 1.5rem;
  }
}

.c-cart__column {

  &--picture {
    flex: 0 0 15rem;
  }
  &--product {
    flex: 2;
    padding-bottom: 1rem;
  }
  &--quantity,
  &--unit-price {
    text-align: center;
    @include from($tablet) {
      .o-definition {
        justify-content: center;
      }
    }
  }
  &--price {
    @include until($desktop) {
      display: flex;
      justify-content: space-between;
    }
    text-align: right;
  }
  &--unit-price {
    @include until($desktop) {
      display: none;
    }
  }
  .photo-title {
    font-size: 1em;
  }
  .artist {
    font-size: .75em;
  }
}

.c-cart__delete {
  border: none;
  color: $color-pastel-red;
  font-size: .75em;
}

.c-cart__item-total-price {
  text-align: right;
}

.c-cart__product-name {
  font-size: 1em;
  font-weight: 400;
  @include block;
}
.c-cart__cell {
  .product-name {
    margin-bottom: 1.5em;
    font-weight: 400;
  }
  .product-description, .product-size {
    line-height: 1.7rem;
  }
}




.c-cart__item-total-price {
  font-weight: 400;
}

.c-cart__shipping {
  @include until($desktop) {
    display: none;
  }
}

.c-cart__shipping-time {
  display: none;
  align-items: center;
  justify-content: flex-end;
  img {
    height: 2rem;
    margin-right: 1.5rem;
    width: auto;
  }
}

.c-cart__select-shipment-country {
  @include from($tablet) {
    display: flex;
  }
}

.after-cart-container {
  text-align: right;
}

.c-cart__item-picture {
  max-width: 10rem;
  width: 100%;
  .giftcard-small-preview {
    padding: 0;
  }
  .o-image {
    margin-bottom: 1rem;
    img {
      margin-bottom: 1rem;
    }
    img.has-frame-1 {
      padding: 0px;
      border-image-outset: 1px; // to avoid a white border outside
      border-image-repeat: round; // not sure
      border-image-slice: 8;
      border-image-width: 8px; // size of the frame
      border-style: inset;
      border-width: 8px;
      background: black;
    }
    img.has-frame-1.product-type-id-7 {
      padding: 20px;
      border-image-width: 8px; // size of the frame
      border-width: 1px; // size of passepartout combined with padding
      background: white;

    }
    img.option-id-11, img.frame-color-2 {
      border-image-source: url($productImagePath + 'wooden-frame-white.svg');
      box-shadow: 0px 0px 3px #777;

    }
    img.option-id-12, img.frame-color-1 {
      border-image-source: url($productImagePath + 'wooden-frame-black.svg');
    }
    img.option-id-13, img.frame-color-4 {
      border-image-source: url($productImagePath + 'wooden-frame-brown-oak.svg');
    }
  }
}

.c-cart__item-price {
  font-weight: 400;
}

.c-checkout__summary,
.c-cart__summary {
  @include from($tablet) {
    margin-left: auto;
    width: 100%;
    max-width: 26rem;
  }
  div {
    justify-content: space-between;
  }
  dt {
    text-align: left;
  }
  dd  {
    text-align: right;
    width: 10rem;
  }
  .express-delivery-line-top {
    border-top: 1px solid #eee; 
    margin-top: 0.5rem; 
    padding-top: 0.5rem; 
    margin-bottom: 0;
  }
  .express-delivery-line-bottom {
    font-size: 0.8rem; 
    border-bottom: 1px solid #eee; 
    padding-bottom: 0.5rem; 
    margin-bottom: 0.5rem; 
    width: 100%;
    margin-right: 0;
    padding-left: 1.6rem;
    margin-top: 0.3rem;

  }
}



.c-checkout__summary {
  margin: 0;
  width: 100%;
  div:last-child {
    font-size: 1.25em;
    font-weight: 700;
    dd {
    }
  }
}

.c-cart__nav {
  @include from($tablet) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  @include until($tablet) {
    .c-button {
      width: 100%;
    }
    &--bottom {
      span {
        display: none;
      }
    }
  }
}

.cart-forms-container {
  @include from($desktop) {
    display: flex;
  }
}

.cart-login-form-container {
  margin-bottom: 3rem;
  @include from($desktop) {
    flex: 0 0 40%;
    margin-bottom: 0;
    margin-right: 3rem;
    padding-right: 6rem;
  }
}

.cart-order-form-container {
  @include from($desktop) {
    flex: 1 0 40%;
    margin-left: 3rem;
  }
}

.c-checkout-breadcrumb {
  display: flex;
  justify-content: space-around;
  @include until($tablet) {
    font-size: .5em;
  }
}

.c-checkout-breadcrumb__item {
  flex: 1;
  position: relative;
  text-align: center;
  &::after {
    background: linear-gradient(to right, $color-accent 50%, $color-accent 50%);
    bottom: 0;
    content: '';
    height: .15rem;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.c-checkout-breadcrumb__text {
  border: 0;
  color: $color-text;
  display: inline-block;
  padding: .75rem;
  position: relative;
  font-size: 0.8rem;
  @include from($desktop) {
    font-size: 1rem;
  }
  &::after {
    background: $color-accent;
    border-radius: 50%;
    bottom: 0;
    content: '';
    display: block;
    height: .75rem;
    left: 50%;
    position: absolute;
    transform: translate(-50%, calc(50% - .075rem));
    width: .75rem;
    z-index: 1;
  }
}

.c-checkout-breadcrumb__item {
  &.is-active {
    &::after {
      background: linear-gradient(to right, $color-accent 50%, $color-silver 50%);
    }
    .c-checkout-breadcrumb__text {
      color: $color-text;
      &::after {
        background: $color-accent;

      }
    }
    & ~ .c-checkout-breadcrumb__item {
      &::after {
        background: linear-gradient(to right, $color-silver 50%, $color-silver 50%);
      }
      .c-checkout-breadcrumb__text {
        color: $color-silver;
        &::after {
          background: $color-silver;
        }
      }
    }
  }
}

.c-checkout-breadcrumb__item {
  &:first-child {
    &::after {
      background: linear-gradient(to right, $color-white 50%, $color-accent 50%) !important;
    }
  }
  &:last-child {
    &::after {
      background: linear-gradient(to right, $color-silver 50%, $color-white 50%) !important;
    }
  }
}

.payment-go-back-link {
  /*
  bottom: 0rem;
  position: absolute;
  &.is-long-cart {
    bottom: auto;
  }
  */
}

.payment-address-title {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  a {
    border: none;
    font-size: .75em;
    &:hover {
      color: $color-accent;
    }
  }
}

.address-block {
  font-family: $bodyFont;
  .address-row {
    line-height: 1.5rem;
  }
}
#paypal_button_1 > div, #paypal_button_2 > div {
  z-index: 0;
}