.hiw-page {
  padding-top: 3em;
  text-align: center;
  .product-list-item-open-product-popup,
  .product-list-item-other-sizes {
    display: none;
  }
}

.how-it-works-image {
  margin: auto;
  max-width: 960px;
  width: 100%;
}

.hiw-ngo-background-container {
  background: url($assetsPath + 'images/crop-about.jpg') no-repeat 0px -313px;
  height: 567px;
  margin: auto;
  position: relative;
  width: 760px;

}

.hiw-photographers-background-container {
  background: url($assetsPath + 'images/crop-about.jpg') 0px 0px;
  height: 312px;
  margin: auto;
  width: 760px;
}

.hiw-ngos-section {
  background: $yellow;
}

@include media('<600px') {
  .hiw-page-projects,
  .hiw-ngos-section,
  .hiw-photographers,
  .hiw-products {
    display: none;
  }
}

.hiw-products {
  .products {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
