#choose_projects_type {
  margin-top: 36px;
  text-align: center;
}

.project-map-menu-item {
  cursor: pointer;
  display: inline-block;
  font-size: 1.5em;
  margin: 0 1em;
  &:hover,
  &.selected {
    border-bottom: 3px solid $color-middle-blue-green;
  }
}

#worldmap {
  background: url($assetsPath + 'images/worldmap.png') no-repeat #fff;
  clear: both;
  height: 634px;
  margin: auto;
  text-align: start;
  width: 960px;
}

.project_icon {
  position: absolute;
  margin: 0;
  width: 27px;
}

.worldmap-project-title {
  margin-bottom: 12px;
  font-size: 1rem !important;
  width: 260px;
}

.worldmap-project-description {
  p {
    font-size: 12px;
    line-height: 1.25;
    width: 270px;
  }
}

.worldmap-project-read-more {
  color: $orange;
  font-size: 16px;
  margin-top: 10px;
}

a.project_link {
  text-decoration: none;
  color: #000;
}

a.project_link span {
  display: none;
}

a:hover.project_link span {
  color: #667078;
  display: block;
  background: #fff;
  background: rgba(255, 255, 255, 0.95);
  font-size: 16px;
  font-weight: normal;
  min-height: 190px;
  padding: 14px 10px 10px 15px;
  position: absolute;
  box-shadow: 0px 0px 10px #000;
  width: 450px;
  z-index: 1000;
}

a:hover.project_link > span {
  border-radius: 10px;
}

a:hover.project_link .left {
  float: left;
  width: 150px;
  background: transparent;
  border: 0px;
  box-shadow: 0px 0px 0px #000;
  padding: 0px;
}

a:hover.project_link .right {
  margin-left: 160px;
  background: transparent;
  border: 0px;
  box-shadow: 0px 0px 0px #000;
  width: 195px;
  padding: 0px;
}

#worldmap .right > h1 {
  padding: 0px;
  margin-top: -3px;
}

#worldmap .right > p {
  font-size: 12px;
  line-height: 1.25;
  margin: 0px;
  width: 270px;
}

a:hover.project_link .percentage {
  color: $green;
  margin-top: -45px;
  margin-left: 55px;
}

a:hover.project_link h1 {
  font-size: 16px;
  text-align: left;
}

a:hover.project_link p {
  text-align: left;
}

#worldmap .project_thumb {
  display: block;
  width: 150px;
  height: 100px;
  float: left;
  margin-right: 10px;
  border-radius: 5px;
  margin-bottom: 0px;
}

.open-transparency-popup {
  @include button();
}

#ngo-list {
  .ngo-preview {
    float: left;
    width: 320px;
    height: 550px;
    margin: 30px 50px;
    margin-bottom: 60px;
  }
  .ngo-preview:nth-child(3) {
    margin-right: 0px;
  }
  .ngo-logo-container {
    height: 140px;
  }
  .ngo-logo {
    max-width: 235px;
    max-height: 120px;
  }
  .ngo-text {
    text-align: left;
    width: 320px;
    h3 a {
      text-decoration: none;
    }
    ul {
      font-family: $lora;
      list-style: disc inside none;
      margin-top: 12px;
      li {
        line-height: 1.5em;
      }
    }
  }
  .ngo-text p {
    text-align: left;

  }
}

@mixin project-preview-name {
  color: $black;
  font-size: calc-em(24px, 16px);
  font-weight: 300;
  margin-bottom: calc-em(18px, 24px);
}

.project-name {
  @include project-preview-name;
  margin-bottom: 0.25rem;
}

.project-picture {
  display: inline-block;
  margin-right: calc-em(12px, 16px);
  vertical-align: top;
  width: calc-em(220px, 16px);
}

.project-description {
  display: inline-block;
  margin-right: calc-em(48px, 16px);
  vertical-align: top;
  width: calc-em(280px, 16px);
  .project-description-content {
    font-size: calc-em(16px, 16px);
    margin-top: 0;
    text-align: justify;
  }
}

.open-project-popup {
  cursor: pointer;
}

.project-money-column {
  display: inline-block;
  font-weight: 300;
  margin-right: 0px;
  vertical-align: top;
  width: calc-em(220px, 16px);
  .top-text {
    margin: 0 0 6px 3px;
  }
  .bottom-text {
    margin: 6px 0 0 67px;
  }
  .donation-percentage {
    color: $green;
    font-size: 36px;
    font-weight: 700;
    margin-left: 66px;
  }
}

.project-money-summary {
  background-color: $very_light_grey;
  font-size: calc-em(12px, 16px);
  margin-top: 6px;
  padding: 6px;
}

.project-money-goal {
  margin-bottom: 3px;
}

.choose-projectBtn {
  @include button;
  margin-top: 1em;
  width: 100%;
}

.project-preview {
  //margin: calc-em(48px, 16px) 0;
  margin: 3em 0 5rem 0;
  .open-project-popup {
    cursor: pointer;
  }
}

.project-preview-title {
  @include project-preview-name;
  margin-bottom: 0.25rem;
}

.project-preview-content {
  width: 100%;
  margin: auto;
}

.project-preview-content-img-wrap,
.project-preview-content-description,
.project-preview-content-money {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
}

.project-preview-content-img-wrap {
  //margin-right: calc-em(14px, 16px);
  //max-width: 212px;
  width: 28%;
  img {
    width: 95%;
    margin-top: 0.5rem;
  }
}

.project-preview-content-description {
  //margin-right: calc-em(18px, 16px);
  //width: calc-em(280px, 16px);
  width: 40%;
  padding-right: 2%;
  box-sizing: border-box;
  p {
    margin: 0 0 calc-em(18px, 16px) 0;
    text-align: justify;
  }
}

.project-preview-content-link {
  font-weight: 300;
  color: $orange;
  &:hover {
    text-decoration: underline;
  }
}

.project-preview-content-money {
  width: 28%;

  font-weight: 300;
  margin-top: 1rem;
  .donation-percentage {
    color: $green;
    font-size: 36px;
    font-weight: 700;
    margin-left: 66px;
  }
  .bottom-text {
    margin: -5px 0 0 67px;
  }
  .project-preview-content-money-summary {
    background-color: $very_light_grey;
    font-size: 1em;
    line-height: 1.25;
    margin-top: 6px;
    padding: 6px;
  }
}

#ngo-projects .project-preview {
  margin-left: auto;
  margin-right: auto;
}

#project-page {
  #ngo-header {
    text-align: center;
    margin-bottom: 60px;
  }
  .ngo-logo {
    max-width: 300px;
    max-height: 200px;
    margin-bottom: 20px;
  }
  #project-info > div {
    margin-top: 30px;
    p {
      margin-top: 5px;
    }
  }
  .project-circle {
    float: left;
    margin-right: 10px;
  }
  .percentage {
    font-size: 54px;
    font-weight: bold;
    margin-top: -6px;
    color: $green;
    text-align: left;
  }
  .financed {
    color: $black;
    font-size: 30px;
    margin: 0px 0px 0px 0px;
    text-align: left;
  }
  .financed_de {
    font-size: 24px;
    text-align: left;
  }
  .left {
    float: left;
    width: 240px;
    height: 100px;
    text-align: center;
    margin-right: 20px;
    color: $grey;
    img {
      width: 230px;
      height: 155px;
      border: 2px solid $green;
    }
  }
  .right {
    margin-left: 260px;
    h2 {
      padding-top: 0px;
    }
  }
  #project-goals-row, #project-location-row {
    min-height: 180px;
  }
  .project-map {
    position: relative;
    width: 230px;
    height: 155px;
    border: 2px solid $green;
    background: url($assetsPath + 'images/worldmap.png') repeat #fff;
    margin-left: 3px;
    img {
      width: 27px;
      height: 43px;
      border: 0px;
      position: absolute;
      top: 56px;
      left: 102px;
    }
  }
  #project-description > .left > div {
    margin-bottom: 30px;
    font-size: 12px;
    text-align: left;
    img {
      width: 230px;
      margin-bottom: 5px;
      height: auto;
    }
  }

  ul {
    font-family: $lora;
    list-style: disc inside none;
    margin-top: 12px;
    li {
      line-height: 1.5em;
    }
  }
}

#project-popup {
  display: none;
  background: $white;
  width: 760px;
  height: 500px;
  padding: 15px;
  border-radius: 5px;
  position: fixed;
  z-index: 100;
  overflow-y: scroll;
}

.terms_and_conditions {
  ul {
    font-family: $lora;
    list-style-type: disc;
    margin-left: 24px;
    li {
      line-height: 1.5em;
      margin-bottom: 12px;
    }
  }
}

#ngo-page {
  width: 100%;
  max-width: 640px;
  margin: 3rem auto;
  .ngo-header {
    text-align: center;
    margin-bottom: 3rem;
  }
  .ngo-logo {
    max-width: 350px;
  }
}

.static-ngos-list {
  .our-partners-headline {
    font-family: $montserrat;
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 20px;
  }
  a {
    display: inline-block;
    margin-bottom: 20px;
  }
}

#ngo-description {
  strong {
    font-size: 18px;
    font-weight: bold;
  }

  ul {
    font-family: $lora;
    list-style: inside;
    margin-left: 12px;
    li {
      margin-bottom: 12px;
    }
  }
}

.print-buy-choose-project-txt {
  font-size: calc-em(16px, 16px);
}

.print-buy-choose-project-select-label {
}

.project-description-section {
  background-color: $color-alabaster;
  //padding: 24px;
  text-align: center;
  //width: 100%;
  &-title {
    color: $black;
    font-size: calc-em(24px, 16px);
  }
  &-content {
    display: table;
    margin: 24px auto;
    width: 65%;
  }
}

.project-description-section-content-column {
  display: table-cell;
  padding: 24px;
  text-align: left;
  width: 50%;
  &:first-child {
    border-right: 3px solid #e5e4de;
  }
  &-title {
    font-size: calc-em(16px, 16px);
  }
  &-text {
    font-size: calc-em(16px, 16px);
    line-height: 1.5;
    text-align: justify;
  }
}

.projects-ngo-headings {
  margin-bottom: 24px;
  text-align: center;
}
/*
.projects-ngo-section-title {
  color: $black;
  font-size: calc-em(28px, 16px);
  padding: calc-em(12px, 28px);
  text-transform: uppercase;
}

.projects-ngo-section-subtitle {
  color: $orange;
  font-size: calc-em(22px, 16px);
}
*/

.projects-ngo-list {
  //padding: 24px;
}

.projects-ngo-logo {
  display: inline-block;
}

.projects-ngo-list-container {
  border-collapse: collapse;
  display: table;
  margin: auto;
  width: 80%;
}

.projects-ngo-wrapper {
  border-bottom: 1px solid $grey;
  display: table-row;
  &:last-child {
    border-bottom: 0;
  }
}

.projects-ngo-logo-container {
  display: table-cell;
  padding: 3rem;
  text-align: center;
  vertical-align: middle;
  a {
    text-decoration: none;
    border-bottom: none;
  }
  img {
    max-height: 120px;
    max-width: 235px;
  }
}

.projects-ngo-description {
  display: table-cell;
  padding: 3rem;
  vertical-align: middle;
  ul {
    font-family: $lora;
    list-style: disc inside none;
    margin-top: 12px;
    li {
      line-height: 1.5em;
    }
  }
}

.projects-ngo-description-title {
  a {
    color: $black;
    text-decoration: none;
  }
}

.projects-ngo-description-text {
}

.projects-ngo-description-read-more {
  //font-family: $lora;
  text-decoration: none;
  color: $orange;
  &:hover {
    text-decoration: underline;
  }
}

.photographer-supports-title {
  color: $black;
}
