@import 'buttons/mixin';

.button {
  @include button();
}

input.button, button {
  @include button();
  border: 0;
  font-size: 1em;
}
