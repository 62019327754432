.c-upload-section {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 3rem 1.5rem 0 1.5rem;
  @include from($desktop) {
    --no-padding: 0;
  }
}

.c-upload-section__price-from {
  font-weight: 400;
}

.c-photolab-upload {
  background: none;
  border: 3px dashed $color-cta;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: var(--no-padding, 3rem 0);

  &.is-small-desktop {
    @include from($desktop) {
      padding: 0;
    }
  }
  p {
    margin: 0 0 1em 0;
  }
  .c-button {
    margin: 0;
  }
  .dz-preview {
    text-align: center;
  }
}

.photolab-all-prices-link {
  color: $color-accent;
  margin-bottom: 2rem;
  &:hover {
    text-decoration: underline;
  }
}