#iubenda_policy {
  font-family: inherit !important;
}

#iubenda_policy .iub_header {
  display: none;
}

#iubenda-cs-banner .iubenda-cs-opt-group-custom {
  align-items: center !important;
}

#iubenda-cs-banner .iubenda-cs-container .iubenda-cs-reject-btn  {
  border-width: 3px !important;
  border-color: #fff !important;
  border-style: solid !important;
}

#iubenda-cs-banner .iubenda-cs-container .iubenda-cs-customize-btn  {
  align-self: center !important;
  border-radius: 0 !important;
  font-weight: 400 !important;
  padding: 0 !important;
  text-decoration: underline !important;
}
