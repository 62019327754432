@import '../tools/mixins';

.o-button {

}

.o-button-text-link {
  background: none;
  border: 0;
  padding: 0;
  font-family: $bodyFont;
  @include link;
}