.confirm-message-box {
  background-color: $light_green;
  margin-top: 20px;
  padding: 15px 10px;
  width: 674px;
  a {
    border: 3px solid $green;
  }
}

.profile-picture-upload {
  max-width: 10rem;
  position: relative;
}

.profile-picture-placeholder {
  &:before {
    background: $grey;
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.profile-picture-upload-button {
  position: relative;
  input {
    left: -999px;
    position: absolute;
  }
}


.spinner {
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: -20px;
  position: absolute;
  z-index: 19 !important;
  animation: loading-bar-spinner 400ms linear infinite;
  &.is-hidden {
    display: none;
  }
}

.spinner .spinner-icon {
  width: 40px;
  height: 40px;
  border:  solid 4px transparent;
  border-top-color:  $black !important;
  border-left-color: $black !important;
  border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0%   { transform: rotate(0deg);   transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}
