#about-press {
  text-align: center;
  a {
    margin-top: 20px;
    padding: 15px 20px;
  }
}

#print-photos #about-press, #giftcards-page #about-press {
  #quotes {
    margin-top: 20px;
  }
  #quotes > div {
    width: 195px;
    vertical-align: top;
    img {
      max-width: 185px;
    }
  }
}

.grey-logos {
  filter: grayscale(100%);
  opacity: 0.3;
}

#current-quote {
  font-family: $lora;
  font-size: calc-em(18px, 16px);
  color: $medium_grey;
}

.about-us-projects,
.about-us-ngo,
.about-us-photographers {
  @include media('<620px') {
    display: none;
  }
}
