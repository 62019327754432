.c-photo-thumb-nav {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  overflow-x: auto;
  position: relative;
  scrollbar-color: $grey $color-white;
  scrollbar-width: thin;
  z-index: 0;

  &::-webkit-scrollbar {
    height: .5rem;
    width: auto;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-white;
  }

  &::-webkit-scrollbar-thumb {
    background: $grey;
    //background-image: linear-gradient($color-white 0, $color-white 0.25rem, $color-dim-gray 0.25rem, $color-dim-gray 0.75rem, $color-white 0.75rem);
  }
  &::after {
    content: '';
    padding: .5rem;
  }
  @include until($desktop) {
    padding-bottom: .75rem;
  }

  @include from($desktop) {
    flex: 0 0 16%;
    flex-direction: column;
    overflow-y: auto;
    height: 30rem;
    &::-webkit-scrollbar {
      height: auto;
      width: .5rem;
    }
  }
}

.c-photo-thumb-nav__item {
  border: 3px solid $grey;
  cursor: pointer;
  flex: 0 0 auto;
  flex-basis: 1;
  overflow: hidden;
  padding: 3px;
  position: relative;
  &:hover,
  &.is-selected {
    border: 3px solid currentColor;
    color: $black;
    cursor: default;
  }
  &:hover {
    cursor: pointer;
  }
  @include until($desktop) {
    height: 5rem;
    & + & {
      margin-left: 1rem;
    }
  }
  @include from($desktop) {
    width: 110px;
    & + & {
      margin-top: 1rem;
    }
  }
}

.c-photo-thumb-nav__img {
//  height: 100%;
  height: 68px;
  width: auto;
  display: block;
  object-fit: contain;
  // This width: 100% must be only on desktop otherwise the items stretch
  // on mobile
  @include from($desktop) {
    width: 100%;
    height: 100%;
  }
}

.c-photo-thumb-nav__item--video {
  @include until($tablet) {
    display: none !important;
  }
}

.photo-on-wall-thumbnail-wrap {
  height: 100%;
  overflow: hidden;
  position: relative;
}


