.c-content-slider-wrap {
  margin: 1em 5%;
  //overflow: hidden;
  //position: relative;
}

.c-content-slider {
  //display: flex;
  //position: relative;
}

.c-content-slider__item {
  //flex: 0 0 35%;
  //margin-right: 5em;
}

// This is to avoid an empty space at the bottom of the carousel
.slick-slider {
  font-size: 0;
}

.slick-slide {
  margin: 0 1rem;
  figcaption {
    font-size: 1rem;
  }
}

.photolab-upload-wrap {
  min-height: 0;
  min-width: 0;
  * {
    min-height: 0;
    min-width: 0;
  }
}

.c-carousel {
  min-height: 0;
  min-width: 0;
  flex: 1 100%;
  * {
    min-height: 0;
    min-width: 0;

  }
  &.slick-dotted.slick-slider {
    margin-bottom: 0;
  }
  .slick-slide {
    margin: 0;
  }
  .slick-dots {
    bottom: 0;
  }
}