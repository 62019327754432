.main-logo {
  --circleColor: #{$color-white};
  --photoColor: #{$color-fountain-blue};
  height: 100%;
  width: 100%;
  &--inverted {
    --circleColor: #{$black};
    --photoColor: #{$color-fountain-blue};
    height: 2rem;
  }
}

/*
.c-menu__logo {
  align-items: center;
  display: flex;
  flex: 1;
  margin: auto;
  max-width: 300px;
  padding: 0 1.5rem;
  width: 100%;
  @include from($tablet) {
    margin: 0;
    max-width: 200px;
  }
  @include tablet-only {
    flex: 1;
  }
  img {
    display: block;
    width: 100%;
  }
}


#photocircle {
  .logo-photo {
    color: $white;
  }
  .logo-circle {
    color: $green;
  }
  &:hover {
    .logo-photo {
      color: $green;
      transition-property: color;
      transition-duration: 300ms;
    }
  }
}
*/
