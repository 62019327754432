.newsletter {
    text-align: center;

    input {
        font-size: 16px;
        padding: 8px;
    }
}
.form-kleinehelden {
    margin: 60px 0 60px 0;
    text-align: center;
}
