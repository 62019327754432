$margin: 2.5%;
@mixin photo-products-page {
    padding: 5% 10%;
}

@mixin photo-products-page-container {
    font-size: 0;
    margin-left: (-$margin);
    margin-top: 3rem;
}

@mixin photo-products-page-preview-container($columnsCount) {
    border: 1px solid #ededed;
    display: inline-block;
    font-family: $lora;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.2;
    margin: 0 0 $margin $margin;
    padding: calc-em(8px, 12px);
    text-align: center;
    vertical-align: top;
    width: ((100 / $columnsCount) - 2.5) * 1%; //22.5%; /* width for 4 columns grid */
    &:hover {
        border: 1px solid $orange;
    }
    .original-price {
        color: $grey;
        text-decoration: line-through;
    }
    &-link {
        color: $black;
        text-decoration: none;
    }
    &-img {
        img {
            @include shadow(1);
            // max-height: 80%;
            max-width: 100%;
            width: 100%;
        }
    }
    &-content-wrapper {
        display: table;
        font-size: calc-em(12px, 16px);
        width: 100%;
    }
    &-text {
        display: table-cell;
        line-height: 1.2;
        text-align: left;
        vertical-align: bottom;
    }
    &-title {
        font-size: calc-em(12px, 16px);
        height: calc-em(48px, 12px);
        margin: calc-em(12px, 12px) 0 calc-em(16px, 12px) 0;
    }
    &-button-wrapper {
        display: table-cell;
        vertical-align: bottom;
    }
    &-button {
        @include button();
        margin-top: 1em;
        width: 100%;
    }
}

@mixin photo-product-page {
    padding: 5%;
}

@mixin photo-product-page-title {
    font-size: calc-em(18px, 16px);
    margin: 0 auto calc-em(12px, 18px) auto;
    width: 80%;
}

@mixin photo-product-page-content-wrap {
    display: table;
    margin: auto;
    width: 80%;
    @include media('<960px') {
        width: 100%;
    }
}

@mixin photo-product-page-buy-button {
    @include button();
    border: 0;
    border-radius: 0;
    margin-top: 1em;
    width: 100%;
    i {
        margin-right: 0.5em;
    }
}

@mixin photo-product-page-img-column {
    display: table-cell;
    padding-right: 3em;
}

@mixin photo-product-page-img {
    z-index: 0;
    img {
        border: 1px solid $grey;
        width: 100%;
    }
}

@mixin photo-product-page-sidebar {
    display: table-cell;
    vertical-align: top;
    width: calc-em(300px, 16px);
    @include media('<750px') {
        display: none;
    }
}

@mixin photo-product-page-footer-btn {
    @include button();
    i {
        margin-right: calc-em(6px, 12px);
    }
    @include media('<820px') {
        font-size: calc-em(8px, 16px);
        margin-right: 1em;
    }
}

@mixin photo-product-page-sidebar-project {
    border: 1px solid $green;
    display: table;
    font-family: $lora;
    font-size: 0;
    .read-more-link {
        display: inline-block;
        margin-top: 6px;
    }
    @include media('<750px') {
        display: none;
    }
}

@mixin photo-product-page-total {
    margin-top: 24px;
    position: relative;
    &-price {
        position: absolute;
        right: 0;
        @include media('<750px') {
            position: static;
        }
    }
    @include media('<750px') {
       position: static;
        text-align: right;
    }
}

@mixin photo-product-page-sidebar-project-img {
    display: table-cell;
    width: 50%;
    img {
        width: 100%;
    }
}

@mixin photo-product-page-sidebar-project-txt {
    display: table-cell;
    font-size: 0.75rem;
    padding: .5em;
    vertical-align: middle;
}

@mixin photo-product-page-thumbnail-pager {
    margin-top: 24px;
    text-align: center;
    a {
        border: 1px solid $grey;
        display: inline-block;
        font-size: 0;
        margin-bottom: 12px;
        margin-right: 2%;
        width: 12%;
        &:hover,
        &.cycle-pager-active {
            border: 1px solid $orange;
        }
        &:nth-child(7n) {
            margin-right: 0;
        }
    }
    &-img {
        width: 100%;
    }
}

@mixin photo-product-page-mobile-form {
    display: none;
    @include media('<750px') {
        display: block;
    }
}

.upload-error-content .error-message {
    font-size: .75em;
}
