.o-image {
  img {
    display: block;
    height: auto;
    width: 100%;
  }
  @include until($desktop) {
    &.is-fullwidth {
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      width: 100vw;
    }
  }
  &.is-centered  {
    margin: auto;
  }
  &.w-128 {
    max-width: 128px;
  }
  &.w-256 {
    max-width: 256px;
  }
}

.o-caption {
  font-size: .75em;
  padding-top: 1rem;
  text-align: center;
  @include from($desktop) {
    font-size: 1em;
  }
}