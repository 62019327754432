.c-curators {
  display: flex;
  justify-content: center;
}

.c-curators__picture {
  text-align: center;
}

.c-curators__img-wrap {
  border-radius: 100%;
  border: 3px solid $color-white;
  display: block;
  height: 7.5rem;
  margin-bottom: .75rem;
  overflow: hidden;
  width: 7.5rem;
}

.c-curators__picture + .c-curators__picture {
  margin-left: 3rem;
}

.c-curators__img {
  width: 100%;
}