/* generic style for content coming from the databases, usually doesn't have classes or other things */

.o-content {
  @include block;
  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    @include block;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.125;
    &:not(.is-spaced) + h2,
    &:not(.is-spaced) + h3,
    &:not(.is-spaced) + h4,
    &:not(.is-spaced) + h5,
    &:not(.is-spaced) + h6 {
      @include block;
      font-size: 1.25em;
      font-weight: 300;
      line-height: 1.25;
      margin-top: -1rem
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  ul {
    @include block;
    font-family: $bodyFont;
    line-height: 1.75;
    list-style-position: inside;
    list-style-type: disc;
  }

  li {

  }
}