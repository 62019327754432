.c-hero {
  height: 70vh;
  position: relative;
  @media screen and (max-height: 560px) {
    height: 100vh;
  }
  @media handheld, (max-width: 560px) {
    height: 70vh;
  }
}
