// stuff for the scrollbar
.project-popup {
  .featherlight-content {
    border: 0;
    height: 95%;
    padding: 0;
  }
  .select-project-popup {
    height: 100%;
  }
  .featherlight-inner {
    height: 100%;
  }
  .featherlight-close-icon {
    background: $white;
    color: $black;
  }
}

.c-project-selector {
  //display: flex;
  height: 100%;
  //justify-content: space-between;
  overflow: hidden;
  .project-details {
    flex: 0 0 65%;
    overflow-y: auto;
    padding: 0 3em;
  }
  .project-details-inner {

    .project-name {
      color: $black;
    }

    .ngo-data {
      align-items: center;
      display: flex;
      margin-bottom: 1em;
    }
    .ngo-logo-wrapper {
      margin-right: 1.5em;
      width: 10em;
      img {
        width: 100%;
      }
    }
  }
  .projects-list {
    display: flex;
    flex: 0 0 30%;
    flex-direction: column;
    padding: 0 1em;
  }

  .projects-list__item {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 1em;
    //height: 3em;
    margin-bottom: 1em;
  }

  .project__photo-wrapper {
    flex: 0 0 20%;
    margin-right: .5em;
  }
  .project__photo-outline {
    border: 2px solid $grey;
    display: block;
    padding: .25em;
  }
  .project__photo {
    display: block;
    max-width: 100%;
    width: 100%;
  }
  .project__name {
    color: $grey;
    flex: 0 0 80%;
  }

}

.projects-list__item:hover {
  .project__photo-wrapper .project__photo-outline {
    border: 2px solid $black;
  }
  .project__name {
    color: $black;
  }
}

.project-select-radio {
  position: absolute;
  visibility: hidden;
  &:checked {
    & ~ .project__photo-wrapper .project__photo-outline {
      border: 2px solid $black;
    }

    & ~ .project__name {
      color: $black;
    }
  }
}

.c-project-infographic {
  display: flex;
  justify-content: flex-start;
}

.project-data-temp-wrap {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
}

.c-project-close-circle {
  display: flex;
}

.c-project-circle {
  height: 3em;
  margin-right: .5em;
  width: 3em;
}

.c-project-circle__img {
  width: 100%;
}

.c-project-percentage__number {
  font-size: 1.5em;
  font-weight: 700;
}

.c-project-status {
}

.c-project-status__title {
  color: $black;
  font-size: 1em;
  font-weight: 400;
}

.c-project-status__data {
  font-size: .75em;
  li {
    line-height: 1.5;
  }
}

.c-project-content {
  &:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
  p {
    font-size: .75em;
    margin-top: 0;
  }
}

.c-project-content__title {
  color: $black;
  margin: 1em 0;
}

.c-project-content__img {
  float: left;
  margin: 0 1em 1em 0;
  width: 10em;
}

.project-map-wrap {
  margin-right: 1em;
}

.project-map {
  position: relative;
  width: 230px;
  height: 155px;
  border: 1px solid $black;
  background: url($assetsPath + 'images/worldmap.png') repeat #fff;
  img {
    width: 27px;
    height: 43px;
    border: 0px;
    position: absolute;
    top: 56px;
    left: 102px;
  }
}

.project-region {
  font-family: $lora;
  font-size: .75em;
}

.popup-content {
  display: none;

}

.project-pictures {
  display: flex;
  flex-direction: column;
  float: left;
  margin-right: 1em;
  width: 10em;
  img {
    margin-bottom: .5em;
    width: 100%;
  }
}

.project-donation-disclaimer {
  background: $green;
  color: $white;
  font-size: 1em;
  padding: 1em 1.5em;
}

.wraps {
  display: flex;
  height: 85%;
  justify-content: flex-start;
  padding-top: 1em;
}

@include media('<780px') {
  .project-popup {
    .featherlight-content {
      height: 80%;
      width: 80%;
    }
    .wraps {
      flex-direction: column-reverse;
      justify-content: flex-end;
    }
    .c-project-selector {
      .project-details {
        padding: 0;
      }
      .projects-list {
        flex-direction: row;
        margin-bottom: 2.5em;
      }
      .project__photo-wrapper {
        height: 100%;
        width: auto;
      }
      .project__photo-outline {
        height: 100%;
        width: auto;
      }
      .project__photo {
        height: 100%;
        object-fit: cover;
        width: auto;
      }
      .projects-list__item {
        flex-direction: column;
        margin-bottom: 1em;
      }
    }
  }
}
