$border-width: 3px;
.c-tabs {
  display: flex;
  ul {
    display: flex;
    border-bottom: $border-width solid $color-sunglow;
    flex: 1;
    justify-content: center;
  }
  li {
    display: none;
    margin-bottom: -$border-width;
    @include from($desktop) {
      display: block;
    }
    &.is-selected {
      display: block;
    }
  }
  label {
    align-items: center;
    border: $border-width solid transparent;
    cursor: pointer;
    display: flex;
    padding: .5em 1em;
    text-align: center;
    &:hover {
      background: $color-mustard;
    }
  }

  li.is-selected label {
    background: $color-sunglow;
    border: $border-width solid $color-sunglow;
  }
}

