.c-our-concept-bar {
  align-items: center;
  background: $color-sunglow;
  display: flex;
  justify-content: center;
  padding: .5rem;
}

.c-our-concept-bar__img {
  height: 3rem;
  margin-right: 1.5rem;
}

.c-our-concept-bar__text {
  margin: 0;
}