.c-overlay {
  background: rgba(0, 0, 0, .75);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  &.is-visible {
    display: block;
  }
}

