.c-global-tree-counter {
  background: $color-alabaster;
  border-radius: 1rem/50%;
  bottom: 1rem;
  cursor: pointer;
  left: 1rem;
  margin: 0;
  min-width: 5rem;
  padding: .25rem .75rem;
  position: fixed;
  transition: background ease-in-out .3s;
  z-index: 98;
  box-shadow: 0 0 15px 0 rgba(80, 80, 80, 0.25);
  &:hover {
    background: $color-isabelline;
  }
}

body.in-gallery .c-global-tree-counter {
  @include mobile() {
    display: none;
  }
}

.c-global-tree-counter__content {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.c-global-tree-counter__tree {
  margin-right: .75rem;
  width: 3rem;
}

.c-global-tree-counter__count {
  font-weight: 700;
}

.c-global-tree-counter__message {
  background: $color-isabelline;
  bottom: calc(100% + 2rem);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
  left: 0;
  padding: 1rem;
  position: absolute;
  width: 20rem;
  font-family: $bodyFont;
  &.is-closed {
    display: none;
  }
}

.c-global-tree-counter__button {
  padding: .25rem;
  position: absolute;
  right: 0;
  top: 0;
}

.c-trees {
  align-items: flex-start;
  border-bottom: 1px solid;
  border-top: 1px solid;
  display: flex;
  padding: 1.5rem 0;
  &.in-my-photocircle {
    align-items: center;
    border: 0;
    padding: 0;
  }
  &.has-no-border-top {
    border-top: 0;
  }
  &.has-no-border {
    border: 0;
    margin-bottom: 1.5rem;
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
}

.c-trees__icon {
  margin-right: 1.5rem;
  width: 5rem;
}

.c-trees {
  &.in-cart {
    border-bottom: 1px solid;
    //margin-bottom: 0;
    @include from($tablet) {
      .c-trees__icon {
        width: 2rem;
      }
    }
  }
}

.c-trees__learn-more {
  color: $color-accent;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.in-my-photocircle .c-trees__icon {
  width: 3rem;
}

.my-photocircle-sales-total-item .c-trees__icon {
  width: 2rem;
  display: inline-block;
  margin-right: 0;
  vertical-align: middle;
}

.c-trees__message {
  font-family: $headingsFont;
  line-height: 1.5;
  margin: 0;
  .detail-text {
    color: $grey;
    font-size: 0.8rem;
  }
  .o-link {
    font-size: 0.8rem;
  }
}

.c-trees__counter {
  font-weight: 700;
}
