.o-form-wrap {
  display: flex;
  justify-content: center;
}

.o-fieldset {
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
  .o-fieldset {
    margin-top: 3rem;
  }
  &.no-margin-bottom {
    margin-bottom: 0;
  }
}

.o-legend {
  border-bottom: 1px solid $orange;
  display: block;
  font-size: 1.5em;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  width: 100%;
}

.o-field {
  &.with-price,
  &.has-addons,
  &.is-horizontal {
    display: flex;
  }
  &.with-price {
    /*
    @include until($tablet) {
      font-size: .85em;
    }
    */
    .c-button {
      flex: 1;
    }
  }
  &.has-addons-tablet {
    .o-control:first-child {
      margin-bottom: 1.5rem;
    }
    @include from($tablet) {
      display: flex;
      .o-control:first-child {
        margin-bottom: 0;
      }
    }
    input {
      min-width: auto;
      width: 100%;
    }
  }

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }
  &.has-addons {
    display: flex;
    .o-input {
      margin-right: -1px;
      min-width: auto;
    }
    .c-button {
      border-width: 1px;
      font-size: 1em;
      padding: 6px;
      margin: 0;
    }
  }
  .o-error-message {
    color: $color-error;
    font-size: 0.75em;
    margin-top: 0.25rem;
  }
}

.has-addons-tablet {
  .c-button {
    border-width: 1px;
    font-size: 1em;
    padding: 6px;
    margin: 0;
  }
}

.o-control {
}

.o-label {
  display: block;
  margin-bottom: 0.5rem;
}

.o-textarea,
.o-input {
  max-width: 100%;
  min-width: 250px;
  width: 100%;
}

.o-select {
  max-width: 100%;
  &::after {
    content: "\25be";
    height: 1rem;
    width: 1rem;
  }
}

.o-radio,
.o-checkbox {
  display: inline-block;
}

.o-radio__box {
  cursor: pointer;
}

.o-radio--hidden {
  position: absolute;
  visibility: hidden;
}

.o-checkbox {
  line-height: 1.75;
}

.o-radio {
  & + .o-radio {
    margin-left: 0.5rem;
  }
}

.o-buttons {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
  &:last-child {
    margin-bottom: 0;
  }
  &.align-left {
    justify-content: flex-start;
  }
  &.align-right {
    justify-content: flex-end;
  }
}
