.o-juxtaposed {
  @include desktop {
    display: flex;
  }
  &.has-custom-order {
    @include until($desktop) {
      display: flex;
      flex-direction: column;
    }
  }
  &.is-mobile {
    display: flex;
  }
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}

.o-juxtaposed-item {
  flex: 1 1 0;
  &.o-juxtaposed--padded-container {
    padding: 3rem 1.5rem 0 1.5rem;
  }
  @include from($desktop) {
    &:first-child {
      padding-right: 1.5rem;
    }
    &:last-child {
      padding-left: 1.5rem;
    }
  }
  @include until($desktop) {
    &:first-child:not(.is-bottom):not(.no-padding) {
      padding-bottom: 1.5rem;
    }
    &:last-child:not(.is-top):not(.no-padding) {
      padding-top: 1.5rem;
    }
  }
  @include until($desktop) {
    &.is-top {
      order: -1;
      padding-bottom: 1.5rem;
    }
    &.is-bottom {
      padding-top: 1.5rem;
    }
  }
  &.is-two-fifths {
    @include desktop {
      flex: none;
      width: 40%;
    }
  }
  &.is-two-fifths {
    @include desktop {
      flex: none;
      width: 50%;
    }
  }
  &.no-padding {
      padding: 0;
  }
}


.o-juxtaposed-item--padded {
  padding: 0 1.5rem;
}
