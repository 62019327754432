.c-payment-icons {
}

.c-payment-icons__wrap {
  display: flex;
  flex-wrap: wrap;
}

.c-payment-icons__item {
  flex: 0 0 3em;
  margin-right: .5em;
  img {
    width: 100%;
  }
}