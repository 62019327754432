.popup {
    display: none;
    position: fixed;
    background: white;
    padding: 2rem;
    width: 50%;
    max-width: 800px;
    height: auto;
    overflow-y: auto;
    top: 5rem;
    //bottom: 5rem;
    left: 5rem;
    right: 5rem;
    z-index: 100;
    margin: auto;
    @include until($tablet) {
      top: 0.5rem;
      // bottom: 0.5rem;
      left: 0.5rem;
      right: 0.5rem;
      width: 50%;
      height: auto;
    }
    @include from($desktop) {
      width: 20rem;
      height: auto;
      max-height: 77%;
      padding: 1.5rem;
      .c-search-filter {
        margin-bottom: 1.5rem;
      }
    }
    .close-popup {
        position: absolute;
        top: 0px;
        right: 5px;
        color: black;
        cursor: pointer;
        font-size: 2rem;
        z-index: 101;
    }
  }