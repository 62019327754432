.static-page {
    padding: 5% 10%;
}

.christmas-delivery-times-products-list {
    ul {
        display: inline-block;
        list-style-position: inside;
        list-style-type: disc;
        li {
            padding: .75em;
            &:hover {
                background-color: $light_grey;
            }
        }
    }
}


.join-newsletter-page {
    .flex-columns {
        display: flex; 
        margin: auto; 
        width: 100; 
        max-width: 1200px;
    }
    .left {
        width: 50%; padding: 1rem;
        img {
            width: 100%;
        }
    }
    .right {
        width: 50%; padding: 1rem;
    }
    @include media('<620px') {
        .flex-columns {
            flex-direction: column;
        }

        .left, .right {
            width: 100%;
            padding: 0;
        }
        .right {
            padding-top: 1rem;
        }
    }
}