.o-section {
  padding: 3rem 1.5rem;
  &.is-gapless {
    @include until($desktop) {
      padding: 3em 0;
    }
  }
  &.m-top-padding {
    @include until($desktop) {
      padding-top: 1rem;
    }
  }
  &.m-no-vertical-padding {
    @include until($desktop) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.is-fullwidth {
    padding: 3em 0;
  }
}
.o-section-half-padding {
  padding: 1.5rem;
}
.o-section--old-alt {
  background: $color-alabaster;
}

.o-section--alt {
  background: $color-sunglow;
}

.o-section--alt-2 {
  background: $color-middle-blue-green;
  color: $color-white;
}
