.in-calendars {
  .c-gallery-sidebar {
    @include from($desktop) {
      flex: 0 0 15%;
    }
  }
}
.c-gallery-sidebar {

  @include from($desktop) {
    flex: 0 0 20%;
  }

  @include until($desktop) {
    position: fixed;
    z-index: 1000;
    background-color: white;
    overflow-y: auto;
    overflow-x: none;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0rem;
    padding: 1rem;
  }
  &.mobile-hidden {
    @include until($desktop) {
      display: none;
    }
  }



  /*
  grid-column: 1;
  grid-row: 1 / 3;
  @include media('<780px') {
    background: $white;
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    max-width: 100%;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    z-index: 1000;
    width: 20em;
    &.is-visible {
      transform: translateX(0);
    }
    .filters-wrap {
      flex: 1;
      height: 100%;
      overflow: auto;
      padding: 1em 2em;
    }
  }
  */
}

.filters-wrap {
  display: none;
  position: fixed;
  background: white;
  padding: 2rem;
  width: 30rem;
  height: 30rem;
  overflow-y: auto;
  top: 5rem;
  bottom: 5rem;
  left: 5rem;
  right: 5rem;
  z-index: 100;
  margin: auto;
  @include until($tablet) {
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
  }
  @include from($desktop) {
    width: 20rem;
    height: auto;
    max-height: 77%;
    padding: 1.5rem;
    .c-search-filter {
      margin-bottom: 1.5rem;
    }
  }

}
.c-search-filter {
  margin-bottom: 3em;
}

.c-gallery-sidebar__no-filters-text {
  color: $grey;
  font-family: $montserrat;
  font-weight: 300;
  margin: 0;

}

.c-search-filter__title {
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 1em;
}

.gallery-sidebar-filter {
  color: $black;
  &.is-selected {
    color: $orange;
  }
}

.c-active-filters {
  max-width: 15em;
}

.c-active-filters__item {
  background: $light_grey;
  display: inline-block;
  padding: .25em .5em;
  margin: 0 .5em .5em 0;
}

.c-active-filters__content {
  align-items: center;
  display: flex;
}

.c-active-filters__text {
  margin-right: .5em;
}

.c-active-filters__close-button {
  color: $grey;
  cursor: pointer;
  font-size: 1.5em;
  line-height: 1;
  &:hover {
    color: $black;
  }
}

.c-gallery-sidebar__close {
  cursor: pointer;
}
.gallery-formats-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}
.gallery-sidebar-format-filter.gallery-sidebar-filter {
  border: 3px solid $black;
  cursor: pointer;
  position: relative;
  width: 30%;
  //display: inline-block;
  font-size: .75em;
  margin-bottom: 1rem;
  &.panoramic-format {
    width: 100%;
  }
  &.is-selected,
  &:hover {
    background: $black;
    color: $white;
    border: 2px solid $black;

  }
  &:before {
    display: block;
    content: "";
    width: 100%;
  }
  &.format-filter-classic:before {
    padding-top: 56.25%;
  }
  &.format-filter-panoramic:before {
    padding-top: 33.33%;
  }
  &.format-filter-squared:before {
    padding-top: 100%;
  }
  &.format-filter-vertical:before {
    padding-top: 133%;
  }
  .content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.format-filter-classic {
}

.c-category-filters__item {
  margin-bottom: .5em;
  &.is-hidden {
    display: none;
  }
}

.c-category-filters__text {
  cursor: pointer;
  font-size: .75em;
  text-transform: uppercase;
  &.is-selected,
  &:hover {
    color: $orange;
  }
}

.size-filter-wrap {
  margin-bottom: 1em;
  position: relative;
}

.c-search-filter__size {
  width: 90%;
}

.size-filter-value-labels {
  position: absolute;
  top: 90%;
  width: 100%;
}

.size-filter-label {
  font-size: .75em;
  position: absolute;
}

.size-filter-max-label {
  right: 0;
}

.gallery-sidebar-filter-label {
  display: block;
  margin-bottom: 1em;
}
