
@function calc-percent-size($total-bg-size, $image-size) {
  @return ($total-bg-size / $image-size) * 100%;
}

@function calc-percent-position($total-bg-size, $image-size, $position) {
  @return (abs($position) / ($total-bg-size - $image-size) * 100%);
}

@mixin responsive-sprite($total-bg-w, $total-bg-h, $image-w, $image-h, $x, $y) {
  background-position: calc-percent-position($total-bg-w, $image-w, $x) calc-percent-position($total-bg-h, $image-h, $y);
  background-size: calc-percent-size($total-bg-w, $image-w) calc-percent-size($total-bg-h, $image-h);
}

@mixin shadow($level: 1) {
  @if $level == 1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  } @else if $level == 2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  } @else if $level == 5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  }

}

