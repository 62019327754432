.press-page-articles {
  border-collapse: collapse;
  display: table;
  margin: auto;
  width: 65%;
}

.press-page-article {
  border-bottom: 1px solid $grey;
  display: table-row;
  height: 200px;
}

.press-page-article-picture {
  display: table-cell;
  padding: 24px;
  text-align: center;
  vertical-align: middle;
}

.press-page-article-text {
  display: table-cell;
  padding: 24px;
  vertical-align: middle;
  .info {
    font-family: $lora;
    font-size: calc-em(12px, 16px);
  }
  .pdf-link {
    color: $color-text;
    font-size: calc-em(10px, 16px);
    text-decoration: none;
    text-transform: uppercase;
  }
}

.press-page-article-title {
  font-size: calc-em(18px, 16px);
  a {
    color: $black;
    font-size: inherit;
    text-decoration: none;
  }
}
