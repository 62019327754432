#user-public-profile-page {
	
	#user-headline {
		.img-container {
			width: 180px;
			height: 180px;
			border-radius: 100px;
			text-align: center;
			overflow: hidden;
			float: left;
			margin: 0px 20px 20px 0px;
			img {
				min-width: 180px;
				min-height: 180px;
				margin: 0px;
			}
		}
		
	}
	
	#user-profile-tabs-menu {
		> div, > a {
			display: inline-block;
			background: $light_grey;
			padding: 10px 20px;
			margin-right: 10px;
			border-radius: 3px;
			cursor: pointer;
			text-decoration: none;
			color: $black;
			border: 2px solid $light_grey;
			&.selected {
				border-color: $grey;
			}
			i {
				margin-right: 10px;
			}
		}
	}
	
	.tab {
	//	display: none;
		width: 800px;
		.tab-headline {
			margin: 40px 0px 20px 10px;
			font-weight: bold;
		}
		.rows-container {
			> div {
				margin: auto;
				padding: 15px 10px;
				border-bottom: 1px solid $light_grey;
				font-family: $lora;
				font-size: 13px;
				line-height: 20px;
				.left-column {
					width: 180px;
					float: left;
					margin: 3px 20px 5px 0px;
					img {
						width: 180px;
					}
				}
				.right-column {
					margin-left: 200px;
				}
				
			}
		}
	}
	
	/*
	.column {
		width: 220px;
		min-height: 300px;
		background: $light_grey;
		float: left;
		margin: 0px 10px;
		padding: 10px;
		border-radius: 3px;
	}
	.column-headline {
		color: $orange;
	}
	.rows-container {
		margin: 10px 0px;
		> div {
			border-bottom: 1px solid $grey;
			padding: 5px;
			font-size: 11px;
			font-family: $lora;
			line-height: 15px;
			overflow: hidden;
			img {
				float: left;
				margin: 3px 10px 5px 0px;
				max-width: 80px;
				max-height: 100px;
			}
		}
	}
	#favourites-column {
		img {
			width: 220px;
			margin-bottom: 5px;
		}
	}
	*/
}
