#newsletter-popup-2 {
	&.de {
		background: url($assetsPath + 'images/subscribe_to_newsletter_banner_de.jpg');
	}
	&.en {
		background: url($assetsPath + 'images/subscribe_to_newsletter_banner_en.jpg');
	}
	display: none;
	width: 960px;
	height: 250px;
	border: 13px solid #fafafa;
	position: fixed;
	top: 160px;
	z-index: 199;
	text-align: center;
	border-radius: 5px;
	box-shadow: 0px 0px 3px #606060;
	div.subscribe-to-newsletter-form {
		position: absolute;
		top: 155px;
		left: 26px;
		text-align: left;
		input {
			width: 230px;
			height: 28px;
			padding-left: 10px;
		}
		.button {
			text-align: center;
			width: 130px;
		}
	}
}

#newsletter-popup {
	background: $white;
	display: none;
	width: 600px;
	height: 500px;
	padding: 20px;
	position: fixed;
	top: 60px;
	z-index: 199;
	text-align: center;
	border-radius: 5px;
	.logo-container {
		margin-top: 10px;
		margin-bottom: 25px;
	}
	> .orange {
		margin-bottom: 45px;
	}
	> div.reason {
		margin: 0px auto;
		width: 400px;
		text-align: left;
		min-height: 60px;
		margin-bottom: 50px;
	}
	> div.reason-3 {
		margin-bottom: 0px;
	}
	> div.reason:last-child {
		margin-bottom: 0px;
	}
	> div > .icon {
		width: 85px;
		text-align: left;
		float: left;
	}
	> div > .right-column {
		width: 310px;
		margin-left: 85px;
		p {
			margin-top: 3px;
		}
	}
	//> div.subscribe-to-newsletter-form {
		//margin-top: 20px;
		//input {
			//width: 230px;
			//height: 28px;
			//padding-left: 10px;
		//}
		//.button {
			//width: 130px;
		//}
	//}
}
#newsletter-bodyclick {
	display: none;
	position: fixed;  
	_position: absolute; /* hack for internet explorer 6*/  
	height: 100%;  
	width: 100%;  
	top: 0;  
	left: 0;
	z-index: 198;
	background: $black;
	cursor: pointer;
}
/*
#close-newsletter-popup {
	color: $orange;
	font-size: 36px;
	position: absolute;
	right: -22px;
	top: -22px;
	cursor: pointer;
}
*/

#close-newsletter-popup {
	display: none;
	position: fixed;
	top: 10px;
	right: 10px;
	text-align: center;
	color: white;
	opacity: 0.8;
	font-size: 35px;
	text-shadow: 0px 0px 3px #000;
	cursor: pointer;
	z-index: 199;//100;
	font-family: $montserrat !important;
}

/*
div.subscribe-to-newsletter-form {
	margin-top: 20px;
	input {
		width: 230px;
		height: 28px;
		padding-left: 10px;
	}
	.button {
		text-align: center;
		width: 130px;
	}
}
*/
