#wall-preview-container {
	width: 100%;
	height: 100%;
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.8);
	z-index: 9990;
	cursor: pointer;
}
#wall-preview {
	width: 1000px;
	height: 660px;
	top: 100px;
	
	img#photo-on-wall {
		max-width: 490px;
		max-height: 255px;
		box-shadow: 0px 0px 3px #666;
		z-index: 10;
	}
	img#photo-on-wall.no-shadow {
		box-shadow: none;
		border: 1px solid #dcd9d9;
	}
	.frame-part {
		display: none;
		position: absolute;
		z-index: 11;
	}
	.top-left, .top-right, .bottom-left, .bottom-right {
		width: 8px;
		height: 8px;
	}
	.top-center, .bottom-center {
		width: 84px;
		height: 8px;
	}
	.center-left, .center-right {
		width: 8px;
		height: 84px;
	}
	.behind-shadow {
		box-shadow: 0px 0px 3px #666;
		position: absolute;
		display: none;
	}
	.passepartout-frame {
		display: none;
		position: absolute;
		z-index: 9;
		background: white;
		border: 5px solid black;
		box-shadow: 0px 0px 3px #666;
	}
	.passepartout-frame-thickness-2-cm {
		border-width: 5px;
	}
	.passepartout-frame-thickness-4-cm {
		border-width: 10px;
	}
	.passepartout-frame-color-1 {
		border-color: black;
	}
	.passepartout-frame-color-2 {
		border-color: #ececec;
	}
	.passepartout-frame-color-4 {
		border-color: #c9945c;
	}
}
#wall-preview #photo-on-wall {
	max-width: 780px;
	max-height: 400px;
	box-shadow: 0px 0px 5px #888;
}
