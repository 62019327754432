.in-photos {
  .c-print-quality {
    display: none;
  }
}

.c-print-quality {
  display: flex;
  justify-content: space-between;
  .fa-star {
    color: $orange;
  }
}
