#checkout {
  #error-message {
    color: $red;
    border: 1px solid $red;
    padding: 15px;
    margin-bottom: 30px;
  }
}

.payment-method-content {
  padding: 0 1.5rem;
}

#advanced-payment-form ul,
#advanced-payment-form ul li,
#sofort-form ul,
#sofort-form ul li,
#paypal-form {
  font-family: $bodyFont;
  list-style-type: disc;
}

#sofort-form ul, #sofort-form ul li {
  list-style-type: none;
}

.checkout-page-info-icons {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;

  .checkout-page-info-icon {
    flex: 1;
    padding: 0 1.5rem;

    &.contact-us,
    &.delivery-methods {
      @include until($tablet) {
        display: none;
      }
    }
  }

  img {
    margin: 0 auto 1rem auto;
    max-height: 2rem;
    max-width: 5rem;
    width: auto;
  }

  figcaption {
    font-size: .85em;
  }
}

.checkout-page-tables-container {
  justify-content: space-between;
  @include from($desktop) {
    display: flex;
  }
}

.checkout-page-summary-container {
  flex: 0 0 40%;
  @include until($desktop) {
    margin-bottom: 1.5rem;
  }

  .o-label {
    font-weight: 400;
  }
}

.checkout-page-logo {
  display: block;
  margin: auto;
  width: 15rem;
}


.payment-methods__item {
  margin-bottom: 1rem;
}

.checkout-cards-wrap {
  display: inline-block;
  vertical-align: middle;

  img {
    width: 3rem;
  }
}

.cvc {
  input {
    width: 5rem;
  }

  img {
    vertical-align: middle;
    width: 3rem;
  }
}

.c-order-summary__item {
  font-family: $bodyFont;
  //width: 10rem;
  li {
    margin-bottom: .25rem;
  }
  .item-name {
    font-size: 1em;
    font-weight: 700;
    margin-bottom: .5rem;
  }
  .item-description {
    line-height: 1.5rem;
  }
  .item-price {
    margin-top: 1rem;
  }
}

#loading-bodyclick {
  background: white;
  opacity: 0.8;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 101;
  text-align: center;
  img {
    margin-top: 3rem;
  }
}