.reviews-container {
    margin: 3rem 0;
    margin-left: 18.2%;
    width: 100%;
    @include until($desktop) {
        margin-left: 0;
    }
}
.in-calendars {
    .reviews-container {
        margin-left: 0;
    }
}
.review-headline {
    font-size: 1.2rem;
    vertical-align: middle;
    .review-rating {
        margin: 0rem 1rem;
        vertical-align: middle;
        color: $color-mustard;
    }
    img {
        width: 30px;
        vertical-align: middle;
    }
    a {
        font-size: 0.8rem;
    }
}
.review-row {
    margin: 1rem 0;
    padding: 0.5rem 0;
    // background-color: $color-grey;
    display: flex;
    font-size: 0.9rem;
    border-bottom: 1px solid $color-isabelline;
}
.review-row .left {
    width: 70%;
    padding: 0.5rem;
    padding-left: 0;
}
.review-row .right {
    width: 30%;
    padding: 0.5rem;
    padding-right: 0;
}
.review-row .review-rating {
    color: $color-mustard;
    margin-bottom: 0.5rem;
}
.review-user img {
    width: 30px;
}
.review-user-row {
    margin-top: 1rem;
    font-size: 0.8rem;
}
.review-user-row > div {
    display: inline-block;
    vertical-align: middle;
}
.review-user-name a {
    color: $color-dim-gray;
    border-bottom: 1px dotted currentColor;
}
.review-date {
    margin-left: 1rem;
}
.survey-stat {
    margin-bottom: 1rem;
    font-size: 0.8rem;
}
.review-row .bar {
    width: 100%;
    margin-top: 0.35rem;
    height: 8px;
    display: flex;
    background: $color-silver;
    border-radius: 1rem;

}
.bar > div {
    width: 20%;
    box-sizing: border-box;
    background-color: $color-charleston-gray;
    // margin-right: 1px;
    &:first-child {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }
    &:last-child {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }
}
.bar .bar-segment-full {
    // background: $color-dim-gray;
}
.blurry-text {
    color: transparent;
    text-shadow: 0 0 5px rgba(0,0,0,0.5);
 }