.c-newspapers {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 1em 0;
}

.c-newspapers__column {
    flex: 100%;
    padding: 1em;
    text-align: center;
  
    @include media('>600px') {
      flex: 50%;
    }
    @include media('>780px') {
      flex: 1;
      &:first-child {
        border-left: 0;
      }
    }

    img {
        width: 100%;
        max-width: 250px;
        height: auto;
        margin: auto;
        &.enorm {
            width: 150px;
        }
        @include media('<600px') {
          width: 75%
      }
    }

    img.resize-on-mobile {
        @include media('<600px') {
            width: 35%
        }
    }
  }