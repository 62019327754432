.c-pagination {
  align-items: center;
  display: flex;
  font-size: 1em;
  justify-content: center;
}

.c-pagination--many-pages {
  > .c-pagination__page {
    display: none;
  }
}

.c-pagination > li + li {
  margin-left: 1.5rem;
}

.c-pagination__link {
  align-items: center;
  color: $color-text;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  width: 1.5rem;
}

.c-pagination__current,
.c-pagination__link:hover {
  background: $color-sunglow;
}

.c-pagination__page {
  position: relative;
  @include until($tablet) {
    display: none;
  }

  @include from($tablet) {
    &.is-visible {
      display: flex;
    }
  }
}

@include from($tablet) {
  .c-pagination__page--ellipsis-before::before,
  .c-pagination__page--ellipsis-after::after {
    content: '...';
    display: flex;
    position: absolute;
  }
  .c-pagination__page--ellipsis-after::after {
    right: -1rem;
  }
  .c-pagination__page--ellipsis-before::before {
    left: -1rem;
  }
}

.c-pagination__first,
.c-pagination__last,
.c-pagination__current {
  display: flex !important;
}

@include until($tablet) {
  .c-pagination__current::before,
  .c-pagination__current::after {
    content: '...';
    display: flex;
    position: absolute;
  }
  .c-pagination__current::after {
    right: -1rem;
  }
  .c-pagination__current::before {
    left: -1rem;
  }
}

.c-pagination__last::after,
.c-pagination__first::before {
  content: '';
}

