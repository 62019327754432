.c-reel {
  display: flex;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 1.5rem;
  scrollbar-color: $grey $color-white;
  scrollbar-width: thin;
}

.c-reel::-webkit-scrollbar {
  height: 1rem;
}

.c-reel::-webkit-scrollbar-track {
  background-color: $color-white;
}

.c-reel::-webkit-scrollbar-thumb {
  background-color: $color-white;
  background-image: linear-gradient($color-white 0, $color-white 0.25rem, $color-dim-gray 0.25rem, $color-dim-gray 0.75rem, $color-white 0.75rem);
}

.c-reel > * {
  // this works for products in the photo page
  // in other pages we probably would need a different width
  /* Using calc we make sure that we show always 3 products plus
   a piece of another product, so it's clear that one can scroll */
  flex: 0 0 calc(50% - 2.5rem);
  @media (min-width: 580px) and (max-width: 1087px) {
    flex: 0 0 calc(33% - 2.5rem);
  }
}

.c-reel > img {
  height: 100%;
  flex-basis: auto;
  width: auto;
}

.c-reel > * + * {
  margin-left: 1.5rem;
}

.c-reel.overflowing {
  padding-bottom: 1.5rem;
}