.c-photolab-controls {
  .o-buttons {
    flex-wrap: wrap;
    justify-content: flex-start;
    & > * + * {
      margin-left: .25rem;
    }
  }
}

#custom-print-controls {
  display: none;
}

#custom-print-preview {
  .container-internal {
    display: block;
    width: 100%;
    background: $color-silver;
    margin: auto;
    //height: 700px;
    max-width: 640px;
    max-height: 640px;
    img {
      width: 100%;
    }
    /*
    @include until($desktop) {
      height: 500px;
    }
    */
  }
  /*
  #custom-print-img {
    display: block;
    max-width: 100%;
  }
  */
  .cropper-bg {
    background: none;
  }
  .cropper-modal {
    //background: #fff;
  }
}

.cp-button {
  border-width: 1px;
  font-size: 1em;
  font-weight: 300;
  margin-bottom: 0;
  padding: 1rem;
  @include until($tablet) {
    font-size: .85em;
    padding: .75rem;
  }
  &:hover,
  &.selected {
    background: $black;
    color: $color-white;
  }
}

.cp-control {
  color: $black !important;
  cursor: pointer;
  &__text {
    border-bottom: 3px solid currentColor;
  }
  &:hover {
    color: $color-accent !important;
  }
}

.cropper-black-and-white {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

// crop-box
.cropper-view-box {
  outline-color: white;
}
.cropper-line, .cropper-point {
  background-color: white;
}

#cp-default-data {
  margin: 0;
}

.cp-black-and-white {
  display: flex;
  align-items: center;
  label {
    margin-right: 1.5rem;
  }
}