table {
  border: 1px solid $color-section-background-main;
  border-spacing: 0;
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  th {
    background-color: $color-section-background-main;
    border: 1px solid $color-section-background-main;
    color: $white;
    vertical-align: middle;
    padding: .20rem 0.15rem;
    font-size: 0.6rem;
    @include from($desktop) {
      font-size: 1rem;
      padding: .75rem 1.5rem;
    }
  }
  td {
    border: 1px solid $color-section-background-main;
    line-height: 1.5;
    padding: .20rem 0.15rem;
    font-size: 0.7rem;
    @include from($desktop) {
      font-size: 1rem;
      padding: .75rem 1.5rem;
    }
  }

  &.v-align-top {
    td {
      vertical-align: top;
    }
  }

}
