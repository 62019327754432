@include from($desktop) {
  .thank-you-page-header {
    .c-headline {
      animation: 1s ease-in-out 0s 1 forwards fade-in-1;
      color: $color-middle-blue-green;
      font-size: 3em;
      opacity: 0;
    }
    .c-headline-sub {
      animation: .5s ease-in-out 1s 1 forwards slide-in;
      font-size: 2.5em;
      transform: translateX(100%);
    }
    p {
      animation: .5s ease-in-out 1.5s 1 forwards fade-in-2;
      font-size: 1.5em;
      opacity: 0;
    }
  }
}

@keyframes fade-in-1 {
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-2 {
  100% {
    opacity: 1;
  }
}

@keyframes slide-in {
  100% {
    transform: translateX(0);
  }
}

.thank-you-order-summary {
  .thank-you-order-summary__section + .thank-you-order-summary__section {
    margin-top: 3rem;
  }
  dd {
    font-weight: 400;
    margin-right: .25rem;
  }
}