// For some reason without this import it doesn't work
@import '../tools/mixins';
@import '../tools/responsive-mixins';
@import '../settings/breakpoints';

:root {
  --device: mobile;
  @include from($tablet) {
    --device: tablet;
  }

  @include from($desktop) {
    --device: desktop;
  }

  @include from($widescreen) {
    --device: widescreen;
  }

  @include from($fullhd) {
    --device: fullhd;
  }
}

html {
  color: $color-text;
  font-family: $headingsFont;
  font-size: 100%;
  font-weight: 300;
  overflow-x: hidden;
  scroll-behavior: smooth;
  /*
  @include media("<1124px") {
    font-size: calc-em(12px, 16px);
  }

  @include media("<624px") {
    font-size: calc-em(10px, 16px);
  }
  */
  &.t-font-size-reset {
    font-size: 100%;

  }
}

body {
  z-index: 0;
  /*
  padding-top: 130px;
  &.has-offer-bar {
    padding-top: 156px;
  }
  @include from($tablet) {
    padding-top: 170px;
  }
  @include from($desktop) {
    padding-top: 159px;
  }
  */
}

p {
  @include body-text();
  &:last-child {
    margin-bottom: 0;
  }
}

a {
  text-decoration: none;
}

/* Style links with no classes */
.o-link,
a:not([class]) {
  @include link;
}

input, select, option, textarea {
  background: $white;
  border: 1px solid $medium_light_grey;
  font-family: $lora;
  font-size: 1em;
}

option {
  border: 0;
}

input {
  padding: 6px;
}


