.c-product {

}

.c-product__image {
  width: 100%;
}

.c-product__content {
  margin-top: 1rem;
}

.c-product__title {
  font-weight: 400;
  margin-top: .5rem;
  text-transform: capitalize;
}

.c-product__link {
  color: $black;
  &:hover {
    color: $color-accent;
  }
}

