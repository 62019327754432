.o-hero {
  &.is-plain {
    background: $color-sunglow;
    &.kwerfeldein {
      background: #f2f0e6;

    }
    .o-hero__body {
      color: $color-text;
    }
  }
  p {
    font-size: 0.85rem;
  }
}

.o-hero__body {
  color: $black;
  padding: 1.5rem;
}

.o-new-hero {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: minmax(300px, auto);
}

.o-new-hero__picture {
  grid-column: 1 / -1;
  grid-row: 1;
}
.in-magazine {
  /*
  .o-hero__body {
    color: red;
    background: $color-fountain-blue url($assetsPath + 'images/bg-pattern.png');
  }
  
  .o-new-hero__picture {
      background: $color-fountain-blue url($assetsPath + 'images/bg-pattern.png');
  }
      */
      .o-new-hero__body {
        h1, h2, h3 {
          color: black;
        }
      }
      .o-new-hero.has-overlay {
        h1, h2, h3 {
          color: white;
        }
      }
}

.o-new-hero__body {
  //color: $white;
  color: black;
  display: flex;
  flex-direction: column;
  grid-column: 1;
  grid-row: 1;
  justify-content: center;
  padding: 1.5rem;
}

.o-new-hero.has-overlay .o-new-hero__body {
  background: rgba($black, .5);
}
