.page-photographer {
  .medium-column {
    width: 100%;
    max-width: 750px;
    margin: auto;
  }
  .c-trees__icon-en {
    width: 9rem;
  }
  .c-trees__icon-de {
    width: 5.5rem;
  }
}
.photographer-profile-info-text  {
  line-height: 1.5rem;
  margin-top: 1rem;
}
.c-photographer-profile {
  margin-bottom: 1.5rem;
}
.profile-section {
  margin: 2rem auto 0;
}

.photographer-profile-image {
//  border-radius: 50%;
  height: 5rem;
  width: 5rem;
  overflow: hidden;
  &.in-photo-page {
    width: 3rem;
    height: 3rem;
  }
  img {
    height: 100%;
    object-fit: cover;
  }
}

.photographer-profile-info-concept {
  display: none;
  font-size: 1em;
  line-height: 1.25;
  width: 100%;
  @include from($desktop) {
    display: block;
    //font-size: .75em;
  }
  &.mobile {
    display: block;
    margin-bottom: 3rem;
    text-align: center;
    @include from($desktop) {
      display: none;
    }
  }
}

.photographer-profile-info-concept-btn-wrap {
  padding: 0 calc-em(12px, 12px);
  vertical-align: middle;
  .concept-btn {
    background: $green;
    color: white;
    font-family: $montserrat;
    font-size: calc-em(16px, 16px);
    padding: calc-em(16px, 16px);
    text-align: center;
    text-transform: uppercase;
  }
}

.photographer-profile-info-text {
  @include until($desktop) {
    display: none;
  }
  p {
    margin: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    font-family: $lora;
    list-style-type: disc;
    list-style-position: inside;
    li {
      margin-bottom: 12px;

    }
  }

  .photograper-questions {
    list-style-type: none;
    .photographer-question {
      font-weight: 700;
      margin-bottom: .5em;
      font-size: 1rem;
      padding-bottom: 0;
    }
    .photographer-answer {

    }
  }
}

.profile {
  padding: 6% 4%;
  .container {
    background-color: rgba($white, 0.95);
    padding: 3%;
  }
}

.photographer-hiw-video {
  flex: 0 0 30%;
  margin-right: 1em;
}

.photographer-sidebar {
  //flex: 0 0 9%;
  //margin-right: 5%;

}

.photographer-profile-website-icons-container {
  display: none;
  @include from($desktop) {
    display: flex;
  }
  li {
    margin-right: 1.5rem;
    padding: .75rem 0;
  }
  a {
    border: none;
    color: $black;
    display: inline;
    i {
      margin-right: .5em;
      width: 1em;
    }
    &:hover {
      color: $orange;
    }
  }
}

.photographer-find-out-more {
  cursor: pointer;
  font-size: 1em;
  border-bottom: 1px dotted $orange;
  color: $orange;
  display: inline;
  @include until($desktop) {
    // display: none;
  }
  &:hover {
    color: $orange;
  }

}

.photographer-calendars {
//  border-top: 1px solid black; 
//  border-bottom: 1px solid black; 
//  padding: 4rem 0;
}
h4 {
  font-weight: 400;
  font-size: 1rem;
  padding-bottom: 2rem;
}
.photograper-questions h4 {

}