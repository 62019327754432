.customer-shared-photos-list, .categories-mockups-list {
    display: flex; 
    flex-wrap: wrap;
}
.customer-shared-photo-row, .category-mockup-row {
    width: 25%; 
    padding: 10px;
    position: relative;
    a {
        text-decoration: none;
        border-bottom: 0px;
        color: white;
    }
    .img-link:hover {
        img {
            opacity: 0.6;
            //transition: opacity 0.1s ease-in;
        }
    }
    img {
        width: 100%;
    }
    .customer-name {
        position: absolute;
        bottom: 25px;
        left: 20px;
        background: rgba(0, 0, 0, 0.6);
        color: white;
        padding: 5px 10px;
        font-size: 0.8rem;
        vertical-align: middle;
        max-width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .customer-name a:hover {
        color: $yellow;
    }
    .icon {
        width: 1.4em;
        height: 1.4em;
        margin-bottom: -5px;
    }
    span {
        vertical-align: middle;
    }
    @include media('<860px') {
        & {
            width: 50%;
        }
        .customer-name {
            font-size: 0.65rem;
        }
    }
    @include media('<400px') {
        & {
            width: 100%;
        }
    }
}


.in-home {
    .customer-shared-photo-row {
        padding: 15px;
    }
}

.category-mockup-row {
    a div {
        text-align: center;
        color: black;
        padding-top: 0.25rem;
    }
    a span {
        border-bottom: 1px dotted $grey;
    }
}