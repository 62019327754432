.c-photo-page-price-container {
  border-bottom: 1px solid #ddd;
  margin-bottom: 1.5em;
  padding-bottom: 1em;
}

.c-photo-sidebar {
  flex: 0 0 30%;
  @include until($desktop) {
    margin-top: 0rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $light_grey;
    .c-photo-price {
      display: none;
    }
  }
}
.page-buy_photo p {
  font-size: 0.8rem;
}

.buy-button-container {
  @include until($desktop) {
    height: 0;
    margin-top: 0;
  }
}
.frame-color-preview {
  background-size: 1.5em;
  background-position: 50%;
  background-repeat: no-repeat;
  border: 2px solid $grey;
  border-radius: 100%;
  display: inline-block;
  height: $optionRadioButtonSize;
  padding: 3px;
  width: $optionRadioButtonSize;
  &-img {
    width: 100%;
  }
  &.no-frame {
  }
  &.black {
    //background-image: url($productImagePath + 'frame-black-oak.jpg');
  }
  &.brown_1 {
    //background-image: url($productImagePath + 'frame-brown-walnut.jpg');
  }
  &.brown_2 {
    //background-image: url($productImagePath + 'frame-brown-oak.jpg');
  }
}

.c-photo-price {
  &__value {
    font-size: 1.5em;
    font-weight: 400;
  }
}

.photo-page-all-options-wrap {
  &.is-hidden {
    display: none;
  }
}

.c-photo-our-donation {
  border-bottom: 1px solid $black;
  padding: 1em 0;
  &__text {
    font-family: $montserrat;
    font-weight: 100;
    line-height: 1.25;
    margin: 0;
  }
  &__popup-link {
    color: $orange;
    cursor: pointer;
  }
}

.sidebar-contents {
    animation-fill-mode: none !important;
    background: $color-white;
    box-shadow: 7px 0px 5px 0px rgba(0,0,0,0.39);
    color: $color-text;
    height: 100vh;
    left: 0;
    margin: 0;
    overflow-y: auto;
    padding: 1.5rem;
    position: fixed;
    top: 0;
    /* the -16px is to avoid having the box-shadow visible when the sidebar is not */
    transform: translateX(calc(-100% - 16px));
    transition: transform 0.2s ease-in;
    transform-style: preserve-3d;
    width: 90vw;
    z-index: 200;
    @include from($desktop) {
        width: 30vw;
    }
    &.show {
      transform: translateX(0);
      z-index: 200;
    }

    .back-button {
      color: $orange;
      cursor: pointer;
    }
    .product-type-image {
      margin-top: 1rem;
      width: 100%;
    }
    li {
      margin: 1rem 0;
    }
    p {
      color: $white;
      font-family: $montserrat;
      margin-top: 0;
    }
    ul {
      line-height: 1.25;
      list-style-position: outside;
      list-style-type: circle;
      margin: 0;
      padding-left: 1em;
    }
  @include from($desktop) {
    //display: none;
  }
}
.c-product-features-tooltip {
  p {
    color: $white;
    font-family: $montserrat;
    margin-top: 0;
  }
  ul {
    line-height: 1.25;
    list-style-position: outside;
    list-style-type: circle;
    margin: 0;
    padding-left: 1em;
  }
}

.c-product-customizer__error {
  background: red;
  color: $white;
  display: none;
  font-size: .75em;
  margin: 1em 0;
  opacity: .55;
  padding: .5em;
  text-align: center;
}

.express-delivery-block {
  padding: 0.5rem; 
  border: 1px solid #eee; 
  margin-top: 1rem;
  img {
    width: 17px; 
    vertical-align: middle;
  }
}