.o-media {
  align-items: flex-start;
  display: flex;
  text-align: left;
  @include until($tablet) {
    align-items: center;
    flex-direction: column;
  }
  + .o-media {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
  }
  &.v-center {
    align-items: center;
  }
}

.o-media-left,
.o-media-right {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.o-media-left {
  margin-right: 1rem;
}

.o-media-right {
  margin-left: 1rem;
}

.o-media-content {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
}

