strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
ul.default {
  list-style: disc;
  padding: 1.5rem;
}
.muli {
  font-family: "Muli";
}
.u-no-scroll {
  overflow: hidden;
}

.terms-of-use-dialog-wrap,
.shipping-times-dialog-wrap {
  display: none;
}

.youtube-container {
  display: block;
  //max-width: 640px;
  width: 100%;
}

.youtube-player {
  cursor: pointer;
  display: block;
  height: 100%;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%; /* assuming that the video has a 16:9 ratio */
}

img.youtube-thumb {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  height: auto
}

div.play-button {
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  position: absolute;

  background: url($assetsPath + "images/video-play-icon.png") no-repeat;
}

#youtube-iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}