.photograpers-page-wrapper {
  padding-top: 3em;
}

.c-photographers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.c-photographer {
  flex: 0 0 auto;
  margin: 1em;
  text-align: center;
  width: 10em;
}

.c-photographer-profile {
  .o-media-left {
    margin-right: 0.5rem;
  }
  .o-media-right {
    flex-shrink: initial;
  }
 @include until($tablet) {
  .photographer-profile-image {
    margin-bottom: 1.5rem;
  }
   .o-media-left {
     margin-right: 0;
   }
   .o-media-right {
     margin-left: 0;
     text-align: center;
   }
 }
}

.c-photographer__img-wrap {
  display: block;
  height: 10em;
  width: 10em;
}

.c-photographer-footer {
  margin-top: 1em;
}

.c-photographer__profile-picture {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.c-photographer-name {
  color: $black;
  &:hover {
    text-decoration: underline;
  }
}

.c-photographer__city,
.c-photographer__photos-count {
  color: $grey;
  font-size: .75em;
}



.artists-pagination {
  text-align: center;
  margin: 3rem auto;
  line-height: 2rem;
  .pagination-element {
    display: inline-block;
    margin: 0px 10px;
  }
}