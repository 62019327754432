@import 'module_create_profile';

.my-photocircle-menu {
  align-items: center;
  background: $black;
  display: flex;
  //justify-content: space-around;
  .menu-voice {
    color: $white;
    flex: 1 1 auto;
    font-size: .75em;
    padding: 1em 0;
    text-align: center;
    text-decoration: none;
    &.selected,
    &:hover {
      background: $medium_light_grey;
    }
    &.my-photocircle-menu-sell-photos {
      background: $orange;
    }
    i {
      display: block;
    }
  }
}

.my-photocircle-photographer-offer {
  background: $color-sunglow;
  padding: 1rem;
}

.my-photocircle-right-column {
  margin-left: 260px;
  margin-bottom: 1em;
  width: 940px;
}

.my-photocircle-page-content-wrapper {
  display: flex;
}

.my-photocircle-notifications-container {
  flex: 3 3 0;
  margin-right: 5em;
}

.my-photocircle-favourite-photos-container {
  flex: 1 1 0;
}

.my-photocircle-no-favourite-photos-message {
  font-size: .85em;
}

.my-photocircle-notifications-notification {
  align-items: center;
  border-bottom: 1px solid $light_grey;
  display: flex;
  padding: 1em;
}

.my-photocircle-notifications-notification-icon {
  flex: 1 1 auto;
  margin-right: 1em;
  i {
    font-size: 1.5em;
  }
}

.my-photocircle-page-section-title {
  border-bottom: 1px solid $orange;
  color: $black;
  font-size: 1.5em;
  margin-bottom: 1.5em;
  padding-bottom: .5em;
}

.my-photocircle-notifications-notification-content {
  flex: 1 1 100%;
  font-size: .85em;
}

.my-photocircle-notifications-notification-content-text {
  margin: 0;
}

.my-photocircle-notifications-notification-content-date {
  color: $grey;
  margin: 0;
}

.my-photocircle-favourite-photo-link {
  display: block;
  margin-bottom: 1.5em;
}

.my-photocircle-favourite-img {
  max-width: 100%;
  width: 15em;
}

.my-photocircle-view-gallery-link {
  display: block;
  margin-top: 1em;
}

// My photocircle / favourites section

#my-photocircle-comments, #favourite-notifications {
  //	width: 800px;
  .rows-container {
    > div {
      margin: auto;
      padding: 15px 10px;
      border-bottom: 1px solid $light_grey;
      font-family: $lora;
      font-size: 13px;
      line-height: 20px;
      .left-column {
        width: 180px;
        float: left;
        margin: 3px 20px 5px 0px;
        img {
          width: 180px;
        }
      }
      .right-column {
        margin-left: 200px;
      }

    }
  }
}

.my-photocircle-table {
  font-size: .85em;
  margin: 2.5rem 0rem;
  .subrow {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #eee;
  }
  .subrow:last-child {
    border-bottom: 0px;
  }
}

.c-my-photocircle-table {
  border-bottom: 0px;
  margin-bottom: 1.5rem;
  padding: 1.5rem 0;
}

.c-my-photocircle-table__header {
  display: none;
  @include from($tablet) {
    display: flex;
    padding: 1rem 0;
    background-color: $color-middle-blue-green;
    color: $color-white;

  }
  h2 {
    font-size: 1.25em;
  }
}

.c-my-photocircle-table__row {
  padding: 3rem 0rem;
  border-bottom: 1px solid $color-middle-blue-green;
  line-height: 1.5rem;
  span.artist {
    display: none;
  }
  @include from($tablet) {
    display: flex;
  }
  @include from($tablet) {
    .subrow {
      //min-height: 21rem;
    }
  }

}

.c-my-photocircle-table__cell {
  @include until($tablet) {
    padding: 1rem;
  }
  @include from($tablet) {
    flex: 1;
    padding: 0 1rem;
  }
}

header {
  .c-my-photocircle-table__column {
    &--order {
      flex: 1.23;
    }
    &--product-complete {
      flex: 4;
    }
    &--donation {
      flex: 2;
    }
  }

}

.c-my-photocircle-table__column {
  &--photo {
    flex: 0 0 2;
    img {
      max-width: 220px;
      margin-bottom: 1.5rem;
    }
  }
  &--order {
    flex: 1;
  }
  &--product {
    flex: 2;
  }
  &--product-complete {
    flex: 5;
    img {
      max-width: 220px;
      margin: 1rem 0;
    }
    .subrow {
      padding: 1.5rem 0;
      border-bottom: 1px solid #ccc;
      &:first-child {
        padding-top: 0rem;
      }
      &:last-child {
        border-bottom: 0px;
        padding-bottom: 0rem;
      }
    }
    @include from($tablet) {
      .subrow {
        display: flex;
      }
      .photo-col {
        flex: 1;
        padding-right: 1rem;
      }
      .product-col {
        flex: 1;
      }
      .donation-col {
        flex: 1;
      }
      img {
        margin: 0;
        margin-bottom: 1rem;
      }
    }
    .project-box {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      .donation-image {
        order: 3;
      }
      .donation-name {
        order: 2;
      }
      .donation-amount {
        order: 1;
      }
      @include from($tablet) {
        margin-top: 0rem;
        .donation-image {
          order: 1;
        }
        .donation-name {
          order: 2;
        }
        .donation-amount {
          order: 3;
        }
      }
    }

  }
  &--quantity,
  &--unit-price {
    text-align: center;
    @include from($tablet) {
      .o-definition {
        justify-content: center;
      }
    }
  }
  &--donation {
    flex: 0 0 2;
    img {
      max-width: 220px;
      margin-bottom: 1.5rem;
    }
  }
  &--earnings {
    flex: 2;
    .c-trees {
      padding-top: 0rem;
    }
  }
}

.request-payment-description ul {
  font-family: $lora;
  list-style-type: disc;
  margin: 24px;
  li {
    margin: 6px 0 6px 0;
  }
}

#paypal_form,
#german_bank_form {
  margin: 24px;
  li {
    margin: 12px 0;
  }
  label {
    display: inline-block;
    width: 220px;
  }
}

.request-payment-btn {
  @include button();
  margin: 1em 0;
}

.payment-form-question {
  display: block;
  margin-bottom: 12px;
  margin-top: 12px;
}

// Sell / Edit photo section
#sell-photo {
}

.sell-photo-notes {
  margin-top: 20px;
  .right {
    margin-left: 110px;
  }
  p {
    margin: 0px;
  }
}

#backup-upload-field {
  display: none;
}

.lang_de {
  #fieldset-language-2 {
    border-top: 1px dashed $grey;
    border-bottom: 1px dashed $grey;
    padding: 15px 0px;
  }
}

#help_box {
  background: $light_grey;
  color: $orange;
  font-size: 14px;
  min-height: 100px;
  padding: 10px;
  border-bottom: 1px solid $grey;
}

#image-container {
  margin-top: 1em;
  text-align: center;
}

#main-img {
  @include shadow(1);
  display: none;
  background: $white;
  padding: .75em;
  max-width: 100%;
}

#error-message-container {
  display: none;
  color: $color-error;
  text-align: left;
}

.tagEditor li {
  background-color: $light_grey;
  font-size: 12px;
  background-image: url($assetsPath + "images/minus_small.png");
  background-position: right center;
  background-repeat: no-repeat;
  cursor: pointer;
  display: inline-block;
  list-style-type: none;
  margin: 4px 4px 4px 4px;
  padding: 3px 18px 3px 6px;
  border-radius: 3px;
}

#markup {
  border: 1px solid $grey;
  font-size: 1em;
  text-align: center;
  width: 3em;
}

#project-support-block {
  margin-top: 20px;
  padding-top: 20px;
}

#show-product-types-table-button {
  border-bottom: 1px dotted $orange;
  cursor: pointer;
  display: inline-block;
  margin-top: 3em;
  padding-bottom: 0.25em;
}

#product-types-table {
  font-size: .85em;
  margin-top: 1em;
}

#submit.transparent {
  opacity: 0.4;
}

#my_account,
#create_profile {
  .info-text {
    color: $grey;
    display: block;
    font-size: 10px;
    margin: 3px 0 0 6px;
  }

  label.error {
    display: block;
    text-align: left;
  }
  .my-account-buttons-container {
    margin-top: 1em;
    .change-password-button,
    .delete-account-button {
      cursor: pointer;
      &:hover {
        color: $orange;
      }
    }
  }
  #terms_and_conditions {
    margin-bottom: 20px;
  }
  #save_settings_button {
    width: 250px;
  }
}

.change-password-dialog,
.delete-account-dialog,
.error-dialog {
  display: none;
}

#registration_form {
  label.error {
    margin: 6px;
    text-align: left;
  }
  span.error p {
    color: $color-error;
    display: block;
    font-family: $montserrat;
    font-size: 12px;
    margin: 6px;
    text-align: left;
    width: auto;
  }
}

.error-dialog,
#delete_account,
#change_password,
#request-payment {
  background: $white;
  padding: 20px;
  p {
    margin: 10px 0px;
  }
  textarea {
    width: 540px;
    height: 60px;
    padding: 10px;
    color: $grey;
    resize: vertical;
  }
  #delete_account_confirm {
    padding: 5px;
    border: 0px;
  }
}

#delete_account {
  div.error {
    margin-top: 24px;
  }
}

#change_password {
  form {
    margin-top: 20px;
  }
  div {
    min-height: 35px;
  }
  label {
    display: inline-block;
    width: 165px;
    //text-align: right;
    padding-right: 10px;
  }

  label.error {
    display: block;
    margin-left: 185px;
    padding: 6px 0;
  }
  div.error {
    margin-top: 24px;
  }
  #change_password_confirm {

  }

}

.file_upload {
  span.error {
    margin-left: 205px;
  }
}

.banners {
  margin-top: 48px;
}

.banner {
  border: 1px solid #eeeeee;
  border-radius: 3px;
  margin-bottom: 24px;
  overflow: hidden;
  position: relative;
  width: 100%;

  & > img {
    margin: 10px;
    position: absolute;
  }
  & > textarea {
    border: 3px solid #ccc;
    box-sizing: border-box;
    float: right;
    height: 100px;
    margin: 10px;
    padding: 5px;
    resize: none;
    vertical-align: top;
    width: 400px;
  }
}

.english-profile-warning {
  color: $grey;
  font-size: 8px;
}

.my-photocircle-sell-photo-btn {
  @include button();
  margin: 1em 0;
  padding: 1em 0;
  text-align: center;
  width: 100%;
  i {
    margin-right: .5em;
  }
}

.my-photocircle-connect-with-facebook-btn {
  @include button($bgColor: $facebook_color);
  padding: 1em 0;
  text-align: center;
  width: 100%;
  i {
    margin-right: .5em;
  }
}

.sell-photo-upload-btn-wrap {
  margin: auto;
  position: relative;
  width: 248px;
  .fileupload {
    cursor: pointer;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
  &:hover {
    .upload-photo-button {
      @include shadow(3);
      transform: translateY(-2.5%);
    }
  }
}

.upload-photo-button {
  @include button();
  text-align: center;
  width: 100%;
}

.sell-photo-submit-btn {
  @include button();
  border: 0 !important;
  display: block;
  margin: 3em auto 0 auto;
}

.change-password-form-field {
  margin-bottom: 1em;
  label {
    display: block;
  }
}

.my-photocircle-profile-form-container {
  margin: auto;
  width: 70%;
}

.my-photocircle-profile-form {
  //width: 50%;
}

.my-photocircle-account-form-textarea {
  height: 10em;
  width: 100%;
}

.my-photocircle-account-form-text-field,
.my-photocircle-upload-form-text-field {
  padding: .5em;
  width: 100%;
  &:focus {
    border: 1px solid rgba($orange, 0.7);
    box-shadow: 0 0 10px $light_grey;
  }
}

.my-photocircle-product-examples-table {
  border: 1px solid $grey;
  font-size: .85em;
  margin: auto;
  width: 100%;
  th {
    text-align: left;
    background: $grey;
    border: 1px solid $grey;
    padding: 10px;
  }
  td {
    border: 1px solid $grey;
    line-height: 1.5;
    padding: 10px;
    vertical-align: top;
    width: 25%;
    &:first-child {
      width: 30%;
    }
  }
}

.my-photocircle-account-form-container {
  padding: 0 5%;
  margin-top: calc-em(58px, 16px);
}

.my-photocircle-upload-form-container {
  display: none;
  margin-top: 10em;
  padding: 2.5% 5%;
  &.edit {
    display: flex;
    margin-top: 3em;
  }
  &.is-visible {
    display: flex;
  }
}

.my-photocircle-upload-form-photo-container {
  flex: 1;
  //height: 100%;
  //&.fixed {
  //top: 150px;
  //position: fixed;
  //}
}

.my-photocircle-upload-form-photo-container-inner {
  border: 1px solid $light_grey;
  padding: 1em;
  top: 100px; // for the sticky positioning
}

.my-photocircle-upload-form {
  flex: 2;
  margin-right: 3em;
}

.my-photocircle-upload-form-section-title {
  border-bottom: 1px solid $orange;
  font-size: 1.5em;
  margin-bottom: 2em;
  padding-bottom: .5em;
}

.color-circle-selector {
  border: 2px solid $grey;
  border-radius: 30px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  margin-right: 5px;
  width: 25px;
  &.selected,
  &:hover {
    border-color: $black;
  }
  &.black-and-white {
    background: linear-gradient(135deg, #ffffff 0%, #ffffff 50%, #0a0e0a 51%, #0a0809 100%);
  }
}

#insert-multi-lang-button {
  color: $orange;
  //border-bottom: 1px dotted $orange;
  cursor: pointer;
  display: inline-block;
}

.my-photocircle-upload-form-field-help-text {
  font-size: .85em;
  line-height: 1.5;
  margin-bottom: 1em;
}

.selectize-control {
  font-family: $lora;
}

.selectize-input {
  box-shadow: none;
}

.selectize-control.multi .selectize-input [data-value] {
  background-color: transparent;
  background-image: none;
  border: 1px solid $black;
  box-shadow: none;
  color: $black;
  text-shadow: none;
  .remove {
    border-left: 1px solid $grey;
  }
}

.selectize-input.parsley-error {
  border: 1px solid $color-error !important;
}

.parsley-custom-error-message {
  font-size: 0.85em;
  color: $color-error;
  margin: .25em 0 0 .25em;
}

.my-photocircle-account-form-content,
.my-photocircle-upload-form-content {
  padding: 0 1.5em;
  p {
    font-size: .85em;
  }
}

.my-photocircle-profile-email-notifications-container {
  label {
    font-family: $lora;
  }
}

.my-photocircle-upload-product-types {
  th {
    background: $grey;
    border: 1px solid $grey;
    padding: .75em;
    text-align: left;
  }

  td {
    border: 1px solid $grey;
    line-height: 1.5;
    padding: .75em;
    vertical-align: middle;
    width: 25%;
    &:first-child {
      width: 30%;
    }
  }
}

.js-show-markup-field {
  border-bottom: 1px dotted $orange;
  cursor: pointer;
  display: inline-block;
  margin-top: 1em;
  padding-bottom: 0.25em;
}

.my-photocircle-upload-form-markup {
  display: none;
}

.mandatory-field-marker {
  color: $orange;
}

.in-my_photocircle {
  .photo-column .o-image img {
    max-width: 220px;
  }
  .product-column .o-image img {
    max-width: 220px;
  }
  .project-column .o-image img {
    max-width: 220px;
  }
}

.my-photocircle-sales-total {
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  @include until($tablet) {
    display: block;
    & > * + * {
      margin-top: 1.5rem;
    }
  }
}

.my-photocircle-sales-total-item {
  @include from($tablet) {
    & + & {
      margin-left: 3rem;
    }
  }
  .sales-number {
    font-size: 1.5em;
  }
}

.button-wrap {
  text-align: right;
}

.my-photocircle-sales-links, .portfolio-status-navigation-bar {
  margin-bottom: 2rem;
  .show-table-button, .filter-status-option {
    float: left;
    margin-right: 1rem;
    cursor: pointer;
    &.active {
      color: $orange;
      border-bottom: 2px solid $orange;
    }
  }
}
.portfolio-status-navigation-bar {
  margin-top: 6rem;
  margin-bottom: 8rem;
}

.donation-slider-wrap {
  margin-bottom: 3rem;
  #donation-slider {
    margin-bottom: 1rem;
  }
}
