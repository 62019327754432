.o-grid {
  @include from($tablet) {
    display: grid;
  }
  &.is-mobile {
    display: grid;
  }
  grid-gap: 3rem 0;
  grid-template-columns: repeat(2, 45%);
  justify-content: space-around;
}

.o-grid--3 {
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.o-grid__item {
  margin-bottom: 3rem;
  @include from($tablet) {
    margin-bottom: 0;
  }
}
