@import 'module_press';
@import 'module_feedback';
@import 'module_need_help_popup';
@import 'module_newsletter_popup';

#faqs {
  .faqs_category {
    color: $black;
    margin: 2em 0 1em 0;
  }
  .question {
    color: $black;
    cursor: pointer;
    font-family: $lora;
    font-size: 1.25em;
    margin-bottom: 1em;
    width: 100%;
    .question-number {
      color: $orange;
    }
    .question-text {
      border-bottom: 1px dotted $grey;
      &:hover {
        color: $orange;
      }
    }
  }
  .answer {
    margin-bottom: 3em;
    p {
      margin: 1em;
    }
  }
  ul,
  ol {
    font-family: $lora;
    list-style-type: disc;
    margin-left: 1em;
    li {
      margin-bottom: .5em;
    }
  }
  table {
    margin-left: 1em;
  }
  table,
  td,
  th {
    border: 1px solid $black;
    font-size: calc-em(14px, 16px);
    padding: calc-em(6px, 14px);
  }
  tr:first-child,
  th {
    background-color: $grey;
    color: $black;
  }
  td {
    font-size: calc-em(14px, 16px);
    vertical-align: middle;
  }
}

#data_protection_statement {
  text-align: left;
  input {
    float: left;
  }
  div.align_center {
    text-align: center;
  }
  ol, ul, div, li {
    font-family: $lora;
    line-height: 1.5em;
  }
  p {
    margin: 10px 0;
    line-height: 1.5em;
  }
  h1 {
    margin: 30px 0 0 0;
  }
  h2 {
    margin: 20px 0 10px 0;
  }
}

.terms_and_conditions {
  margin-bottom: 30px;
}


.legal-notice-page {
  margin: calc-em(48px, 16px) auto;
  width: 80%;
}

.terms-and-conditions-page {
  margin: calc-em(48px, 16px) auto;
  width: 80%;
  p {
    margin: 10px 0;
    line-height: 1.5em;
  }
  ol, ul, div, li {
    font-family: $lora;
    line-height: 1.5em;
  }

  ol, ul {
    list-style-type: disc;
    margin-left: calc-em(24px, 16px);
  }
  li {
    margin-bottom: calc-em(12px, 16px);
  }
  h1 {
    margin: 30px 0 0 0;
  }
  h2 {
    margin: 20px 0 10px 0;
  }
}

.data-protection-statement-page {
  margin: calc-em(48px, 16px) auto;
  width: 80%;
}

.shipment-and-delivery-page {
  margin: calc-em(48px, 16px) auto;
  width: 80%;
}

.terms-and-conditions-page {
  strong {
    font-weight: bold;
  }
}
