.o-definition-list {
}

.o-definition {
  display: flex;
  margin-bottom: .75rem;
}

.o-definition__term {
  margin-right: .75rem;
}

.o-definition__detail {

}
