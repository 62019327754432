.c-button {
  background: $color-accent;
  border: 3px solid $color-accent;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-family: $headingsFont;
  font-size: calc-em(18px, 16px);
  font-weight: 400;
  margin: 1rem 0;
  //min-width: 10em;
  padding: calc(1rem - 6px) 1.5rem;
  text-align: center;
  &:hover {
    background: $color-accent-hover;
    border-color: $color-accent-hover;
  }
  &[disabled],
  &:disabled {
    cursor: auto;
    opacity: 0.25;
  }
}

.c-button--full-width {
  width: 100%;
}

.c-button--cta {
  background: $color-cta;
  border-color: $color-cta;
  color: $color-text;
  &:hover {
    background: $color-cta-hover;
    border-color: $color-cta-hover;
  }
}
.c-button--cta.is-inactive {
  opacity: 0.3;
}

.c-button--add-to-cart {
  margin: 0;
  padding: 1rem .75rem;
  @include until($tablet) {
    padding: .5rem .75rem;
  }
}

.c-button--outline {
  background: transparent;
  border-color: $black;
  color: $color-text;
  &:hover {
    background: $color-text;
    border-color: $black;
    color: $white;
  }
}

.c-button__price {
  align-items: center;
  border: 1px solid $color-sunglow;
  display: flex;
  font-weight: 400;
  padding: 0 1.5rem;
}
.photo-page-button-wrap {
  .discount-code {
    float: right;
    padding: 0.5rem;
  }
}
@include until($desktop) {
  .photo-page-button-wrap {
    background: $color-white;
    bottom: 0;
    box-shadow: 0 -4px 12px 3px rgba(80, 80, 80, 0.2);
    left: 0;
    position: fixed;
    padding: 0.4rem;
    padding-bottom: 1rem;
    width: 100%;
    z-index: 2;
    .c-button--add-to-cart {
      padding: .75rem .75rem
    }
    .o-field:not(:last-child) {
      margin-bottom: 0.2rem;
    }
    .discount-code {
      float: none;
      display: block;
      padding: 0.2rem;
    }
  }
}
