// desktop version
.c-language-dropdown-menu {
  position: relative;
  display: inline-block;
  padding: 0 1rem;
  height: 100%;
  font-size: 0.75em;
  .flag-icon {
    //border: 1px solid white;
    margin-top: 7.5px;
    margin-bottom: 9px;
  }
  &:hover {
    color: white;
  }
}
.c-language-dropdown {
  display: none;
  position: absolute;
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  padding: 0rem 0rem 0rem 0rem;
  z-index: 1;
  right: 0px;
  //background: red;
  ul {
    background-color: #fff;
    z-index: 2;
    margin-top: 12px;
    box-shadow: 0px 16px 16px 0px rgba(0,0,0,0.2);
  }
  li {
    width: 100%;
    text-align: right;
    border-bottom: 1px solid rgb(234, 234, 234);
    a {
      display: block;
      width: 100%;
      padding: 0.5rem 0.85rem;
      border-bottom: none;
      font-size: 0.85rem;
      vertical-align: middle;
      color: black !important;
    }
    a:hover {
      background: #f3f3f3;
    }
    .flag-icon {
      border: 1px solid #eee;
      vertical-align: middle;
      margin-left: 0.25rem;
    }
    .mobile-text {
      display: none;
      @include until($tablet) {
        display: inline-block;
      }
    }
    .desktop-text {
      display: none;
      @include from($tablet) {
        display: inline-block;
      }
    }
  }
}
.c-language-dropdown-menu:hover .c-language-dropdown {
  display: block;
}



// mobile version
.choose-language-menu {
  margin-top: 1rem;
  li {
    background: white;
    a {
      color: black;
      border-bottom: 0px;
    }
    img {
      border: 1px solid #000;
      padding: 1px solid white;
    }
    display: inline-block;
    border: 1px solid white;
    padding: 0.3rem;
    margin: 0.3rem;
    border-radius: 3px;
  }
  .mobile-text {
    display: none;
    @include until($tablet) {
      display: inline-block;
    }
  }
  .desktop-text {
    display: none;
    @include from($tablet) {
      display: inline-block;
    }
  }
}



.c-language-selector {
  display: flex;

  > * + * {
    margin-left: .75rem;

    &::before {
      content: '|';
      display: inline;
      margin-right: .75rem;
    }
  }
}

.c-language-selector__item {
  display: flex;

  a {
    border: none;
    &:hover {
      color: $color-accent;
    }
  }
  &.is-selected {
    color: $color-silver;
  }
}

.c-footer {
  .c-language-selector {
    color: $color-white;
    a {
      color: $color-white;
      &:hover {
        color: $color-accent;
      }
    }
  }
}


