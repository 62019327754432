@import 'module_photographer';
@import 'module_wall_preview';
@import 'module_review';


.after-total-row {
  font-family: $lora;
  font-size: calc-em(12px, 16px);
}

#info-popup {
  //background-color: $white;
  padding: 20px 0 0 12px;
}

.gallery-row-title {
  @include headline($orange, 18px);
  font-weight: normal !important;
  color: $black;
  margin-top: 2rem;
  margin-bottom: 1rem;
  a {
    color: $orange;
    font-family: $montserrat;
    font-size: inherit;
    margin-left: calc-em(12px, 16px);
    text-decoration: none;
  }
}

.show_all {
  height: auto !important;
  overflow: visible !important;
  border: none !important;
}

.photo-page-video-slider-container {
  margin-bottom: 3em;
}

.photo-page-facts-title {
  font-size: calc-em(18px, 16px);
}

.photo-page-product-features {
  margin-top: 48px;
  .product-features-slideshow {
    //margin: 24px 0 24px 0;
    width: auto;
    z-index: 0;
    img {
    }
  }
  .product-features {
    font-family: $lora;
    font-size: calc-em(14px, 16px);
    list-style-type: disc;
    margin-top: 1.5em;
    li {
      margin: 12px 0 12px 0;
      i {
        margin-right: 6px;
      }
    }
  }

  /*
  these are just the default styles used in the Cycle2 demo pages.
  you can use these styles or any others that you wish.
   */

  /* set border-box so that percents can be used for width, padding, etc (personal preference) */
  .cycle-slideshow, .cycle-slideshow * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .cycle-slideshow {
    width: 45%;
    min-width: 200px;
    max-width: 500px; /*margin: 10px auto;*/
    padding: 0;
    position: relative;
    background: url(http://malsup.github.com/images/spinner.gif) 50% 50% no-repeat;

  }

  /* slideshow images (for most of the demos, these are the actual "slides") */
  .cycle-slideshow img {
    /*
    some of these styles will be set by the plugin (by default) but setting them here
    helps avoid flash-of-unstyled-content
     */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    display: block;
  }

  /* in case script does not load */
  .cycle-slideshow img:first-child {
    position: static;
    z-index: 100;
  }

  /* pager */
  .cycle-pager {
    text-align: center;
    width: 100%;
    z-index: 500;
    position: absolute;
    bottom: 0px;
    overflow: hidden;
  }
  .cycle-pager span {
    font-family: arial;
    font-size: 50px;
    width: 16px;
    height: 16px;
    display: inline-block;
    color: $light_grey;
    cursor: pointer;
  }
  .cycle-pager span.cycle-pager-active {
    color: $orange;
  }
  .cycle-pager > * {
    cursor: pointer;
  }

  /* caption */
  .cycle-caption {
    position: absolute;
    color: white;
    bottom: 15px;
    right: 15px;
    z-index: 700;
  }

  /* overlay */
  .cycle-overlay {
    font-family: tahoma, arial;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 600;
    background: black;
    color: white;
    padding: 15px;
    opacity: .5;
  }

  /* prev / next links */
  .cycle-prev, .cycle-next {
    position: absolute;
    top: 0;
    width: 30%;
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: 800;
    height: 100%;
    cursor: pointer;
  }
  .cycle-prev {
    left: 0;
    background: url(http://malsup.github.com/images/left.png) 50% 50% no-repeat;
  }
  .cycle-next {
    right: 0;
    background: url(http://malsup.github.com/images/right.png) 50% 50% no-repeat;
  }
  .cycle-prev:hover, .cycle-next:hover {
    opacity: .7;
    filter: alpha(opacity=70)
  }

  .disabled {
    opacity: .5;
    filter: alpha(opacity=50);
  }

  /* display paused text on top of paused slideshow */
  .cycle-paused:after {

  }
}

.photo-page-product-features-text {
  font-size: calc-em(16px, 16px);
}

.photo-content-wrap {
  @include tablet-only {
    padding: 0 10%;
  }
  @include until($desktop) {
    width: 100%;
  }
  @include from($desktop) {
    display: grid;
    gap: 5rem;
    grid-template-columns: 135px 1fr 400px;
    display: flex;
  }
}
.photo-content-wrap-inner {
  display: flex; 
  flex-wrap: wrap;
}

.photo-content-wrap.is-print-photo {
  @include tablet-only {
    padding: 0 10%;
  }
  @include until($desktop) {
    width: 100%;
  }
  @include from($desktop) {
    display: grid;
    gap: 5rem;
    grid-template-columns: 1fr minmax(300px, 400px);
  }
}

.extra-galleries-container {
  margin-top: 48px;
}

.extra-gallery-wrapper {
  margin-top: 3em;
  padding: 2.5%;
}

.photo-column {
  flex: 0 1 80%;
  padding-left: 1rem;
  @include media('<960px') {
    //flex: 0 1 80%;
    padding: 0;
  }
}

.photo-container {
  //height: 65vh;
  perspective: 1000px;
}

.photo-footer {
  margin-right: calc-em(48px, 16px);
}

.photo-footer-btn {
  cursor: pointer;
  i {
    //margin-right: calc-em(6px, 12px);
  }
  @include media('<820px') {
    font-size: calc-em(8px, 16px);
    margin-right: 1em;
  }
  .o-button-text-link {
    font-size: 1em;
    font-weight: normal;
  }
}

.photo-fullscreen-button {
  //padding: 1em 0;
  //text-align: right;
  margin-right: 1em;
  &:hover {
    color: $orange;
  }
  @include media('<400px') {
    display: none;
  }
}

.photo-buttons-container {
  font-size: 3em;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: .5em;
  @include from($desktop) {
    font-size: 1.75em;
  }
}

.photo-button-favourite {
  &.is-favourite,
  &:hover {
    color: $orange;
  }
}

.favourite {
  border: 1px solid $orange;
  color: $orange;
  &:hover {
    background: $orange;
    color: $white;
  }
  @include media('<400px') {
    display: none;
  }
}

.small-wall-preview {
  position: relative;
  .wall-image {
    // max-width: 140px;
    // max-height: 80px;
    width: 100%;
  }
  .photo-on-wall-preview {
    opacity: 0.85;
    position: absolute;
    bottom: 52%;
    box-shadow: 0px 0px 3px #777;
    &.is-horizontal {
      left: 39%;
      width: 25%;
    }

    &.is-vertical {
      height: 40%;
      left: 42%;
    }

    &.is-square {
      left: 42%;
      width: 15%;
    }
  }
  .wall-caption {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    font-size: calc-em(12px, 16px);
    padding: 6px;
    position: absolute;
    width: 100%;
    .wall-caption-text {
      color: $white;
      font-family: $montserrat;
      font-size: calc-em(12px, 16px);
      line-height: 1.5em;
      margin: 0;
    }
  }
}

.photo-page-thumbnail-wrap {
  .photo-on-wall-small {
    bottom: 55%;
    box-shadow: 1px 1px 3px $black;
    left: 50%;
    opacity: .9;
    position: absolute;
    transform: translateX(-50%);
    width: 20%;
  }
  .thumbnail-nav-play-button {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.photo-image {
  cursor: pointer;
  //border-left: 3px solid $black;
  max-height: 100%;
  max-width: 100%;
  //position: absolute;
  //transform: rotateY(10deg);
  @include shadow(3);
  &.is-vertical {
    //max-width: 100%;
    //max-width: 320px;
    //max-height: 640px;
    width: 72%;
    height: auto;
  }

  &.is-horizontal {
    //max-width: 500px;
    //max-width: 100%;
    //max-width: 640px;
    //width: 100%;
    //width: 640px;
    width: 100%;
    height: auto;
  }
  &.is-square {
    //max-width: 100%;
    //width: 480px;
    width: 80%;
    height: 80%;
  }
  @include media('<750px') {
    display: block;
  }
}
.mockup-image {
  //max-height: 100%;
  //max-width: 100%;
  width: 100%;
  height: auto;
}

.responsiveBuyBtn {
  display: none;
  width: auto;
  @include media('<750px') {
    display: inline-block;
  }
}

.profile-picture-link {
  display: inline-block;
  text-decoration: none;
}

.profile-picture {
  display: inline-block;
  filter: grayscale(100%);
  max-width: 60px;
  max-height: 60px;
  margin-right: 10px;
}

.photo-about {
  margin: 1rem 0 1.5rem 0;
  /*
  @include until($tablet) {
    margin-top: -3rem; // align with the other icons
    max-width: 85%;
  }
  */
  @include from($desktop) {
    margin: 1.5rem 0 1.5rem 0;
  }
}

.photo-info {
  display: inline-block;
  vertical-align: top;
}

.photo-title {
  font-family: $montserrat;
  font-size: 1em;
  //font-weight: bold;
}

.photo-author {
  color: $black;
  font-family: $lora;
  font-size: calc-em(12px, 16px);
  font-weight: normal;
  margin-top: 3px;
  a {
    border-bottom: 1px dotted $grey;
    color: inherit;
    text-decoration: none;
    &:hover {
      color: $orange;
    }
  }
}

.photo-location {
  color: $black;
  font-family: $lora;
  font-size: 1em;
  margin-top: calc-em(12px, 16px);
  .region {
    font-weight: normal;
    a {

      text-decoration: none;
    }
  }
}

.photo-categories,
.photo-tags {
  margin-bottom: 1rem;
  &-title {
    font-size: 1em;
  }
}

.photo-tags {
  @include media('<400px') {
    display: none;
  }
}

.category-list-element,
.tag-list-element {
  display: inline;
  margin-top: calc-em(6px, 16px);
}

.category-link,
.tag {
  color: $color-text;
  font-size: calc-em(12px, 16px);
  text-decoration: underline;
  &:hover {
    color: $color-accent;
  }
}

.photo-description-ellipsis {
  display: inline-block;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}

.photo-show-more-info-link {
  color: $orange;
  cursor: pointer;
  display: inline-block;
  font-family: $lora;
  font-size: 1em;
  margin-left: .25em;
  vertical-align: middle;
}

.limited-item {
  border-top: 1px solid #bbbbbb;
  font-family: $lora;
  margin-bottom: 12px;
  padding: 10px 0px;
  position: relative;
  input {
    display: inline-block;
  }
}

.limited-item-content {
  display: inline-block;
  vertical-align: top;
}

.limited-item-size {
  font-family: $montserrat;
  font-size: calc-em(12px, 16px);
  font-weight: bold;
  margin-bottom: 12px;
}

.limited-item-size-label {
  font-weight: bold;
  margin-bottom: 12px;
  text-transform: capitalize;
}

.limited-item-description {
  display: block;
  font-family: $lora;
  font-size: calc-em(12px, 16px);
  margin-top: 6px;
  max-width: 200px;
}

.limited-item-product-description {
  display: block;
  font-family: $lora;
  font-size: calc-em(12px, 12px);
  font-weight: normal;
  margin-top: 6px;
  max-width: 200px;
}

.limited-item-preview {
  border-bottom: 1px dotted $orange;
  color: $orange;
  display: inline-block;
  font-size: calc-em(10px, 16px);
  font-weight: normal;
  padding-bottom: 1px;
  position: absolute;
  right: 0;
  text-transform: uppercase;
}

.limited-edition-sidebar-heading {
  color: $black;
  font-family: $montserrat;
  font-size: calc-em(14px, 16px);
  font-weight: bold;
  padding: 6px 0px;
  text-transform: uppercase;
}

.limited-item-product-name {
  font-family: $montserrat;
  font-size: calc-em(12px, 16px);
  font-weight: bold;
  margin-bottom: 6px;
}

.limited-item-price {
  font-size: calc-em(12px, 16px);
  font-weight: bold;
  position: absolute;
  right: 0;
}

.total-rows-container {
  text-align: right;
  color: $black;
  font-family: $montserrat;
  margin-bottom: 10px;
}

.total-row {
  font-size: 19px;
  font-weight: bold;
}

.limited-editions-column form {
  text-align: right;
}

.limited-heading {
  background-color: $orange;
  color: $white;
  font-family: $montserrat;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 12px;
  padding: 5px;
  text-align: center;
  text-transform: uppercase;
}

.limited-price-list-download {
  display: block;
  font-family: $lora;
  margin-bottom: 12px;
  text-decoration: none;
}

.photo-page-calendars-container {
  margin: 2em 0 2em 0;
}

.photo-page-calendars-container-title {
  color: $black;
  font-family: $montserrat;
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 1em;
  text-align: center;
  text-transform: uppercase;
}

.photo-page-calendars-container-calendar-wrap {
  margin-bottom: 3em;

}

.photo-page-calendars-container-calendar-title {
  font-size: 1em;
  font-weight: 300;
  margin-top: 0.75em;
  text-align: center;
  a {
    border-bottom: 1px solid $orange;
    color: #333;
    line-height: 1.75;
    padding-bottom: 0.25em;
    text-decoration: none;
    &:hover {
      color: $orange;
    }
  }
}

.photo-page-calendars-container-img {
  @include shadow(1);
  width: 100%;
}

.photo-page-calendars-container-link {
  &:hover img {
    @include shadow(3);
    transform: translateY(-2.5%);
  }
}

.christmas-shipping-time-headline {
  color: $christmasRed;
  margin-top: 1em;
  text-align: center;
}

.christmas-shipping-time {
  background: $christmasRed;
  color: $white;
  padding: 0.5rem;
  text-align: center;
  a {
    color: $white;
    text-decoration: none;
    border-bottom: 0px;
  }
  a:hover {
    text-decoration: none;
    border-bottom: 0px;
  }
}

.photo-page-product-options-container {
  &.is-hidden {
    display: none;
  }
}

.product-options-container {
  display: flex;
  margin-bottom: 1em;
  &.is-hidden {
    display: none;
  }
}

.photo-page-product-extra-options-container {
  align-items: center;
  display: flex;
  margin-bottom: 1em;

  &.is-hidden {
    display: none;
  }
}

.product-extra-options-label {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  margin-right: 1em;
}

.selected-extra-option-text,
.selected-wooden-frame-color,
.product-passepartout-options-selected-option-name {
  color: $orange;
  font-size: .75em;
}

.product-extra-options-label-name {
  display: block;
  font-size: .85em;
}

.product-extra-options-selected-option-name {
  color: $grey;
  font-size: .75em;
}


.big-preview-wrapper {
  //display: none;
  //height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  //visibility: hidden;
  .photo-on-wall-wrapper {
    //height: 30%;
    //width: 50%;
  }
  .photo-on-wall-container {
    width: 57%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
  .photo-on-wall {
    bottom: 43%;
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;

    box-shadow: -4px 3px 4px 0px rgba(100,100,100,0.9);
    box-sizing: content-box;
    //border-image: url('/public/images/product_types/frame_colors/templates/wooden_frame.jpg') 93 92 87 92 stretch stretch;
    height: auto;
    //object-fit: contain;
    width: 9%;
    &.with-white-border {
      border: 6px solid $white;
    }
    &.with-wooden-frame {
      background-color: #000;
      border-image-outset: 1px; // to avoid a white border outside
      border-image-repeat: round; // not sure
      border-image-slice: 8;
      border-image-width: 6px; // size of the frame
      border-style: inset;
      border-width: 1px; // size of passepartout combined with padding
      padding: 6px;
      //border-style: inset;
      &.color-no-frame {
      }
      &.color-black {
        border-image-source: url($productImagePath + 'wooden-frame-black.svg');
      }
      &.color-white {
        border-image-source: url($productImagePath + 'wooden-frame-white.svg');
      }
      &.color-black-oak {
        border-image-source: url($productImagePath + 'wooden-frame-black-oak.svg');
      }
      &.color-brown-walnut {
        border-image-source: url($productImagePath + 'wooden-frame-brown-walnut.svg');
      }
      &.color-brown-oak {
        border-image-source: url($productImagePath + 'wooden-frame-brown-oak.svg');
      }
    }
    &.with-passepartout-frame {
      border-image-outset: 1px; // to avoid a white border outside
      border-image-repeat: round; // not sure
      border-image-slice: 8;
      border-image-width: 6px; // size of the frame
      border-style: inset;
      border-width: 8px; // size of passepartout combined with padding
      padding: 6px;
      //border-style: inset;
      &.passepartout-frame-black {
        border-image-source: url($productImagePath + 'passepartout-frame-black.svg');
      }
      &.passepartout-frame-white {
        border-image-source: url($productImagePath + 'passepartout-frame-white.svg');
      }
      &.passepartout-frame-brown-oak {
        border-image-source: url($productImagePath + 'passepartout-frame-brown-oak.svg');
      }

    }
  }
}

.product-wooden-frame-option-label {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  margin-right: 1em;
  @include until($desktop) {
    margin-bottom: .5rem;
  }
}

.wooden-frame-option-label-text {
  display: block;
  font-size: .85em;
  font-weight: 400;
}

.product-passepartout-options-label {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-right: 1em;
}

.product-passepartout-options-label-name {
  font-size: .85em;
  font-weight: 400;
}

.big-preview {
  //height: 65vh;
  //max-height: 100%;
  //max-width: 100%;
  width: 100%;
  height: auto;
}

.select-product-frame-label {
  margin-right: .5em;
}

#passepartout-options-container {
  &.is-hidden {
    display: none;
  }
}

.select-passepartout-radio {
  position: absolute;
  visibility: hidden;
  &:checked {
    & ~ .passepartout-color-preview {
      border: 2px solid $orange;
    }
  }
}

.select-product-frame-radio {
  position: absolute;
  visibility: hidden;
  &:checked {
    & ~ .frame-color-preview {
      border: 2px solid $orange;
    }
    & ~ .product-name {
      color: $orange;
    }
  }
}

.select-product-size {
  font-size: 1.5em;
  width: 100%;
  color: black;
  option {
    color: black;
  }
  @include from($tablet) {
    font-size: 1em;
    width: 50%;
  }
}

.select-frame-color-wrapper {
  @include from($desktop) {
    display: flex;
  }
  &.is-hidden {
    display: none;
  }
}

.passepartout-color-preview {
  background-size: 1.5em;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border: 2px solid $grey;
  border-radius: 100%;
  display: inline-block;
  height: $optionRadioButtonSize;
  margin-right: .5em;
  padding: 3px;
  width: $optionRadioButtonSize;
  &-img {
    width: 100%;
  }
  &.black {
    //background-color: #000;
  }
  &.white {
    //background-color: #fff;
  }
  &.oak_tree {
    //background-image: url($productImagePath + 'frame-brown-oak.jpg');
  }
}

.passepartout-options {
  align-items: center;
  display: flex;
}

.product-extra-option-preview {
  background-size: 1.5em;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  padding: 3px;
  &-img {
    width: 100%;
  }
  &.lamination-matte {
    // background-image: url($productImagePath + 'matte.png');
  }
  &.lamination-glossy {
    // background-image: url($productImagePath + 'glossy.png');
  }
}

.product-option-radio {
  position: absolute;
  visibility: hidden;

  &:checked {
    & ~ .product-option-preview {
      border: 2px solid $orange;
    }
    & ~ .product-option-name {
      color: $orange;
    }
  }
}

.product-extra-option-radio {
  position: absolute;
  visibility: hidden;

  &:checked {
    & ~ .product-extra-option-preview {
      border: 2px solid $orange;
    }
    & ~ .product-extra-option-name {
      color: $orange;
    }
  }
}

.product-extra-option-label {
  margin-right: .5em;
}

.option-label-text {
  font-weight: 400;
}

.selected-option-text {
  color: $orange;
  font-size: .75em;
}

.extra-option-label-text {
  font-size: .85em;
  font-weight: 400;
}

.select-product-option-label {
  margin-right: .5em;
}

.product-option-preview {
  background-size: 1.5em;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border: 2px solid $grey;
  border-radius: 100%;
  display: inline-block;
  padding: 3px;
  height: $optionRadioButtonSize;
  width: $optionRadioButtonSize;
  &-img {
    width: 100%;
  }
}

.product-extra-option-preview {
  background-size: 1.5em;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border: 2px solid $grey;
  border-radius: 100%;
  display: inline-block;
  height: $optionRadioButtonSize;
  width: $optionRadioButtonSize;
  &.white-border {
    background-image: url($productImagePath + 'moab_paper.jpg');
  }
  &.no-border {
    background-image: url($productImagePath + 'poster.jpg');
  }
}

.product-extra-option-name {
  &.white-border,
  &.no-border {
    display: none;
  }
}

.photo-preview-slider {
  position: relative;
}

.slider-element {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: left;
  top: 0;
  visibility: hidden;
  width: 100%;
  &.active {
    opacity: 1;
    position: static;
    visibility: visible;
  }
  @include from($desktop) {
    text-align: center;
  }
}

.tooltip-contents {
  display: none;
}



.other-gallery {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .image_container {
    align-items: center;
    background: transparent;
    display: flex;
    flex: 0 0 15%;
    margin: 2%;
    text-align: center;
    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }
}

.photo-fullscreen-container {
  display: none;
}

.photo-fullscreen {
  width: 100%;
  height: auto;
}

@include media('<959px') {
  .photo-on-wall-small {
    height: 20%;
  }

  .profile-picture,
  .photo-location,
  .photo-footer-categories-wrap,
  .photo-tags {
    display: none;
  }

  .photo-fullscreen-popup {
    .featherlight-content {
      width: 80%;
    }
  }

}

.c-vat-and-delivery-text {
  color: $grey;
  font-size: .75em;
  a {
    color: inherit;
    &:hover {
      color: $black;
    }
  }
}

.photo-extra-info-container {
  padding-top: 1.5rem;
  @include until($desktop) {
    display: none;
  }
  &.slide-down,
  &.slide-up {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.5s ease-in-out;
  }
  &.slide-down {
    /* kind of an hack to allow the slide down animation
    in vanilla js. Would be good to find a proper solution
     */
    max-height: 99rem;
  }
}
.calendar-page-description {
  ul {
    list-style: disc;
    padding: 1.5rem;
    li {
      font-family: "Muli";
    }
  }
}