.c-order-summary__item {
  margin-bottom: 1.5rem;
  @include from($tablet) {
    display: flex;
  }
}

.c-order-summary__picture {
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  max-width: 10rem;
  @include from($tablet) {
    margin-bottom: 0;
  }
  .giftcard-small-preview {
    padding: 0;
  }
  .o-image {
    img {
      margin-bottom: 1rem;
    }
    img.has-frame-1 {
      padding: 0px;
      border-image-outset: 1px; // to avoid a white border outside
      border-image-repeat: round; // not sure
      border-image-slice: 8;
      border-image-width: 8px; // size of the frame
      border-style: inset;
      border-width: 8px;
      background: black;
    }
    img.has-frame-1.product-type-id-7 {
      padding: 20px;
      border-image-width: 8px; // size of the frame
      border-width: 1px; // size of passepartout combined with padding
      background: white;

    }
    img.option-id-11, img.frame-color-2 {
      border-image-source: url($productImagePath + 'wooden-frame-white.svg');
      box-shadow: 0px 0px 3px #777;

    }
    img.option-id-12, img.frame-color-1 {
      border-image-source: url($productImagePath + 'wooden-frame-black.svg');
    }
    img.option-id-13, img.frame-color-4 {
      border-image-source: url($productImagePath + 'wooden-frame-brown-oak.svg');
    }
  }
}

.c-order-summary__feature-label {
  font-weight: 700;
}

