$headingsFont: 'Montserrat';
$bodyFont: 'Muli';
$fontDir: '../fonts/';

$montserrat: $headingsFont;
$lora: $bodyFont;

/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Montserrat Light'), local('Montserrat-Light'),
  url($fontDir + 'montserrat-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url($fontDir + 'montserrat-v12-latin-300.woff') format('woff'), /* Modern Browsers */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url($fontDir + 'montserrat-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url($fontDir + 'montserrat-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
  url($fontDir + 'montserrat-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url($fontDir + 'montserrat-v12-latin-700.woff') format('woff'), /* Modern Browsers */
}

/* muli-300 - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Muli Light'), local('Muli-Light'),
  url($fontDir + 'muli-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url($fontDir + 'muli-v11-latin-300.woff') format('woff'), /* Modern Browsers */
}


