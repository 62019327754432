$lucida_grande: 'Lucida Grande', tahoma, verdana, arial, sans-serif;
$helvetica_neue: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font_awesome: "FontAwesome";

@mixin text-rendering($legibility:optimizeLegibility) {
  text-rendering: $legibility;
}

@mixin font-smoothing($style:antialiased) {
  font-smoothing: $style;
}

@mixin logo($size:24px) {
  font-family: $montserrat;
  font-size: calc-em($size, 16px);
  font-weight: bold;
  text-transform: uppercase;
}

@mixin headline($color, $size) {
  color: $color;
  font-family: $montserrat;
  font-size: calc-em($size, 16px);
  font-weight: 300;
}

@mixin paragraph($size:16px) {
  color: $black;
  font-family: $lora;
  font-weight: normal;
  font-size: calc-em($size, $size);
  margin: calc-em(18px, $size) 0;
  line-height: 1.5;
}

@mixin link($color:$orange,$text_decoration: none) {
  color: $color;
  text-decoration: $text_decoration;
}

.ui-dialog-content, .ui-widget-content {
  font-family: $montserrat;
}

.facebook_text {
  font-family: $lucida_grande;
  font-size: 11px;
}

.blue_facebook_text {
  font-family: $lucida_grande;
  color: $facebook_color;
  font-size: 11px;
}

.facebook-link:hover .icon-circle {
  color: $facebook_color !important;
}

.twitter-link:hover .icon-circle {
  color: $twitter_color !important;
}

.google-plus-link:hover .icon-circle {
  color: $google_plus_color !important;
}

.youtube-link:hover .icon-circle {
  color: $youtube_color !important;
}

.small {
  font-size: 12px !important;
}

.normal {
  font-size: 14px !important;
}

.big {
  font-size: 21px !important;
}

.very-big {
  font-size: 28px !important;
}

.huge {
  font-size: 48px !important;
}

.subsection-title {
  font-size: calc-em(22px, 16px);
}

.subsection-subtitle {
  font-size: calc-em(18px, 16px);
}

