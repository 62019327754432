[hidden] {
  display: none;
}

.c-menu__logo {
  height: 2rem;
  margin: auto;
  max-height: 100%;
  max-width: 12rem;
  min-width: 5rem;
  @include until($tablet) {
    padding: 0 1.5rem;
  }

  a {
    display: block;
    height: 100%;
  }
}

.c-nav__cart {
  position: relative;
  @include from($desktop) {
    padding-right: 0.25rem;
  }
}

.c-nav__cart-total, .c-nav__wishlist-total {
  --position: -0.25rem;
  --size: 1rem;
  align-items: center;
  background: $color-fountain-blue;
  border-radius: 100%;
  bottom: var(--position);
  color: $white;
  display: flex;
  font-size: 8px;
  height: var(--size);
  justify-content: center;
  right: var(--position);
  position: absolute;
  width: var(--size);
}
.c-nav__wishlist-total {
  right: 0.1rem;
  bottom: 1rem;
}

.c-mobile-nav {
  background: #000;
  display: flex;
  height: 3rem;
  padding: 0.75rem 1.5rem;
  @include from($tablet) {
    display: none;
  }

  .mobile-hidden {
    @include until($desktop) {
      display: none;
    }
  }
}

.c-mobile-main-nav {
  background: #000;
  color: $color-white;
  height: calc(100%);
  //margin-top: 3rem;
  padding: 1.5rem;
  left: 0;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  transition: transform 0.2s ease-in-out;
  width: 80%;
  z-index: 1;

}

.c-mobile-nav__item {
  display: flex;

  > * + .c-nav__icon {
    margin-left: .75rem;
  }
}

.c-mobile-nav__sub {
  margin-left: 1.5rem;
}

.c-mobile-nav__link {
  color: $color-white;
  display: inline-block;
  padding: .75rem 0;
}

.c-nav__icon {
  align-items: center;
  background: none;
  color: $color-white;
  display: flex;
  font-size: 1.5em;
  height: 100%;
  padding: 0;

  svg {
    height: 100%;
    max-width: 2rem;
    overflow: hidden;
    pointer-events: none;
    vertical-align: -0.125em;
    width: 100%;
  }
}


.c-nav__toggle-content.search-bar {
  background: #000;
  left: 0;
  padding: 1.5rem;
  position: absolute;
  top: 100%;
  //transform: translateY(-200%);
  //transition: transform 0.3s ease-in-out;
  width: 100%;
  z-index: -1;
  display: none;
}

.c-nav__toggle[aria-expanded="true"] {
  & + .c-nav__toggle-content {
    transform: translateX(0);
    display: block;
  }

  & + .c-nav__toggle-content.search-bar {
    //transform: translateY(0);
    display: block;
  }
}

.nav-dropdown-mobile-headline {
  padding: 0.75rem 0;
  i.fa {
    position: absolute;
    right: 2rem;
  }
}
.nav-dropdown-mobile-content {
  padding-left: 0.75rem;
  li {
    padding: 0.75rem;
  }
}
.c-menu__toggle {
  z-index: 100;
}

li.top-border {
  border-top: 1px solid rgb(75, 75, 75);
}

.cross {
  display: none;
}

[aria-expanded="true"] .cross {
  display: block;
}

[aria-expanded="true"] .burger {
  display: none;
}

@supports (d: path("")) {
  [aria-expanded="true"] .cross {
    display: none;
  }

  [aria-expanded="true"] .burger {
    display: block;
  }

  [aria-expanded] path {
    transition: d 0.75s;
  }

  [aria-expanded="true"] path {
    d: path("M1,1 9,9 M5,5 5,5 M1,9 9,1");
  }
}

.c-nav {
  display: block;
}

.c-menu {
  display: none;
  align-items: center;
  background-color: #000;
  color: $white;
  //display: flex;
  font-size: 1.5em;
  justify-content: space-around;
  padding: 0.75rem 0.75rem;
  @include from($tablet) {
    display: block;
    font-size: 1em;
  }
  @include from($desktop) {
    display: flex;
    > * + * {
      margin-left: 1.5rem;
    }
  }
}

.c-menu__toggle[aria-expanded="true"] + .c-mobile-main-nav {
  transform: translateX(0);
  z-index: 2;
}


.c-mobile-menu {
  color: $white;
  //display: flex;
  display: none;
  flex: 1;
  justify-content: center;
  @include from($tablet) {
    flex: 0 0 auto;
    justify-content: flex-end;
    padding-left: 1.5rem;
  }
  @include from($desktop) {
    display: none;
  }
}

.c-menu__item {
  align-items: center;
  display: flex;
  height: 100%;
}

.c-menu__left {
  padding: 1rem 0 1.5rem 0;

  > * + * {
    margin-left: 1.5rem;
  }

  @include from($tablet) {
    padding: 0;
  }
  @include from($desktop) {
    //flex: 1;
    flex-grow: 15;
  }
}

.c-menu__right {
  flex-direction: column;
  font-size: 0.75em;
  @include from($tablet) {
    flex-direction: row;
    font-size: 1em;
    flex-grow: 5;
  }

  > * + * {
    margin-left: 1.5rem;
  }
}

.c-nav {
  //display: none;
  font-size: 0.9em;
  justify-content: space-between;
  @include from($tablet) {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
  @include from($desktop) {
    flex: 1;
    justify-content: space-between;
  }
}

.c-nav__item {
  flex: 1;
  text-align: center;
  /*
  @include tablet-only {
    padding-left: 1rem;
    &:not(:last-child) {
      padding-right: 1rem;
    }
  }
  */
}
.c-nav__item--acrylic_glass {
  flex: 1.2;
}
.c-nav__item--photolab {
  position: relative;
}

.c-nav__link {
  color: inherit;
  display: block;
  padding: 0.75rem 0;
  strong {
    font-weight: normal;
  }
  &.active,
  &:hover {
    strong {
      border-bottom: 3px solid currentColor;
    }
  }
}

.c-menu__search {
  align-items: center;
  color: $white;
  font-size: 1.5em;
  height: 100%;
  width: 100%;
  form {
    width: 100%;
  }
  @include from($tablet) {
    //flex: 1;
    width: auto;
    flex-grow: 4;
  }

  .search-wrap {
    border: 1px solid $white;
    padding: 0.25em 0;
    position: relative;
  }

  button {
    background: none;
    border: 0;
    box-shadow: none;
    color: $white;
    height: 1em;
    padding: 0;
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
    width: 1em;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  input {
    background: transparent;
    border: none;
    color: $white;
    font-size: 0.75em;
    padding: 0 2.5em 0 0.5em;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  @include from($desktop) {
    display: flex;
    font-size: 1em;
  }
}

.c-menu-icons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  //flex: 0 0 auto;
  flex-grow: 10;
  order: -1;

  a {
    display: block;
  }

  @include from($tablet) {
    order: 0;
  }
}

.c-menu-icons__item {
  color: $black;

  a {
    color: $black;
  }

  &:hover {
    color: $color-fountain-blue;

    a {
      color: $color-fountain-blue;
    }
  }

  &:not(:first-child) {
    padding-left: 0.75rem;
  }

  @include until($desktop) {
    &:not(:last-child) {
      padding-right: 0.75rem;
    }
  }
  text-align: center;
}

.c-menu-icons__label {
  margin-top: 0.25rem;
}

.c-nav-submenu {
  background: #000;
  display: none;
  position: absolute;
  text-align: left;
  top: 100%;
  z-index: 99;
  width: 20rem;
}

.c-nav-submenu__link {
  color: $white;
  display: block;
  font-size: 0.75em;
  letter-spacing: 0.1em;
  padding: 1em;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    background: $white;
    color: #000;
  }
}

.c-nav__item--photolab:hover .c-nav-submenu {
  display: block;
}

.sub-menu-mobile {
  font-size: 0.75em;
  padding: 0.5rem 1.5rem;

  li {
    // padding: 1em 0;
  }

  a {
    color: $white;
  }

  @include tablet-only {
    align-items: center;
    display: flex;
  }
  @include from($desktop) {
    display: none;
  }
}

.menu-icon-mobile {
  &:not(:first-child) {
    padding-left: 1.5rem;
  }

  &:not(:last-child) {
    padding-right: 1.5rem;
  }

  text-align: center;

  a {
    color: $white;
  }

  @include from($desktop) {
    display: none;
  }
}

.icon-wrap {
  height: 1.5em;
}

.icon {
  fill: currentColor;
  height: 1.5em;
  stroke: currentColor;
  stroke-width: 0;
  width: 1.5em;
}

.c-menu-icons__label {
  font-size: 0.5em;
  @include from($tablet) {
    font-size: 0.75em;
  }
}

.main-menu-mobile {
  align-self: flex-start;
  padding: 1.5rem;

  &.closed {
    display: none;
  }

  &.open {
    display: block;
  }

  li {
    padding: 1rem 0;
  }

  a {
    color: $white;
  }
}

/*
.search-bar-mobile {
  .search-wrap {
    position: relative;
  }
  input {
    color: $white;
    background: #000;
    width: 100%;
  }
  @include tablet-only {
    flex: 1;
    padding-right: 1.5rem;
    /* TODO: this needs to be fixed */
/*
    display: block !important;
    order: -1;
    &.in-gallery {
      display: none !important;
    }
  }
}
*/

.menu-icon-mobile__search {
  @include tablet-only {
    display: none;
  }
}

.top-strip {
  background: #f3f3f3;
  color: $black;
  font-size: 1em;
  padding: 0.25rem 1.5rem;
  text-align: center;

  .top-strip-text {
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      width: 28px;
      margin: 0 0.5rem;
      margin-right: 1rem;
      vertical-align: middle;
    }
    .icon {
      margin-right: 1rem;
    }
    i.fa {
      font-size: 1.8rem !important;
      color: $color-fountain-blue;
    }
    a {
      color: $white;
    }
    strong {
      background: $color-pastel-red;
      font-weight: bold;
      color: white;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
    }
  }

  .mobile-hidden, &.mobile-hidden {
    @include until($desktop) {
      display: none;
    }
  }

  @include from($desktop) {
    //padding: 1.5rem;
  }

  .text {
    display: none;
    @include from($tablet) {
      display: block;
    }
  }

  .mobile-text {
    display: none;
    @include until($tablet) {
      display: block;
    }
  }
}

.tooltip-content {
  display: none;
}

.user-account-drop {
}

.c-user-account-links {
  padding: 0.5em;
}

.c-user-account-links__item:not(:last-child) {
  margin-bottom: 1em;
}

.c-user-account-links__link {
  color: $white;

  &:hover {
    text-decoration: underline;
  }
}

.mobile-menu-account {
  align-items: center;
  border-top: 1px solid $white;
  display: flex;
  font-size: 0.75em;

  .icon {
    fill: $white;
    height: 1em;
    margin-right: 1em;
    width: 1em;
  }

  .sub-menu-account-link-separator {
    color: $white;
    margin: 0 0.5em;
  }
}

.sub-menu-account-links {
  //border-top: 1px solid $color-white;
}

.c-menu-icons__burger {
  @include from($tablet) {
    display: none;
  }
}

.c-menu-icons__search {
  @include from($tablet) {
    display: none;
  }
}

.c-menu-icons__wishlist {
  @include from($desktop) {
    padding-left: 0 !important;
  }
  position: relative;
}

.c-menu-icons__account {
  display: none;
  @include from($tablet) {
    display: block;
  }
}




.c-nav-dropdown {
  display: none;
  background: white; 
  padding: 1rem 0rem 2rem 0rem; 
  position: absolute; 
  left: 0px; 
  right: 0px;
  color: black;
  text-align: left;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  //margin-top: 1rem;
  .columns {
    display: flex;
    .column {
      width: 33%;
    }
    ul {
      padding-top: 0.5rem;
    }
    li {
      line-height: 1.5rem;
      font-size: 0.9rem;
    }
    a {
      text-decoration: none;
      border-bottom: 0px;
    }
    a:hover {
      color: $orange;
    }
  }
}
.c-nav-dropdown-calendars {
  a {
    font-size: 0.8rem;
  }

}