#thank-you-for-feedback-popup {
    #feedback_email {
        width: 200px;
        margin-bottom: 10px;
        padding: 5px;
    }
    textarea {
        width: 340px;
        height: 165px;
        padding: 5px;
        resize: vertical;
    }
    #send-feedback-button-container {
        text-align: right;
        input {
            width: 250px;
        }
    }
}
