.c-image-thumb {
  flex: 0 0 30%;
  margin-bottom: 1rem;
  width: 100%;
  &--4 {
    flex: 0 0 calc(25% - 3rem);
    margin: 0 1.5rem;
    max-width: calc(25% - 3rem);
  }
}

@supports (display: grid) {
  .c-image-thumb {
    margin: auto;
    max-width: none;
  }
}

.c-image-thumb {
  background: $color-alabaster;
  text-align: center;
}

.c-image-thumb__link {
  // this fixes a bug in IE11
  // probably flexbug 6: https://github.com/philipwalton/flexbugs#6-the-default-flex-value-has-changed
  align-items: center;
  display: flex;
  flex: 1 1 0%;
  height: 100%;
  justify-content: center;
  .o-image {
    max-height: 100%;
  }
}

.c-image-thumb__img {
  display: block;
  height: auto;
  margin: auto;
  max-height: 19rem;
  max-width: 100%;
  padding: 1rem;
  @include from($desktop) {
    max-height: 15rem;
  }
  &.horizontal {

  }
  &.vertical {
    //height: 100% !important;
    width: auto;
  }
}

.c-image-thumb__footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: .75em;
  margin-top: 1rem;
  padding: 1rem;
  @include from($desktop) {
    font-size: .75em;
  }
}

.c-image-thumb__text {
  max-width: 65px;
  text-align: left;
  width: 100%;
  @include from($tablet) {
    max-width: 200px;
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.c-image-thumb__artist-link,
.c-image-thumb__title-link {
  color: $black;
  &:hover {
    color: $orange;
  }
}

.c-image-thumb__title-link {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.c-image-thumb__artist {
  @include media('<600px') {
    display: none;
  }
}

.c-image-thumb__title {
  font-size: 1.25em;
  font-weight: 400;
}

.c-image-thumb__price {

}

.c-image-thumb__artist {

}

.c-image-grid {
  display: flex;
  flex-wrap: wrap;
}

@supports (display: grid) {
  .c-image-grid {

    display: grid;
    grid-gap: 1.5em;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    @include media('<780px') {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

.c-fav-button {
  cursor: pointer;
}

.favourited-true,
.favourited-1 {
  color: $orange;
}

.wishlist-empty-text {
  font-size: 1.5em;
  margin: 0 0 3em 0;
}
