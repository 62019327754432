/* Copied from bulma
* https://github.com/jgthms/bulma/blob/master/sass/elements/container.sass
*
* The modifiers is-widescreen and is-fullhd allow to have a full width container
* until these breakpoints. So, with is-widescreen, it will be full width for the desktop
*/
.o-container {
  margin: 0 auto;
  position: relative;
  @include from($desktop) {
    max-width: $desktop - (2 * $gap);
    width: $desktop - (2 * $gap);
    &.is-fluid {
      margin-left: $gap;
      margin-right: $gap;
      max-width: none;
      width: auto;
    }
  }
  &.is-spaced {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }
  &.is-fluid-mobile {
    max-width: $desktop - (2 * $gap);
    width: 100%;
    @include until($desktop) {
      margin-left: $gap;
      margin-right: $gap;
      width: auto;
    }
  }
  @include until($widescreen) {
    &.is-widescreen {
      max-width: $widescreen - (2 * $gap);
      width: auto;
    }
  }
  @include until($fullhd) {
    &.is-fullhd {
      max-width: $fullhd - (2 * $gap);
      width: auto;
    }
  }
  @include from($widescreen) {
    max-width: $widescreen - (2 * $gap);
    width: $widescreen - (2 * $gap);
  }
  @include from($fullhd) {
    max-width: $fullhd - (2 * $gap);
    width: $fullhd - (2 * $gap);
  }
  /* Don't grow behind the $device breakpoint */
  &.is-tablet-only {
    max-width: $tablet;
    width: auto;
  }
  &.is-desktop-only {
    max-width: $desktop - (2 * $gap);
    width: auto;
  }
  &.is-widescreen-only {
    max-width: $widescreen - (2 * $gap);
    width: auto;
  }
  &.is-gapless {
    @include until($desktop) {
    }
  }
}

.o-container--navbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.o-container-narrow--tablet {
  width: auto;
  @include until($desktop) {
    width: 75%;
  }
}
