.partner--free-documentary {
    header {
      display: none;
    }
    .breadcrumb {
      display: none;
    }
    .partner-fix-div-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      text-align: left;
      @include until($tablet) {
        flex-direction: column;
        flex-wrap: wrap;
        text-align: center;
      }
    }
    .partner-profile-image {
      padding: 1rem;
    }
  }