.c-product-grid-select {
  position: relative;
  z-index: 0;
  &.is-grid {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
  }
}

.c-product-grid__item {
  position: relative;
}

.c-product-grid__item-wrap {
  cursor: pointer;
  display: block;
  position: relative;
  &:hover {
    .c-product-grid__img {
      filter: saturate(80%);
      transition: filter .4s ease-in-out;
    }
    .product-name {
      color: $color-bittersweet;
    }
  }
}

.c-product-grid__img-wrap {
  display: block;
  height: 80px;
  margin-bottom: .35rem;
  .c-product-grid__img {
    filter: saturate(30%);
    height: 100%;
    object-fit: cover;
    object-position: bottom;
    width: 100%;
  }
}

.c-reel {
  .c-product-grid__img-wrap {
    height: 100%;
  }
}

@media (min-width: 580px) {
  .photo-page-product-types-grid .c-reel {
    overflow: auto;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 1rem;
    row-gap: 1rem;
    padding-bottom: 0;
    & > * + * {
      margin: 0;
    }
    & > * {
      width: 30%;
      flex: none;
  
    }
  }
}


.c-product-grid__text {
  display: block;
  font-size: 0.8rem;
}

.product-type-info-icon {
  background: none;
  height: 1.25rem;
  left: .5rem;
  padding: 0;
  position: absolute;
  top: .5rem;
  width: 1.25rem;
  z-index: 1;
  svg {
    height: 100%;
    pointer-events: none;
    width: 100%;
  }
  &:hover {
    cursor: pointer;
    use {
      --color: #{$color-bittersweet};
    }
  }
}

.select-product-type-radio {
  position: absolute;
  visibility: hidden;
  & ~ .c-product-grid__img-wrap {
    border: 2px solid white;
  }
  &:checked {
    & ~ .c-product-grid__img-wrap {
      border: 2px solid $orange;
      .c-product-grid__img {
        filter: saturate(80%);
      }
    }
    & ~ .c-product-grid__text .product-name {
      color: $orange;
    }
  }
}

.product-name-label {

}

.product-options-label {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-right: 1em;
}
