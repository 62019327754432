.c-magazine {
  max-width: 50rem;
}

.o-hero.in-magazine {
  h1, h2 {
    color: black !important;
  }
}
.in-magazine {
  .o-hero__body {
    //background: $color-fountain-blue url($assetsPath + 'images/bg-pattern.png');
  }
  
  .o-new-hero__picture {
      //background: $color-fountain-blue url($assetsPath + 'images/bg-pattern.png');
  }
}

.c-post {
  padding: 3rem 0;
}

.c-post.in-list + .c-post {
  border-top: 1px solid $color-dim-gray;
}

.c-post__header {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 300px;
}


.c-post__img {
  grid-column: 1 / -1;
  grid-row: 1;
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  &.no-image {
    background-color: rgba($color-fountain-blue, .75);
    background-image: repeating-linear-gradient(45deg, transparent, transparent
    35px, rgba($color-fountain-blue,.5) 35px, rgba($color-fountain-blue,.5) 70px);
  }
}

.c-post__title {
  //align-items: center;
  grid-column: 2 / 3;
  grid-row: 1;
  display: flex;
  font-weight: 700;
  justify-content: center;
  line-height: 1.75;
  //padding: 0 6rem;
  //text-align: center;
  //width: 100%;
  &.no-image {
    background: none !important;
  }
}

.c-post__title a {
  border: 0;
  color: $color-white;
}

.c-post__inner {
  padding: 1.5rem 3.5rem;
}

.c-post__content {
  //padding: 60ch;
  h4 {
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.5;
  }
  & > p:first-child::first-letter {
    float: left;
    font-size: 5em;
    line-height: .75;
    padding: .75rem 1rem 0 0;
  }
  & > * + * {
    margin-top: 3rem;
  }
  p  {
    & + p,
    & + ul,
    & + ol {
      margin-top: 1.5rem;
    }
  }

  figcaption {
    color: $color-dim-gray;
    font-size: .75em;
    text-align: left;
  }
  em {
    font-style: italic;
  }
  strong {
    font-weight: 700;
  }
  ol,
  ul {
    list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
  }
}

.c-post__footer {
  display: flex;
  justify-content: space-between;
}

.wp-caption {
  margin-left: auto;
  margin-right: auto;
  width: auto !important;

  a {
    border: 0;
    text-decoration: none;
  }

  + .wp-caption {
    margin-top: 3rem;
  }
}

.wp-caption-text {
  margin-top: .75rem;
  text-align: center;
}

.c-post__content {
  @include body-text();
  a[href$=jpg], a[href$=jpeg], a[href$=jpe], a[href$=png], a[href$=gif] {
    text-decoration: none;
    border: 0 none;
  }

  img {
    display: block;
    height: auto;
    margin: auto;
    max-width: 100%;
  }
}

.c-magazine__translation-link {
  align-self: flex-start;
  display: inline-block;
}

.wp-block-quote,
.wp-block-pullquote {
  border-color: $color-silver;
  border-style: solid;
  border-width: 1px 0; 
}

.wp-block-pullquote {
  padding: 1.5rem;
  p {
    font-size: 1.25em;
  }
}
.wp-block-quote {
  position: relative;
  padding: 1.5rem .75rem;
  cite {
    display: block;
    text-align: right;
  }
  cite::before {
    content: '- ';
  }
}


.wp-block-quote::before {
  background: $white;
  color: $color-dim-gray;
  content: '\201C';
  //font: 6em/1.08em 'PT Sans', sans-serif;
  font-size: 6em;
  height: 2rem;
  left: 50%;
  line-height: .8;
  position: absolute;
  text-align: center;
  top: 0em;
  transform: translate(-50%, -50%);
  width: 3rem;
}

 