// Responsiveness
// from Bulma
// https://github.com/jgthms/bulma/blob/master/sass/utilities/initial-variables.sass#L46,L57
// The container horizontal gap, which acts as the offset for breakpoints
$gap: 64px !default;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px !default;
// 960px container + 4rem
$desktop: 960px + (2 * $gap) !default;
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap) !default;
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap) !default;