$color-grey: #f6f6f6;

$color-bittersweet: #ff7556;
$color-coral-reef: #ff886d;

$color-sunglow: #ffcc35;
$color-mustard: #ffdd66;

$color-powder-blue: #aae2dd;
$color-middle-blue-green: #80ccca;
$color-fountain-blue: #67b3b1;


$color-pastel-red: #ff5c5f;
$color-iguana-green: #75be86;
$color-white: #fff;

$color-charleston-gray: #292929;
$color-dim-gray: #6b6b6b;
$color-silver: #bfbfbf;
$color-isabelline: #ededed;
$color-alabaster: #f8f8f8;

$color-text: $color-charleston-gray;
$color-accent: $color-bittersweet;
$color-accent-hover: $color-coral-reef;

$color-cta: $color-sunglow;
$color-cta-hover: $color-mustard;

$color-section-background-main: $color-middle-blue-green;
$color-section-background-alt: $color-bittersweet;

$color-error: $color-pastel-red;
$color-success: $color-iguana-green;

$green: $color-middle-blue-green;
$light_green: #ebfdb6;
$very_light_green: #f6fde1;
$dark_green: #87b407;
$alt_green: #ffffcb;

$yellow: #f8f6ed;
$yellow_border: #eee;

$white: #fff;

$orange: $color-bittersweet;

$black: $color-charleston-gray;
$total_black: #000;

$very_light_grey: #f6f6f6;
$light_grey: #EEEEEE;
$medium_light_grey: #ababab;
$grey: #bfbfbf;



$medium_grey: #606060;
$dark_grey: #666;

$red: #f7929c;
$strong_red: #ff0000;
$blue: #0036ff;

$facebook_color: #3B5998;
$facebook_light_color: #3f67a9;
$twitter_color: #4099FF;
$google_plus_color: #666;
$google_plus_light_color: #adadad;
$youtube_color: #CD332D;

$christmasRed: #ff5757;
