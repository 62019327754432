.video-embed-wrap {
  cursor: pointer;
  display: inline-block;
  margin-right: 2.5%;
  position: relative;
  vertical-align: top;
  width: 100%;
  @include from($desktop) {
    width: 50%;
  }
  .icon-play {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    .play-circle {
      stroke: rgba($white, .85);
    }
    .play-triangle {
      fill: rgba($white, .85);
    }
  }
  &:hover {
    .icon-play {
      .play-circle {
        stroke: $white;
      }
      .play-triangle {
        fill: $white;
      }
    }
  }
}

.video-embed-container {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-embed-wrap--center {
  display: block;
  margin: auto auto 3rem auto;
}
.in-about {
  .video-embed-wrap--center {
    width: 100%;
    max-width: 500px;
    margin: 2rem;
  }
}