.c-footer {
  // display: grid;

}

.c-footer__link {
  color: $white;
  font-size: 1em;
  line-height: 1.5;

  &:hover {
    text-decoration: underline;
  }

  @include from($desktop) {
    font-size: .75em;
  }
}

.footer-column {


}

.footer-link-section {
  margin: 1.5rem auto;
  width: 90%;

}


.c-footer__show-on-mobile {
  display: block;
}

.c-footer__hide-on-mobile {
  display: none;
  @include media('>780px') {
    display: block;
  }
}

.c-footer__hide-on-desktop {
  display: none;
  @include media('<780px') {
    display: block;
  }
}

.footer-wrap {
  background: $black;
  padding: 3em 1em 6em;
  @include media('<780px') {
    padding: 3em 1em 10em;
  }
}

.footer-bottom {
  margin-top: 3rem;
  align-items: center;
  display: flex;
}


.footer-columns {
  color: $white;
  display: grid;
  grid-gap: 2.5em;
  grid-template-columns: auto;
  grid-template-rows: 1fr auto;
  @include media('>780px') {
    grid-template-columns: repeat(5, 1fr);
  }
}

.photocircle-benefits {
  @include media('<1080px') {
    display: none;
  }
  background-color: $yellow;
  clear: both;
  margin-top: 40px;
  padding: 2.5em 0;
  text-align: center;

  .print-photos-benefits-title {
    font-size: calc-em(24px, 16px);
    margin-bottom: 1.5em;
  }

  .benefits-wrapper {
    margin: 0 auto;
    padding: 1.5em 0;
  }

  .benefit {
    border-right: 1px solid #ddd;
    display: inline-block;
    height: 220px;
    padding: 0 1.5em;
    vertical-align: top;
    width: 240px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border: none;
      padding-right: 0;
    }

    .benefit-picture {
      height: 65px;
      margin-bottom: 1.5em;

      .benefit-picture-icon {
        height: 100%;
        width: 100%;

      }
    }

    h4 {
      font-size: 1em;
    }

    p {
      font-size: 0.75em;
      line-height: 1.75;
    }
  }

  &.in-homepage {
    margin: 0;
  }
}

.footer-column-title {
  display: block;
  font-size: calc-em(16px, 16px);
  font-weight: 400;
  letter-spacing: 0.1em;
  margin-bottom: calc-em(6px, 12px);
}

.c-social-icons {
  align-items: center;
  grid-column: 1 / -1;
  justify-content: center;
  text-align: center;
  @include from($tablet) {
    display: flex;
    justify-content: flex-start;
  }
}

.c-social-icons__list {
  font-size: 1.5em;
}

.c-social-icons__label {
  color: $white;
  margin: 0 0 1.5rem 0;
  @include from($tablet) {
    margin: 0 1.5rem 0 0;
  }
}

.c-social-icons a {
  color: $black;
  text-decoration: none;
}

.footer-meta {
  color: $white;

  .photocircle-copyright {
    display: block;
    margin-bottom: 12px;
  }
}

.footer-languages > li {
  display: inline;
  margin-right: calc-em(24px, 16px);
  @include media('<560px') {
    display: block;
    margin: 0 0 12px 0;
    text-align: center;
  }

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      color: $green;
    }
  }
}

.footer-second-column {
  margin-top: 3em;
}

.c-newsletter-container {
  background: $color-section-background-main;
  color: $white;
}

.c-newsletter {
  align-items: center;
  justify-content: center;
}

.c-newsletter__badge {
  margin-right: 1em;
  width: 2.5rem;
  @include from($tablet) {
    width: 10em;
  }

  svg {
    width: 100%;
  }
}

.c-newsletter__title {
  color: inherit;
  font-size: 1.5em;
  font-weight: 400;
}

.c-newsletter__text {
  color: inherit;
  font-size: 1em;
  font-weight: 300;
}

.c-newsletter__input {
  border: 1px solid $white;
}

.c-newsletter__button {
  background: transparent;
  border: 1px solid $white;
  color: $white;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background: $white;
    border-color: currentColor;
    color: $color-section-background-main;
  }
}

.language-item {
}

.language-icon {
  margin-right: 1em;
  vertical-align: middle;
  width: 2em;
}

.language-name {
  margin-right: 1em;
}

