.c-voucher-box {
  .o-input {
    text-transform: uppercase;
  }
  @include block;
  text-align: right;
  .o-field {
    justify-content: flex-end;
  }
  &__active-code {
    border-bottom: 3px dotted $color-accent-hover;
    text-transform: uppercase;
  }
  &__unset {
    background: none;
    border: none;
    color: $black;
    display: block;
    margin-left: auto;
    padding: 1rem 0 0 0;
  }
}

#discount-code-error-message {
  margin-top: .5rem;
}