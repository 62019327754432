.create-profile-form-wrapper {
  margin-bottom: 1em;
}

.my-account-buttons-container {
  margin: 1em 0;
}

.my-photocircle-disabled-edit {
  cursor: pointer;
  color: $color-dim-gray;
}

.my-photocircle-column--action {
  min-width: 108px;
  width: 10%;
}

.my-photocircle-your-earnings {

}
