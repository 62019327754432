@mixin button ($padding:10px, $font_size: 18px, $bgColor: $orange) {
	background-color: $bgColor;
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-size: calc-em($font_size, 16px);
	font-weight: 300;
	padding: 1em 1.5em;
	text-decoration: none;
	&:hover {
	};
}
