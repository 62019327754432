%block {
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

@mixin block {
  @extend %block;
}

@mixin link {
  border-bottom: 2px solid currentColor;
  color: $color-text;
  cursor: pointer;
  &:hover {
    border-color: $color-accent;
  }
}
