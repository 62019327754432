.c-card {
  background: $color-alabaster;
  flex: 0 0 30%;
  margin-bottom: 1rem;
  min-width: 0;
  padding: 1rem;
  width: 100%;
  position: relative;
  &--4 {
    flex: 0 0 calc(25% - 3rem);
    margin: 0 1.5rem;
    max-width: calc(25% - 3rem);
  }
}

@supports (display: grid) {
  .c-card {
    margin: auto;
    max-width: none;
  }
}

.c-card__mockup {
  position: absolute; 
  top: 0px; left: 0px; right: 0px; 
  height: 17rem;
  display: block; 
  z-index: 10; 
  overflow-y: hidden; 
  background-size: cover; 
  background-position: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
}
.c-card:hover {
  @include media('>780px') {
    .c-card__mockup {
      visibility: visible;
      opacity: 1;
    }
  }

}

// TODO: rename to wrapper or something
.c-card__link {
  // this fixes a bug in IE11
  // probably flexbug 6: https://github.com/philipwalton/flexbugs#6-the-default-flex-value-has-changed
  align-items: center;
  display: flex;
  height: 100%;
  flex: 1 1 0%;
  //height: 100%;
  justify-content: center;
  .o-image {
    align-items: center;
    display: flex;
    height: 100%;
    max-height: 100%;
    //padding: 1rem;
  }
}

/* color, font-size, max-height, and word-break are used in the gallery,
 * so maybe this class needs to be moved somewhere else or to
 * another component
 */

.c-card__img {
  box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, .35);
  color: $color-text;
  display: block;
  font-size: .5em;
  height: auto;
  margin: 0 auto;
  max-height: 19rem;
  max-width: 100%;
  //padding: 1rem;
  word-break: break-word;
  @include from($desktop) {
    max-height: 15rem;
  }
  &.horizontal {

  }
  &.vertical {
    //height: 100% !important;
    width: auto;
    max-height: 16rem;
  }
}

.c-card__footer {
  font-size: .75em;
  margin-top: 1rem;
  position: relative;
  .c-fav-button {
    position: absolute;
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    bottom: -1.5rem;
    right: -1.5rem;
    width: 30px;
    height: 30px;
    .icon {
      position: absolute;
      top: 6px;
      left: 5px;
    }
  }
  /*
  align-items: center;
  display: flex;
  justify-content: space-between;

  //padding: 1rem;
  @include from($desktop) {
    font-size: .75em;
  }
  > * + * {
    margin-left: 1.5rem;
  }*/
}

.c-card__text {
  overflow: hidden;
  text-align: left;
  width: 100%;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@include from($tablet) {
  .c-card--4 {
  }
}

.c-card__artist-link,
.c-card__title-link {
  color: $black;
  &:hover {
    color: $orange;
  }
}

.c-card__title-link {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.c-card__artist {
  @include media('<600px') {
    display: none;
  }
}

.c-image-grid {
  display: flex;
  flex-wrap: wrap;
}

@supports (display: grid) {
  .c-image-grid {
    display: grid;
    grid-gap: 1.5em;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    @include media('<780px') {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

.c-fav-button {
  cursor: pointer;
}

.favourited-true,
.favourited-1 {
  color: $orange;
}

.wishlist-empty-text {
  font-size: 1.5em;
  margin: 0 0 3em 0;
}

.c-card__price {
  margin-top: .5rem;
}
