.calendar-page {
  max-width: 1530px;
  margin: auto;
}
.c-calendar-format {
  display: flex;
}
.c-calendar-format__item {
  flex: 1;
  &:not(:last-child) {
    margin-right: 0.75rem;
  }
  .o-radio__box {
    border: 2px solid $color-dim-gray;
    padding: 0.75rem;
    transition: all ease-in-out 0.2s;
    font-size: 0.9rem;
    &:hover  {
      border-color: $color-text;
    }
  }

  .o-radio:checked ~ .o-radio__box {
    background: $color-text;
    border-color: $color-text;
    color: $color-white;
  }
}

.c-calendar-format__size {
  font-size: 0.75em;
  margin-bottom: 0.5em;
}
.c-calendar-format__original-price {
  color: $color-silver;
  margin-right: 0.15rem;
  text-decoration: line-through;
}
.c-calendar-format__current-price {
  font-weight: 400;
}
.c-calendar-format__rowc-calendar-format__price.discounted {
  .c-calendar-format__current-price {
    color: $color-pastel-red;
    font-size: 1rem;
    margin-top: 0.25rem;
  }
}
.c-calendar-format__original-price-string {
  background: $color-pastel-red;
  color: white;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  margin-left: 0.2rem;
}
.calendar-format-available-0 {
  opacity: 0.5;
  .o-radio__box {
    cursor: auto;
  }
}

.calendar-page-footer {
  margin-top: calc-em(24px, 16px);
  .calendar-preview-container {
    width: 30.8%;
  }
}

.calendar-page-thumbnail-pager {
  @include photo-product-page-thumbnail-pager;
}

.calendar-mobile-form {
  @include photo-product-page-mobile-form;
  margin-top: 3em;
  text-align: right;
}

.customize-calendar-page {
  margin: 0 auto;
  max-width: 1024px;
  padding-top: 3em;
  text-align: center;
  width: 80%;
}

.customize-calendar-section {
  padding: 3em 0;
}

.customize-calendar-subtitle {
  text-transform: uppercase;
}

.customize-calendar-section-title {
  font-size: 2em;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.customize-calendar-section-single-image {
  margin-bottom: 3em;
  img {
    width: 100%;
  }
}

.customize-calendar-subsection-title {
  font-size: 1.5em;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.customize-calendar-section-grid {
  display: flex;
  margin-bottom: 3em;
}

.customize-calendar-section-grid-image-wrap {
  padding: 1em;
  img {
    @include shadow(1);
    width: 100%;
  }
}

.customize-calendar-section-grid-image-wrap-text {
  font-size: 1.25em;
  margin-top: 1em;
  text-transform: uppercase;
}

.customize-calendar-price {
  border: 1px solid $black;
  color: $black;
  font-size: 1.25em;
  margin: 2.5em auto;
  padding: 1em 0.5em;
  text-align: center;
  width: 35%;
}

.swiper-container-with-arrows {
  width: 100%; 
  position: relative; 
  display: flex;
  margin-left: 0px;
  @include from($desktop) {
    margin-left: -32px;
  }
}
.swiper-container {
  //height: 100%;
  width: 100%;
}
.swiper-button-prev, .swiper-button-next {
  --swiper-navigation-size: 24px;
  opacity: 0.3;
  color: inherit;
}
.swiper-button-prev {
  left: 0;
}
.swiper-button-next {
  right: 0;
}
.swiper-wrapper {
}

.swiper-slide {
  width: 100%;
  box-sizing: border-box;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  img {
    width: 100%;
    height: auto;
    border: 1px solid #eee;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);

  }
}

.calendar-swiper-wraps {
  @include from($desktop) {
    display: flex;
    justify-content: space-around;
  }
}

.calendar-column {
  flex: 1 1 40%;
  max-width: 700px;
  min-width: 0;
  .c-headline {
    @include until($tablet) {
      font-size: 1rem;
    }
  }
}
.calendar-box-horizontal {
  max-width: 700px;
}
.calendar-box-vertical {
  max-width: 480px;
}

.calendar-page-sidebar {
  flex: 0 0 30%;
  @include until($tablet) {
    margin-top: 2rem;
  }
  .c-trees {
    //border: none;
    margin: 1.5rem 0rem;
  }
}

.calendar-page-total {
  font-weight: 700;
  margin-top: 2rem;
}

.calendar-page-footer {
  margin-bottom: 1.5rem;
}

.calendar-page-sidebar-project {
  margin: 1rem 0;
}

.calendar-thumbs-navigation {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  .calendar-thumb {
    border: 1px solid $grey;
    cursor: pointer;
    flex: 1 0 calc(14% - 1rem);
    margin: 0 1rem 0.75em 0;
    &:nth-child(7n) {
      margin-right: 0;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
.calendar-column-horizontal  {
  .calendar-thumbs-navigation, .calendar-page-footer { 
    max-width: 640px;
  }
}
.calendar-column-vertical  {
  .calendar-thumbs-navigation, .calendar-page-footer { 
    max-width: 500px;
  }
}
.c-calendar-thumb__title {
  color: $black;
  font-size: 0.75em;
  margin: auto;
  max-width: 200px;
  padding: 0 1rem;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/*
.c-calendar-thumb-is-horizontal {
  height: auto !important;
  width: 100% !important;
}

.c-calendar-thumb-is-vertical {
  height: 100% !important;
  width: auto !important;
}
*/

.calendar-preview-container-price.discounted {
  .calendar-final-price {
    color: $color-pastel-red;
  }
  .calendar-original-price-string {
    background: $color-pastel-red;
    color: white;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
}
.calendar-original-price {
  color: $color-silver;
  text-decoration: line-through;
}
