.o-columns,
.o-columns-section {
  @include from($desktop) {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }
  @include tablet {
    display: flex;
  }
  &.is-mobile {
    display: flex;
    margin-left: -.75rem;
    margin-right: -.75rem;
  }
  &.o-columns--big-gap {
    margin-left: -3rem;
    margin-right: -3rem;
  }
}

.o-column {
  flex: 1 1 0;
  padding: .75rem;
  &.is-narrow {
    flex: none;
  }
}

.o-column--extra-padding {
  @include from($desktop) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.o-columns--big-gap {
  .o-column {
    padding: 3rem;
  }
}

.o-column--gapless {
  padding: 0;
}

.o-column--left {
  order: -1;
}

@include tablet {
  .o-column--half {
    flex: none;
    width: 50%;
  }

  .o-column--one-third {
    flex: none;
    width: 33.3333%;
  }

  .o-column--two-thirds {
    flex: none;
    width: 66.6666%;
  }

  .o-column--one-fourth {
    flex: none;
    width: 25%;
  }
  .o-column--three-fourths {
    flex: none;
    width: 75%;
  }

  .o-column--one-fifth {
    flex: none;
    width: 20%;
  }
  .o-column--two-fifths {
    flex: none;
    width: 40%;
  }
  .o-column--three-fifths {
    flex: none;
    width: 60%;
  }
  .o-column--four-fifths {
    flex: none;
    width: 80%;
  }


}

.o-column--center {
  margin: auto;
}
