.c-image-slider {
  position: relative;
}



.c-image-slider__slide {
  left: 0;
  position: absolute;
  &.is-active {
    position: static;
  }
}