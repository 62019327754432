.c-quote {
  display: flex;
  font-size: 1rem;
}

.c-quote__text {
  margin: 0 auto 1.5rem auto;
  position: relative;
  @include from($desktop) {
    font-size: 1.5em;
    max-width: 66.666%;
  }
}

.c-quote__footer {
  justify-content: center;
}

.c-quote__image {
  border-radius: 50%;
  overflow: hidden;
  width: 110px;
  @include until($desktop) {
    margin-bottom: 1rem;
  }
  &.has-no-text {
    border-radius: 0;
    max-width: 100%;
    width: 400px;
  }
}
