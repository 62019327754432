$color-gift-card-primary: #5cacce;
$color-gift-card-secondary: #b6e0e8;

.see-preview {
  cursor: pointer;
  margin-top: 15px;
  text-align: center;
}

.giftcards-page-preview-wrap {
  width: 100%;
  max-width: 100%;
}

// giftcard preview
.giftcard-small-preview {
  background: $white;
  margin: auto;
  margin-bottom: 5px;
  padding: 0.5em;
  position: relative;
  width: 100%;
  border: 1px solid $color-gift-card-secondary;
  @include from($desktop) {
    width: 80%;
    margin-top: 1.5rem;
  }
}

#giftcards-page {
  padding-bottom: 6rem;
  .giftcard-preview-header-wrap {
    padding: 3em 0.5em;
  }
}
.giftcard-preview-header-wrap {
  background-color: $color-gift-card-secondary;
  padding: 0.5em;
  text-align: center;

  img {
    width: 80%;
  }
}

.giftcard-preview-header-wrap-img {
  margin-bottom: .75em;
}

.giftcard-preview-slogan {
  color: $white;
  font-family: $lora;
  font-size: 0.5em;
  margin-top: 0.75em;
}

.giftcard-big-preview-header-image {
  margin-bottom: 1em;
}

.giftcard-big-preview-header-logo {

}

.giftcard-big-preview-header-slogan {
  margin-top: 1em;
}

#giftcard-big-preview {
  //height: 1148px;
  position: relative;
  width: 800px;
  #giftcard-big-preview-price-container {
    position: absolute;
    top: 310px;
    left: 361px;
    color: $orange;
    font-size: 120px;
  }
  #giftcard-big-preview-text-container {
    position: absolute;
    top: 435px;
    left: 50px;
    line-height: 18px;
    line-height: 24px;
    p {
      margin-top: 10px;
      font-family: $montserrat;
      width: 750px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.giftcard-photocircle-logo {
  font-size: calc-em(16px, 16px);
  font-weight: bold;
  text-transform: uppercase;
}

.giftcard-slogan {
  font-size: calc-em(12px, 16px);
  line-height: 1.25em;
  margin: 0;
}

.giftcard-cart-content {
  position: absolute;
  left: 50%;
  top: 50%;
}

.giftcard-preview-giftcard-label {
  color: $black;
  font-weight: 400;
  text-transform: uppercase;
}

.giftcard-cart-label {
  font-size: calc-em(14px, 16px);
  font-weight: 400;
  text-transform: uppercase;
}

.giftcard-preview-price-wrap {
  border: 1px solid $color-gift-card-secondary;
  font-size: 1em;
  font-weight: 700;
  margin-top: 0.5em;
  padding: 0.5em;
  text-align: center;
}

.order-summary,
.c-cart {
  .giftcard-preview-price-wrap {
    display: none;
  }
}

.giftcard-preview-price {
  color: $color-gift-card-primary;
}

.giftcard-cart-price {
  color: $color-gift-card-primary;
  font-size: calc-em(36px, 16px);
  line-height: 1em;
}

.giftcard-big-preview {
  display: none;
  width: 800px;
}

.giftcard-big-preview-header {
  background-color: $color-gift-card-secondary;
  color: $white;
  padding: 1em;
  text-align: center;
  width: 100%;
  img {
    width: 80%;
  }
}

.giftcard-big-preview-message-section {
  border: 5px solid $color-gift-card-secondary;
  margin: 1.5em 0 1.5em 0;
  padding: 0.5em;
  text-align: center;
}

.giftcard-big-preview-value {
  font-size: 3em;
  text-transform: uppercase;
}

.giftcard-big-preview-price-wrap {
  color: $color-gift-card-primary;
}

.giftcard-big-preview-hiw-section {
  border-collapse: separate;
  border-spacing: 1em;
  a {
    color: $color-gift-card-primary;
    font-weight: 400;
    text-decoration: none;
  }
  td {
    width: 33%;
  }
  img {
    width: 100%;
  }
}

.giftcard-big-preview-hiw-title {
  font-size: 2em;
  text-align: center;
}

.giftcard-big-preview-hiw-step-title {
  text-align: center;
}

.giftcard-big-preview-footer-wrap {
  background-color: $color-gift-card-secondary;
  color: $white;
  font-family: $lora;
  line-height: 1.25;
  padding: 1em;
  width: 100%;
}

.giftcard-big-preview-code-container {
  background-color: $white;
  color: $black;
  font-family: $montserrat;
  font-size: 1.5em;
  display: inline-block;
  padding: .5em 1em;
  vertical-align: top;
}

.giftcard-big-preview-instructions {
  display: inline-block;
}

.giftcard-big-preview-rules {
  list-style-type: disc;
  list-style-position: inside;
  margin-top: 1em;
}

.giftcard-big-preview-website {
  font-family: $montserrat;
  margin-top: 1em;
  text-align: center;
}

.giftcard-box {
  margin-bottom: 1.5rem;
}

.giftcards-dropdown {
  padding: 6px;
}
