.c-product-features {
  display: flex;
  flex-wrap: wrap;
  font-family: $bodyFont;
  justify-content: center;
  margin-bottom: 3rem;
  text-align: center;
  @include tablet-only {
    font-size: .875em;
  }
}

.c-product-features {
  //display: none;
}

.c-product-features--item {
  flex: 0 0 50%;
  padding: 1.5rem;
  max-width: 250px;
  @include from($tablet) {
    flex: 1;
    padding: 1.5rem 1.5rem 0 1.5rem;
  }
  img {
    margin: 0 auto 1em auto;
    max-width: 318px;
    @include from($tablet) {
      max-width: 112px;
    }
  }
}
