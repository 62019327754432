.green {
  color: $green !important;
}
.light_green {
  color: $light_green !important;
}
.very_light_green {
  color: $very_light_green !important;
}
.white {
  color: $white !important;
}
.black {
  color: $black !important;
}
.total_black {
  color: $total_black !important;
}
.orange {
  color: $orange !important;
}
.red {
  color: $red !important;
}
.strong_red {
  color: $strong_red !important;
}
.facebook_blue, .fb_blue, .blue_fb, .fb_color {
  color: $facebook_color !important;
}
.twitter_color {
  color: $twitter_color !important;
}
.gplus_color, .googleplus_color, .google_plus_color {
  color: $google_plus_color !important;
}
.blue {
  color: $blue !important;
}
.grey {
  color: $grey !important;
}
.light_grey {
  color: $light_grey !important;
}
.medium_grey {
  color: $medium_grey !important;
}
.medium_light_grey {
  color: $medium_light_grey !important;
}

.green_border {
  border-color: $green !important;
}
.light_green_border {
  border-color: $light_green !important;
}
.black_border {
  border-color: $black !important;
}
.light_grey_border {
  border-color: $light_grey !important;
}
.grey_border {
  border-color: $grey !important;
}
.white_border {
  border-color: $white !important;
}
.orange_border {
  border-color: $orange !important;
}


.green_background, .green_bg {
  background: $green !important;
}
.light_green_background, .light_green_bg {
  background: $light_green !important;
}
.light_grey_background, .light_grey_bg {
  background: $light_grey !important;
}
.grey_background, .grey_bg {
  background: $grey !important;
}
.white_background, .white_bg {
  background: $white !important;
}
.black_background, .black_bg {
  background: $black !important;
}
.orange_background, .orange_bg {
  background: $orange !important;
}


