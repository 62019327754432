.c-product-prices-table {
  line-height: 1.5;
}

.c-product-prices-table__header {
  //padding: 1.5rem 0;
}

.c-product-prices-table__frame-options {
  border: 1px solid $color-silver;
  margin-top: 1.5rem;
  padding: 1rem;
  @include from($desktop) {
    display: flex;
  }
  label {
    display: block;
  }
  label:first-child {
    margin-right: 1.5rem;
  }
  input {
    margin-right: .85rem;
  }
}

.c-product-prices-table__content {
  font-size: .875em;
  margin-top: 1.5rem;
  @include from($tablet) {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
  }
  @include from($desktop) {
    grid-gap: 3rem;
    grid-template-columns: repeat(4, 1fr);
  }
}

.c-product-prices-table__column {
  border: 1px solid $color-silver;
  margin-bottom: 1.5rem;
  @include from($desktop) {
    margin-bottom: 0;
  }
}

$baseSize: 12px;
.c-product-prices-table__title {
  font-size: 1.25em;
  font-weight: 400;
  margin-bottom: 1rem;
  padding: 0 .5rem;
  &:before {
    border: 2px solid $black;
    content: '';
    display: inline-block;
    height: $baseSize;
    margin-right: 1em;
  }
  &.format-din:before {
    width: $baseSize * 1.4142;
  }
  &.format-1_1:before {
    width: $baseSize;
  }
  &.format-2_1:before {
    width: $baseSize * 2;
  }
  &.format-3_1:before {
    width: $baseSize * 3;
  }
  &.format-3_2:before {
    width: $baseSize / 2 * 3;
  }
  &.format-4_1:before {
    width: $baseSize * 4;
  }
  &.format-4_3:before {
    width: $baseSize / 3 * 4;
  }
  &.format-16_9:before {
    width: $baseSize / 9 * 16;
  }
}

.c-product-prices-table__row {
  border-bottom: 1px solid $color-silver;
  display: flex;
  font-family: $bodyFont;
  justify-content: space-between;
  padding: .25rem .5rem;
  &:first-child {
    border-top: 1px solid $color-silver;
  }
  &:last-child {
    border-bottom: 0;
  }
  &:hover {
    background: $color-cta;
  }
}
