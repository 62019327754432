.c-info-bar {
  display: none;
  background: $white;
  font-size: .75em;
  padding: .5rem 1rem;
  @include from($tablet) {
    display: block;
  }
}

.c-info-bar__list {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.c-info-bar__item {
  margin-right: 3em;
  &--right {
    align-items: center;
    display: flex;
    margin: 0 0 0 auto;
  }
  a {
    color: $black;
  }
}
