@function calc-em($target-px, $context) {

  @return ($target-px / $context) * 1em;
}


@import 'variables';
@import 'tools/include-media';
@import 'tools/responsive-mixins';
@import 'tools/helper';
@import 'tools/mixins';

@import 'settings/breakpoints';
@import 'settings/colors';
@import 'settings/fonts';

@import 'tools/utilities';

@import 'tools/typography';
@import 'tools/crop';

@import 'generic/reset';
@import 'generic/my-reset';
@import 'generic/reset-fieldset';
@import 'generic/all';

@import 'elements/body';
@import 'elements/headings';
@import 'elements/lists';
@import 'elements/table';

//@import 'fonts/payment_font';
@import 'common';
@import 'typography/typography';
@import 'font-awesome/font-awesome';
@import 'buttons';

@import 'vendors/featherlight/featherlight';
@import 'vendors/drop/drop-theme-arrows-bounce-dark';

@import 'modules/plugins/balloon';
@import 'modules/plugins/stickystate';
@import 'modules/plugins/chosen';
@import 'modules/plugins/selectize';
@import 'modules/plugins/selectize_carlo';
@import 'modules/plugins/tooltipster.bundle.min';
@import 'modules/plugins/tooltipster-sideTip-borderless.min';
@import 'modules/plugins/tooltipster-sideTip-noir.min';
@import 'modules/plugins/modal-video.min';

@import 'vendors/parsley';
@import 'vendors/slick';
@import 'vendors/slick-theme';
@import 'vendors/sweetalert';
@import '~nouislider/distribute/nouislider.css';
@import '~swiper/swiper-bundle.min.css';

@import '~cropperjs/dist/cropper.css';

@import 'objects/section';
@import 'objects/container';
@import 'objects/hero';
@import 'objects/content';
@import 'objects/stack';
@import 'objects/wrappers';
@import 'objects/column';
@import 'objects/juxtaposed';
@import 'objects/form';
@import 'objects/image';
@import 'objects/media-object';
@import 'objects/grids';
@import 'objects/buttons';
@import 'objects/radio-hidden';
@import 'objects/definition-list';
@import 'objects/level';

@import 'dropzone.upload';

@import 'components/content-slider';
@import 'components/buttons';
@import 'components/header';
@import 'components/logo';
@import 'components/overlay';
@import 'components/pagination';
@import 'components/video-embed';
@import 'components/photo-grid';
@import 'components/image-thumbnail';
@import 'components/main-nav';
@import 'components/cart';
@import 'components/voucher-box';
@import 'components/info-bar';
@import 'components/search';
@import 'components/project-map';
@import 'components/headlines';
@import 'components/language-selector';
@import 'components/hero-image';
@import 'components/photo-thumb-nav';
@import 'components/product-grid-select';
@import 'components/photo-sidebar';
@import 'components/project-selector';
@import 'components/concept-bar';
@import 'components/team-member';
@import 'components/site-seals';
@import 'components/newspapers';
@import 'components/print-photo-upload';
@import 'components/photolab-upload';
@import 'components/product-features';
@import 'components/photographers';
@import 'components/payment-icons';
@import 'components/gallery';
@import 'components/gallery-sidebar';
@import 'components/breadcrumbs';
@import 'components/products';
@import 'components/slider';
@import 'components/tabbed-menu';
@import 'components/product-prices-table';
@import 'components/errors';
@import 'components/card';
@import 'components/quote';
@import 'components/order-summary';
@import 'components/message';
@import 'components/curators';
@import 'components/contact';
@import 'components/photolab-instagram-landing-lage';
@import 'components/tree-project';
@import 'components/print-quality';
@import 'components/customize-print';
@import 'components/reel';
@import 'components/customer-shared-photos';
@import 'components/popup';
@import 'components/partners';
@import 'components/toggleSwitch';

@import 'modules/popup';
@import 'photo-products';

@import 'pages/my_photocircle';
@import 'pages/about';
@import 'pages/how_it_works';
@import 'pages/projects';
@import 'pages/calendars';
@import 'pages/postcard_sets';

@import 'pages/products_page';
@import 'pages/static_pages';
@import 'pages/artist_join_page';

@import 'modules/my_photocircle/module_my_photocircle';
@import 'modules/photos/module_photo';

@import 'modules/giftcards/module_giftcard';
@import 'modules/checkout/module_checkout';
@import 'modules/thank_you/module_thank_you';
@import 'modules/auth/module_auth';
@import 'modules/general_information/module_general_information';
@import 'modules/static/module_static';
@import 'modules/static/module_competition';
@import 'modules/users/module_public_profile';
@import 'modules/timber_frames/module_timber_frames';

@import 'modules/footer/module_footer';

@import 'general';

@import 'progressbars/mixin';

@import 'trumps/colors';
@import 'trumps/featherlight';
@import 'trumps/dropzone';
@import 'trumps/iubenda';
@import 'trumps/overwrites';
@import 'trumps/trusted-badge';

@import 'magazine';
@import '~@wordpress/block-library/build-style/style.css';
