.c-team-members {
  margin-top: 5em;
}

.c-team-member {
  //align-items: center;
  @include media('<700px') {
    display: block;
  }
}

.c-team-member__img {
  border-radius: 50%;
  flex: 0 0 10%;
  width: 70%;
  text-align: center;
  max-width: 200px;
  @include media('<780px') {
    width: 50%;
  }
}

.c-team-member__title {
  margin-bottom: 1.5rem;
}

.c-team-member__name {
  color: $black;
  font-weight: 400;
}