.parsley-errors-list {
  color: $color-error;
  font-size: .75em;
  margin-top: .25rem;
}

.parsley-error {
  border: 1px solid $color-error;
  &.o-checkbox {
    border: 0;
  }
}

.parsley-success {
  border: 1px solid $color-success;
  &.o-checkbox {
    border: 0;
  }
}

