dl {
  & > div {
    display: flex;
    margin-bottom: .75rem;
  }
}

dt {
  margin-right: .75rem;
}

dd {

}