#need-help-popup {
	background: $white;
	padding: 20px;
	height: 460px;
	border-radius: 5px;
	#need-help-popup-left-column {
		float: left;
		width: 320px;
	}
	.feedback-form-container  {
	//	margin: 25px 0px 0px 0px;
		margin: -25px 0px 0px 0px;
	}
}
