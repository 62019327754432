.postcard-sets-page {
    @include photo-products-page;
}

.postcard-sets-container {
    @include photo-products-page-container;
}

.postcard-set-preview-container {
    @include photo-products-page-preview-container($columnsCount: 3);
    @include media('<961px') {
        width: 30.83%; /* width for 3 columns grid */
    }
    @include media('<651px') {
        width: 47.5%; /* width for 2 columns grid */
    }
    @include media('<380px') {
        width: 100%; /* width for 1 columns grid */
    }
    &-title {
        font-size: calc-em(16px, 16px);
    }
}

.postcard-sets-extra-content {
    margin: 78px auto;
    text-align: center;
    width: 1130px;

    .postcard-sets-extra-content-row {
        margin-bottom: 78px;
        &:after {
            content: " ";
            display: block;
            height: 0;
            clear: both;
        }
    }

    .postcard-sets-extra-text-1,
    .postcard-sets-extra-text-2,
    .postcard-sets-extra-text-3 {
        text-align: left;
        h2 {
            padding-top: 0;
            margin-bottom: 12px;
        }
    }

    .postcard-sets-extra-text-1 {
        vertical-align: top;
        width: 950px;
    }

    .postcard-sets-extra-text-2 {
        float: right;
        width: 700px;
    }

    .postcard-sets-extra-text-3 {
        width: 1100px;
    }

    .magazines {
        float: left;
        margin-right: 68px;
        overflow: hidden;
    }

    .partners, .magazines {
        width: 400px;
        h4 {
            margin-bottom: 12px;
        }
    }
    .partners {
        float: right;
        text-align: right;
    }
    .postcard-set-award-logo {
        float: left;
        margin-right: 48px;
        overflow: hidden;
        width: 200px;
    }
    .magazines {
        text-align: left;
    }
    .postcard-sets-extra-text {
        width: 700px;
        h2 {
            color: $black;
            margin-bottom: 12px;
            padding: 0;
        }
        h3 {
            color: $orange;
        }
        p {
            font-size: 12px;
            line-height: 1.5em;
        }
        &.right {
            margin-left: 40px;
        }
        &.left {
            margin-right: 20px;
        }
    }
}

.postcard-set-page {
    @include photo-product-page;
}

.postcard-set-page-title {
    @include photo-product-page-title;
}

.postcard-set-page-content-wrap {
    @include photo-product-page-content-wrap;
}

.postcard-set-page-buy-button {
    @include photo-product-page-buy-button;
}

.postcard-set-page-img-column {
    @include photo-product-page-img-column;
}

.postcard-set-img {
    @include photo-product-page-img;
}

.postcard-set-page-sidebar {
    @include photo-product-page-sidebar;
}

.postcard-set-page-sidebar-format {
    display: block;
    font-family: $lora;
    font-size: calc-em(14px, 16px);
    margin-bottom: 12px;
    padding-left: 15px;
    position: relative;
    text-indent: -15px;
    input {
        padding: 0;
        margin: 0;
        vertical-align: bottom;
        position: relative;
        top: -1px;
        display: inline-block;
        height: 12px;
        margin-right: 12px;
        width: 12px;
    }
    .price-container {
        position: absolute;
        right: 0;
        .original-price {
            color: $grey;
            margin-right: calc-em(6px, 14px);
            text-decoration: line-through;
        }
    }
}

.postcard-set-page-footer {
    margin-top: calc-em(24px, 16px);
    .postcard-set-preview-container {
        width: 30.8%;
    }
}

.postcard-set-page-footer-btn {
    @include photo-product-page-footer-btn;
}

.postcard-set-page-sidebar-project {
    @include photo-product-page-sidebar-project;
}

.postcard-set-page-total {
    @include photo-product-page-total;
}

.postcard-set-page-sidebar-project-img {
    @include photo-product-page-sidebar-project-img;
}

.postcard-set-page-sidebar-project-txt {
    @include photo-product-page-sidebar-project-txt;
}

.postcard-set-thumbnail-pager {
    @include photo-product-page-thumbnail-pager;
}

.postcard-set-preview-container-price {
    font-size: calc-em(16px, 12px);
}

.postcard-set-mobile-form {
    @include photo-product-page-mobile-form;
}
