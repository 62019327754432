.timber-frames-page {
    margin-top: 3.625em;
    padding: 0 10%;
    .page-main-title,
    .page-main-subtitle,
    .page-main-text {
        font-size: calc-em(16px, 16px);
        text-align: center;
    }
    @include media('<1200px') {
        padding: 0 5%;
    }
}

$margin: 2.5%;
.timber-frames-gallery {
    font-size: 0;
    margin-left: (-$margin);
    margin-top: 48px;
}

.timber-frame-preview-container {
	border: 1px solid #ededed;
    display: inline-block;
    font-family: $lora;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.2em;
    margin: 0 0 $margin $margin;
	text-align: center;
    vertical-align: top;
    width: 22.5%; /* width for 4 columns grid */
    &.is-for-sale:hover {
        border: 1px solid $orange;
    }
    &.is-sold {
        opacity: 0.40;
    }
    @include media('<961px') {
        width: 30.83%; /* width for 3 columns grid */
    }
    @include media('<651px') {
        width: 47.5%; /* width for 2 columns grid */
    }
    @include media('<380px') {
        width: 100%; /* width for 1 columns grid */
    }
    &-link {
        color: $black;
        text-decoration: none;
    }
    &-img {
        height: 250px;
        padding: calc-em(24px, 16px) 0;
        position: relative;
        img {
            @include shadow(1);
            left: 50%;
            max-height: 80%;
            max-width: 80%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &-content-wrapper {
        display: table;
        font-size: calc-em(12px, 16px);
        padding: calc-em(8px, 12px);
        width: 100%;
    }
    &-text {
        display: table-cell;
		line-height: 1.2em;
		text-align: left;
        vertical-align: bottom;
    }
    &-title {
		font-weight: bold;
    }
    &-price {
        margin-top: calc-em(8px, 12px);
    }
    &-button-wrapper {
        display: table-cell;
        vertical-align: bottom;
    }
    &-button {
        background: $orange;
        color: $white;
        font-family: $montserrat;
        padding: calc-em(10px, 12px);
        &:hover {
            color: $white;
        }
    }
	&-sold-text {
		background: $grey;
		color: $white;
        font-family: $montserrat;
		padding: calc-em(10px, 12px);
	}
}

.timber-frame-preview {
	&:hover {
		border-color: $orange;
		cursor: pointer;
	}
}
.timber-frame-preview-sold {
	&:hover {
		border-color: #ededed;
		cursor: auto;
	};
}
.timber-frames-gallery-texts {
	> div {
		width: 80%;
		margin: 40px auto;
		border-bottom: 1px solid $grey;
        &:last-child {
            border-bottom: none;
        }
	}
	.left-column {
		float: left;
		width: 50%;
	}
	.right-column {
		margin-left: 50%;
		padding-left: 20px;
	}
	img {
		width: 90%;
		margin-bottom: 40px;
	}
	h3 {
		@include headline($black, 24px);
		text-transform: uppercase;
	}
	h4 {
		@include headline($orange, 21px);
		margin-top: 10px;
	}
}
// .timber-frame-page {
// 	img#photo {
// 		max-width: 640px;
// 		max-height: 640px;
// 	}
// }

.timber-frame-page-title {
    font-size: calc-em(18px, 16px);
    margin: 24px auto;
    width: 80%;
}

.timber-frame-shipping-time {
    color: $grey;
    display: block;
    font-family: $lora;
    font-size: calc-em(12px, 16px);
    margin-top: 1em;
    text-align: right;
}

.buy-column-timber-frames {
    .buy-photo-button {
        margin: 0 0 48px 0;
        &:hover {
            background: $orange;
            color: $white;
        }
    }
}
.buy-column-timber-frames-prices {
	> div {
		margin-bottom: 10px;
		line-height: 1.2em;
	}
	.left-column {
		float: left;
		width: 160px;
		font-family: $lora;
	}
	.right-column {
		margin-left: 160px;
		text-align: right;
		font-family: $lora;
	}
	.including-print-box {
		border: 1px solid $grey;
		padding: 7px;
		background: #fafafa;
	}
	.unselected {
		opacity: 0.5;
	}
	.project-box {
		border: 1px solid $green;
		background: white;
		margin: 8px 0px 0px;
		img {
			width: 70px;
			float: left;
		}
		.text {
			margin: 8px 5px 0px 75px;
			font-size: 0.85em;
			font-family: $lora;
		}
	}
	
}

.timber-frame-description {
	h3 {
		@include headline($black, 24px);
		text-transform: uppercase;
		padding-top: 20px;
	}
	h4 {
		@include headline($orange, 21px);
		margin-top: 10px;
	}
}

.small-wall-preview-timber-frames {
    background: url($assetsPath + 'images/small-wall-preview-chair.jpg');
	height: 300px;
    margin-top: 3em;
	text-align: center;
    width: 300px;
	img {
		box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.55);
	}
}

.timber-frame-total {
    margin: 24px 0;
    .left-column,
    .right-column {
        font-family: $montserrat;
    }
}

.timber-frame-see-more {
    color: $black;
    font-size: calc-em(24px, 16px);
    a {
        margin-left: 0.75em;
        text-decoration: none;
    }
}

.timber-frame-slideshow {
	position: relative;
	z-index: 1;
	img {
		width: 600px;
	}

    .cycle-slideshow { min-width: 200px; max-width: 600px; /*margin: 10px auto;*/ padding: 0; position: relative;
        background: url(http://malsup.github.com/images/spinner.gif) 50% 50% no-repeat;

     }

    /* slideshow images (for most of the demos, these are the actual "slides") */
    .cycle-slideshow img { 
        /* 
        some of these styles will be set by the plugin (by default) but setting them here
        helps avoid flash-of-unstyled-content
        */
        position: absolute; top: 0; left: 0;
        width: 100%; padding: 0; display: block;
    }

    /* in case script does not load */
    .cycle-slideshow img:first-child {
        position: static; z-index: 100;
    }

    /* pager */
    .cycle-pager { 
        text-align: center; width: 100%; z-index: 500; position: absolute; bottom: 0px; overflow: hidden;
    }
    .cycle-pager span { 
        font-family: arial; font-size: 50px; width: 16px; height: 16px; 
        display: inline-block; color: $light_grey; cursor: pointer; 
    }
    .cycle-pager span.cycle-pager-active { color: $orange;}
    .cycle-pager > * { cursor: pointer;}


    /* caption */
    .cycle-caption { position: absolute; color: white; bottom: 15px; right: 15px; z-index: 700; }


    /* overlay */
    .cycle-overlay { 
        font-family: tahoma, arial;
        position: absolute; bottom: 0; width: 100%; z-index: 600;
        background: black; color: white; padding: 15px; opacity: .5;
    }


    /* prev / next links */
    .cycle-prev, .cycle-next { position: absolute; top: 0; width: 30%; opacity: 0; filter: alpha(opacity=0); z-index: 800; height: 100%; cursor: pointer; }
    .cycle-prev { left: 0;  background: url(http://malsup.github.com/images/left.png) 50% 50% no-repeat;}
    .cycle-next { right: 0; background: url(http://malsup.github.com/images/right.png) 50% 50% no-repeat;}
    .cycle-prev:hover, .cycle-next:hover { opacity: .7; filter: alpha(opacity=70) }

    .disabled { opacity: .5; filter:alpha(opacity=50); }


    /* display paused text on top of paused slideshow */
    .cycle-paused:after {
        /*
        content: 'Paused'; color: white; background: black; padding: 10px;
        z-index: 500; position: absolute; top: 10px; right: 10px;
        opacity: .5; filter: alpha(opacity=50);
    */
    }
}

.timber-frame-buy-button {
    @include button();
    width: 100%;
}

.timber-frame-already-sold-text {
    border: 1px solid $light_grey;
	font-family: $lora;
    margin: 3em 0;
    padding: 1em;
    text-align: center;
}

.timber-frame-mobile-form {
    @include photo-product-page-mobile-form;
}

