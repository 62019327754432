.print-photo-upload-progress-wrap {
  display: none;
  height: 100%;
  position: absolute;
  text-align: center;
  width: 100%;
}
.print-upload-progress-bar-container {
  display: block;
  height: 2em;
  margin: auto;
  width: 50%;
  position: relative;
}
.print-photo-upload-bar-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.print-upload-progress-bar {
  display: block;
  height: 100%;
  left: initial;
  top: initial;
  width: 100%;
}
.progress-number {
  left: 5%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
