.c-headlines {
  margin-bottom: 5em;
}

.c-headline {
  @include block;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.125;
  &:not(.is-spaced) + .c-headline-sub {
    margin-top: -1rem
  }
  strong {
    color: $color-pastel-red;
    font-weight: bold;
  }
}

.c-headline-sub {
  @include block;
  font-size: 1.25em;
  font-weight: 300;
  line-height: 1.25;
  strong {
    color: $color-pastel-red;
    font-weight: bold;
  }
}

.c-headline--small {
  font-size: calc-em(18px, 16px);
  text-transform: capitalize;
}

.c-headline--medium {
  font-size: calc-em(22px, 16px);
}

.c-headline--large {
  font-size: calc-em(28px, 16px);
}
