.products-page {
    margin-top: 3em;
}

.products-page-product {
    margin-top: 5em;
}

.products-page-slideshow-wrap {
    display: inline-block;
    margin-right: 5%;
    vertical-align: top;
    width: 44%;
    @include media('<680px') {
        display: block;
        width: 100%;
    }
}

.products-page-slideshow {
    @include shadow(1);
    z-index: 0;
    img {
        width: 100%;
    }
}

.products-page-thumbnail-pager {
    @include photo-product-page-thumbnail-pager;
    a {
        margin-right: 1.33%;
        width: 24%;
        &:last-child {
            margin-right: 0;
        }
    }
}

.products-page-content-wrap {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    @include media('<680px') {
        display: block;
        width: 100%;
    }
}

.products-page-button {
    @include button();
}