.c-site-seals {
  display: flex;
  flex-wrap: wrap;
  margin: 1em 0;
}

.c-site-seals__column {
  flex: 100%;
  padding: 1em;

  @include media('>600px') {
    flex: 50%;
  }
  @include media('>780px') {
    border-left: 1px solid $grey;
    flex: 1;
    &:first-child {
      border-left: 0;
    }
  }
}

.c-site-seals__title {
  font-weight: 400;
  margin-bottom: 1em;
  text-align: center;
}

.c-badge {
  display: flex;
}

.c-badge__icon {
  margin-right: 1em;
  width: 5em;
  img {
    width: 100%;
    height: auto;
  }
}

.c-site-seals__icon-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  &--payment {
    justify-content: flex-start;
  }
}

.c-site-seals__icon {
  flex: 0 0 25%;
  img {
    width: 100%;
    height: auto;
  }
}