@import '../settings/breakpoints';

.u-no-pointer-events {
  pointer-events: none;
}

.u-center {
  text-align: center;

  &-mobile {
    @include until($tablet) {
      text-align: center;
    }
  }

  &-tablet {
    @include until($desktop) {
      text-align: center;
    }
  }
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.is-flex {
  display: flex;
}

.u-flex-v-center {
  align-items: center;
}

.is-hidden {
  display: none !important;
}

@include mobile {
  .is-hidden-mobile {
    display: none !important;
  }
}

@include tablet {
  .is-hidden-tablet {
    display: none !important;
  }
}

@include desktop {
  .is-hidden-desktop {
    display: none !important;
  }
}

.u-weight-400 {
  font-weight: 400 !important;
}

.u-weight-700 {
  font-weight: 700 !important;
}

[v-cloak] {
  display: none !important;
}

.u-bg-grey {
    background: $color-grey;
}
